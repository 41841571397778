import {Box, Typography} from '@mui/material';
import React, {useState} from 'react';
import {useAtomValue} from 'jotai';
import {useLocation, useNavigate} from 'react-router';
import {useParams} from 'react-router-dom';
import {positionsAtom} from '../../store/positionsStore';
import AddPositionModal from './AddPositionModal';
import {IJobPosition} from '../../models/IJobPosition';
import PaymentOutcomeModal from "./PaymentOutcomeModal";

const linkStyle = {
    fontSize: "1rem",
    marginRight: '1.5rem',
    cursor: 'pointer',
    color: '#000000',
    textTransform: 'capitalize',
};

const activeLinkStyle = {
    color: '#809252',
    fontWeight: 700,
    textDecoration: 'underline',
    textDecorationThickness: '2px',
    textUnderlineOffset: '0.8rem',
};

function CompanyHeaderLinks() {
    const {id} = useParams();

    const navigate = useNavigate();
    const location = useLocation();
    const positions = useAtomValue(positionsAtom);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openPositionFilter = (position: IJobPosition) => {
        navigate(`/positions/${position.id}/filter`);
    };

    const openOfferStatus = () => {
        navigate('/offer-status');
    };

    const openAccount = () => {
        navigate('/account');
    };

    const openSettings = () => {
        navigate('/settings');
    };

    return (
        <>
            {positions.length > 0 && (
                <>
                    <Typography
                        sx={{...linkStyle, ...location.pathname === '/offer-status' ? activeLinkStyle : {}, fontWeight: 600,
                            fontSize: "1rem",
                            color: '#FFA149'}}
                        typography="body1"
                        onClick={() => openOfferStatus()}
                    >
                        Offer status
                    </Typography>
                    {positions.map((position, index) => {
                        const additionalStyle = id === position.id.toString() ? activeLinkStyle : {};
                        return (
                            <Typography
                                key={index}
                                sx={{...linkStyle, ...additionalStyle}}
                                typography="body1"
                                onClick={() => openPositionFilter(position)}
                            >
                                {position.name}
                            </Typography>
                        );
                    })}
                </>
            )}
            <Typography sx={{
                ...linkStyle, color: '#809252', fontSize: "1rem",
            }} typography="body1" onClick={() => handleOpen()}>
                + Add new
                position
            </Typography>

            <Box sx={{flexGrow: 1}}/>
            <Typography
                sx={{
                    ...linkStyle, ...location.pathname === '/settings' ? activeLinkStyle : {},
                    fontWeight: 600,
                    fontSize: "1rem",
                    color: '#FFA149'
                }}
                typography="body1"
                onClick={() => openSettings()}
            >
                Settings
            </Typography>

            <AddPositionModal open={open} handleClose={handleClose}/>
            <PaymentOutcomeModal />
        </>
    );
}

export default CompanyHeaderLinks;
