import React from 'react';
import {Box, Container, Fab} from '@mui/material';
import JoblingHeaderBar from "../components/JoblingHeaderBar";
import BackToTop from "../components/BackToTop";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

function JoblingLayout({
                           children,
                           backgroundColor = '#F7F7F7',
                           showHeader = true
                       }: { children: JSX.Element, backgroundColor?: string, showHeader?: boolean }) {
    return (
        <Container disableGutters>
            {showHeader && <JoblingHeaderBar/>}

            <Box sx={{display: 'flex', justifyContent: 'center', backgroundColor: backgroundColor}}>
                <Box sx={{height: '100vh', maxWidth: '90rem', width: '100%'}}>
                    {children}
                </Box>
            </Box>


            <BackToTop>
                <Fab size="small" aria-label="scroll back to top">
                    <KeyboardArrowUpIcon sx={{color: '#809252'}}/>
                </Fab>
            </BackToTop>
        </Container>

    );
}

export default JoblingLayout;
