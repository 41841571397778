import React from 'react';
import UserPersonalityInfo from "./UserPersonalityInfo";
import {IMetrixValue} from "../../../models/IMetrixOverviews";
import ValuesRightSideContent from "./ValuesRightSideContent";


function UserValuesHighestLowest({
                                     elements,
                                     averages
                                 }: { elements: IMetrixValue[] | undefined, averages: IMetrixValue[] | undefined }) {

    const personalityInfoOverview = () => {
        return elements?.map((element) => {
            return <React.Fragment key={element.type}>
                <UserPersonalityInfo metrixCode={element.type} value={element.value}
                                     average={averages?.find(a => a.type === element.type)}
                                     rightSideContent={<ValuesRightSideContent/>}/>
            </React.Fragment>
        })
    }

    return (
        <>
            {personalityInfoOverview()}
        </>
    );
}

export default UserValuesHighestLowest;
