import React from 'react';
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Menu,
    MenuItem,
    ThemeProvider,
    Toolbar,
    Typography
} from '@mui/material';
import {getAuth} from 'firebase/auth';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router';
import StudentHeaderLinks from './student/StudentHeaderLinks';
import {unsavedPreferencesAtom} from '../store/userStore';
import MenuIcon from '@mui/icons-material/Menu';
import JoblingLogo from "../assets/JoblingLogo";
import {tomatoTheme} from "./theme";
import UnsavedConfirmationDialog from "../pages/Home/UnsavedConfirmationDialog";


const styles = {
    appbar: {
        backgroundColor: 'background.paper',
        height: '5rem',
        //justifyContent: 'center',
        borderBottom: '1px solid #EFEDEC;',
    },
};

const STUDENT_LINKS = [
    {label: 'Offers', path: '/offers'},
    {label: 'Preferences', path: '/preferences'},
    {label: 'Survey results', path: '/survey'},


];

function NewHeaderBar() {
    const navigate = useNavigate();
    const hasUnsavedChanges = useAtomValue(unsavedPreferencesAtom);
    const [showPrompt, setShowPrompt] = React.useState<boolean>(false);


    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const confirmNavigation = () => {
        setShowPrompt(false)
        signOut()
    };

    const cancelNavigation = () => {
        setShowPrompt(false)
    };

    const signOut = () => {
        getAuth().signOut().then(() => {
            navigate('/login');
        });
    };

    const logout = () => {
        if (hasUnsavedChanges) {
            setShowPrompt(true)
        } else {
            signOut()
        }
    };

    const providerId = getAuth().currentUser?.providerData[0].providerId

    return (
        <ThemeProvider theme={tomatoTheme}>
            <UnsavedConfirmationDialog
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}/>
            <AppBar elevation={0} position="sticky" sx={styles.appbar}>
                <Container>
                    <Toolbar disableGutters>
                        <Box sx={{
                            height: '5rem',
                            alignItems: 'center',
                            display: {xs: 'none', md: 'flex'},
                        }}>
                            <JoblingLogo/>
                        </Box>

                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="default"
                            >
                                <MenuIcon/>
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: {xs: 'block', md: 'none'},
                                }}
                            >
                                {STUDENT_LINKS.map((page) => (
                                    <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center"
                                                    onClick={() => navigate(page.path)}>{page.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>


                        <Box sx={{
                            height: '5rem',
                            alignItems: 'center',
                            display: {xs: 'flex', md: 'none'},
                        }}>
                            <JoblingLogo/>
                        </Box>

                        <Box sx={{display: {xs: 'none', md: 'flex'}, ml: 5,}}>
                            <StudentHeaderLinks/>
                        </Box>

                        <Box sx={{display: 'flex', flexGrow: 1}}/>

                        {providerId === 'password' && (
                            <Button sx={{color: 'palette.primary', fontSize: '1rem', fontWeight: '600'}}
                                    onClick={() => navigate('/account')}>Account</Button>
                        )}

                        <Button sx={{color: 'palette.primary', fontSize: '1rem', fontWeight: '600'}}
                                onClick={() => logout()}>Log out</Button>

                    </Toolbar>
                </Container>
            </AppBar>
        </ThemeProvider>
    );
}

export default NewHeaderBar;
