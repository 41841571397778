import React, {useEffect} from 'react';
import './App.css';
import {CssBaseline, ThemeProvider} from '@mui/material';
import {ToastContainer} from 'react-toastify';
import AppAuthWrapper from './components/AppAuthWrapper';
import AppRoutes from './components/AppRoutes';
import theme from './components/theme';
import './i18n/config';
import useDomainValues from './hooks/useDomainValues';

function App() {
    const {loadDomainValues} = useDomainValues();

    useEffect(() => {
        loadDomainValues();
    }, []);

    return (
        <AppAuthWrapper>
            <ThemeProvider theme={theme}>
                <ToastContainer
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
                <CssBaseline/>
                <AppRoutes/>
            </ThemeProvider>
        </AppAuthWrapper>
    );
}

export default App;
