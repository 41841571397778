import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Button} from "@mui/material";
import {IQuestion} from "../../../models/IQuestion";

interface Props {
    activeStep: number;
    questions: IQuestion[];
    selected: Map<number, number | undefined>
    handleNext: (step: number | undefined) => void;
    handleBack: () => void;
    type: string;
}

function TestNavigationButtons({activeStep, questions, selected, handleNext, handleBack, type}: Props) {

    const currentQuestion = questions[activeStep] as IQuestion
    const hasAlreadyValue = selected.get(currentQuestion.id!) === undefined

    const isNextDisabled = () => {
        const isNotLastQuestion = activeStep !== 65
        return isNotLastQuestion && hasAlreadyValue
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>


            <Button
                variant="outlined"
                color="inherit"
                disabled={activeStep === 0 && type === 'PERSONALITY'}
                onClick={handleBack}
                sx={{height: '3rem'}}
            >
                Back
            </Button>

            {!hasAlreadyValue && (
                <Button variant="contained"
                        sx={{ml: 2}}
                        onClick={() => handleNext(selected.get(questions[activeStep].id!))}
                        disabled={isNextDisabled()}>
                    {activeStep === questions.length - 1 && type === 'COMPETENCE' ? 'Finish' : 'Next'}
                </Button>
            )}

        </Box>
    );
}

export default TestNavigationButtons;
