import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Stack, Typography} from '@mui/material';
import Layout from '../Layout';
import DashboardImage from "../../assets/DashboardImage";
import {useAtomValue} from "jotai";
import {positionsAtom} from "../../store/positionsStore";
import AddPositionForm from "../../components/company/AddPositionForm";

function CompanyDashBoard() {

    const positions = useAtomValue(positionsAtom)


    if (positions.length === 0) {
        return (
            <Layout>
                <Stack spacing={1}>
                    <AddPositionForm/>
                </Stack>
            </Layout>
        );
    }

    return (
        <Layout>
            <Stack spacing={1}>
                <Box sx={{position: 'relative'}}>
                    <Typography typography="h2" sx={{position: 'absolute', left: '60%', mt: 4, fontSize: '1.75rem'}}>Jobling
                        is a pool of ripening talent</Typography>
                    <DashboardImage/>
                </Box>
            </Stack>
        </Layout>
    );
}

export default CompanyDashBoard;
