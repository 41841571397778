import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack, Typography} from '@mui/material';
import {useAtom} from 'jotai';
import {useTranslation} from 'react-i18next';
import Layout from '../../Layout';
import CompanyOfferStatusLeft from './CompanyOfferStatusLeft';
import CompanyOfferStatusMiddle from './CompanyOfferStatusMiddle';
import {positionsAtom} from '../../../store/positionsStore';
import {IJobPosition} from '../../../models/IJobPosition';
import AddPositionForm from '../../../components/company/AddPositionForm';
import useOrders from "../../../hooks/useOrders";

function CompanyOfferStatus() {
    const {t} = useTranslation();

    const [positions, setPositions] = useAtom(positionsAtom);
    const [activePosition, setActivePosition] = useState<IJobPosition | null>(null);
    const {loadOrderOverview} = useOrders();

    useEffect(() => {
        if (positions) {
            setActivePosition(positions[0]);
        }
    }, [positions]);

    useEffect(() => {
        loadOrderOverview()
    }, []);

    if (positions.length === 0) {
        return (
            <Layout>
                <Stack spacing={1}>
                    <AddPositionForm/>
                </Stack>
            </Layout>
        );
    }

    return (
        <Layout>
            <Stack spacing={1}>
                <Typography typography="h3">{t('company.dashboard.title')}</Typography>
                <Stack direction="row" spacing={1}>
                    <CompanyOfferStatusLeft
                        positions={positions}
                        activePosition={activePosition}
                        setActivePosition={setActivePosition}
                    />
                    <CompanyOfferStatusMiddle activePosition={activePosition}/>
                </Stack>
            </Stack>
        </Layout>
    );
}

export default CompanyOfferStatus;
