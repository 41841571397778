import React, {useState} from 'react';
import {Autocomplete, Box, Button, CircularProgress, Paper, Stack, TextField, Typography,} from '@mui/material';
import {useAtom} from 'jotai';
import {userAtom} from '../../store/userStore';
import {styles} from '../theme';
import ActionButton, {ActionButtonType} from "../ActionButton";
import {toast} from "react-toastify";
import {getAuth, updatePassword} from "firebase/auth";


function ChangePassword() {
    const user = getAuth().currentUser

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');


    const submitChangePassword = async () => {
        if (password !== repeatPassword) {
            toast.error("Passwords do not match")
            return
        }

        if (password.length < 6) {
            toast.error("Password must be at least 6 characters long")
            return
        }

        const providerId = user?.providerData[0].providerId
        if(providerId !== "password") {
            toast.error("Current user is not password user")
            return
        }

        if(user != null) {
            await updatePassword(user, password)
                .then(() => {
                    setPassword('')
                    setRepeatPassword('')
                    toast.success("Password changed successfully")
                })
                .catch((error) => {
                    toast.error(error.message)
                })
        }
    }

    if (!user) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <Stack sx={{alignItems: 'center'}}>

            <Typography typography="h3">Change password</Typography>
            <TextField
                sx={{width: '50%'}}
                type="password"
                variant="standard"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
                sx={{width: '50%'}}
                type="password"
                variant="standard"
                label="Repeat password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
            />

            <Box sx={{display: 'flex', mt: 3}}>
                <Button variant="contained" onClick={submitChangePassword}>Change password</Button>
            </Box>
        </Stack>
    );
}

export default ChangePassword;
