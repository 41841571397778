import React from 'react';
import {toast} from 'react-toastify';
import {ISearchFilter} from '../models/ISearchFilter';
import useApi, {BASE_URL} from './useApi';

const useChartApi = () => {
    const {doFetch} = useApi();

    const CHART_URL = `${BASE_URL}filter/chart`;

    const getGradeData = (filter: ISearchFilter, metrix: string, jobPositionId: number) => {
        const url = new URL(`${CHART_URL}/${metrix}`);
        url.search = new URLSearchParams({jobPositionId: jobPositionId.toString()}).toString();

        return doFetch(url.toString(), 'POST', {...filter})
            .catch((e) => {
                toast.error('Failed to load average grade chart');
            });
    };

    return {getGradeData};
};

export default useChartApi;
