import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack, Typography} from '@mui/material';
import Layout from '../../Layout';
import AccountLeft from './AccountLeft';
import AccountMiddle from './AccountMiddle';

function Account() {
    return (
        <Layout>
            <Stack spacing={1}>
                <Typography typography="h3">My account</Typography>
                <Stack direction="row" spacing={1}>
                    <AccountLeft/>
                    <AccountMiddle/>
                </Stack>
            </Stack>
        </Layout>
    );
}

export default Account;
