import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography
} from '@mui/material';
import Dialog from "@mui/material/Dialog";
import {useAtomValue} from "jotai";
import {orderOverviewAtom, productPackagesAtom} from "../../../store/ordersStore";
import ProductPackageContainer from "./ProductPackageContainer";
import Spinner from '../../../util/Spinner';
import ProductPackageQualifiedConfirmation from "./ProductPackageQualifiedConfirmation";
import {IPackageType} from "../../../models/IPackageType";
import QualifiedProductPackage from "./QualifiedProductPackage";

const PACKAGE_SELECTION: string = "PACKAGE_SELECTION";
const QUALIFIED_PACKAGE_CONFIRMATION: string = "QUALIFIED_PACKAGE_CONFIRMATION";


function ProductPackageSelection({
                                     open,
                                     onClose,
                                     onCancel,
                                     availableContactRequests,
                                     jobPositionId,
                                     showOnlyQualifiedPackage = false,
                                     jumpToQualifiedPackageConfirmation = false,
                                 }: {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    availableContactRequests: number;
    jobPositionId: number;
    showOnlyQualifiedPackage?: boolean;
    jumpToQualifiedPackageConfirmation?: boolean;
}) {

    const [currentStep, setCurrentStep] = useState<string>(PACKAGE_SELECTION);
    const [cancelWillCloseModal, setCancelWillCloseModal] = useState<boolean>(false);

    let productPackages = useAtomValue(productPackagesAtom);

    let chooseCandidatesPackage = productPackages?.find((productPackage) => productPackage.type === IPackageType.CHOOSE)
    let qualifiedCandidatesPackage = productPackages?.find((productPackage) => productPackage.type === IPackageType.QUALIFIED)

    useEffect(() => {
        // When qualified package was selected from right sidebar,
        // then the modal will be closed anyway and we don't want to change the step to avoid content flickering
        if (!cancelWillCloseModal) {
            setCurrentStep(PACKAGE_SELECTION);
        }
    }, [open]);

    useEffect(() => {
        if (jumpToQualifiedPackageConfirmation) {
            setCurrentStep(QUALIFIED_PACKAGE_CONFIRMATION);
            setCancelWillCloseModal(true);
        }
    }, [jumpToQualifiedPackageConfirmation]);

    let onCancelForQualifiedConfirmation = () => {
        if (cancelWillCloseModal) {
            onClose();
        } else {
            setCurrentStep(PACKAGE_SELECTION)
        }
    };

    return (
        <>
            {currentStep === PACKAGE_SELECTION &&
                <DialogContent sx={{padding: showOnlyQualifiedPackage ? 0: undefined}}>
                    {chooseCandidatesPackage !== undefined && qualifiedCandidatesPackage !== undefined ?
                        <Grid container spacing={2}>
                            {!showOnlyQualifiedPackage && <Grid item xs={6}>
                                <ProductPackageContainer productPackage={chooseCandidatesPackage!}
                                                         onCancel={onCancel}
                                                         onProceed={onClose}
                                                         proceedText="Continue to pick candidates">
                                    <Stack>
                                        <Typography mt={2} sx={{minHeight: "63px", px: 2}}>
                                            {availableContactRequests === 0 ? <>
                                                    Access our entire candidate database, filter down to you top candidates,
                                                    and
                                                    make offers to the best {chooseCandidatesPackage!.quantity} fits for
                                                    your
                                                    job
                                                    requirements.</> :
                                                <>You're currently on the <strong>{chooseCandidatesPackage!.name}</strong> package and have already used some
                                                    candidates.
                                                    But don't forget, you still have
                                                    a <strong>{availableContactRequests} {availableContactRequests === 1 ? 'candidate' : 'candidates'} left</strong> -
                                                    and the best part is, there's no extra charge for them!</>
                                            }
                                        </Typography>
                                        <Divider sx={{mt: 3}}/>
                                        <ul style={{
                                            paddingLeft: "24px",
                                            paddingRight: "30px",
                                            textAlign: "left",
                                            marginTop: "32px"
                                        }}>
                                            <li>Access our entire candidate database, filter down to you top candidates,
                                                and
                                                make offers to the best fits for your job requirements.
                                            </li>
                                            <li>Get access to a wide pool of potential candidates</li>
                                            <li>Get full control over candidate search and selection - you can search
                                                for
                                                specific competencies, values and personality traits
                                            </li>
                                            <li>Invite handpicked candidates to job interviews</li>
                                        </ul>
                                        {availableContactRequests === 0 &&
                                            <Typography mt={2} textAlign="left" sx={{px: 2}}>
                                                The response rate to job interviews is typically in the range of 20% to
                                                30%
                                            </Typography>}

                                    </Stack>
                                </ProductPackageContainer>
                            </Grid>}
                            <Grid item xs={showOnlyQualifiedPackage ? 12 : 6}>
                                <QualifiedProductPackage
                                    showOnlyQualifiedPackage={showOnlyQualifiedPackage}
                                    availableContactRequests={availableContactRequests}
                                    onCancel={onCancel}
                                    onProceed={() => setCurrentStep(QUALIFIED_PACKAGE_CONFIRMATION)}
                                />
                            </Grid>
                        </Grid>
                        : <Spinner/>}
                </DialogContent>}
            {currentStep === QUALIFIED_PACKAGE_CONFIRMATION && qualifiedCandidatesPackage !== null &&
                <ProductPackageQualifiedConfirmation
                    onCancel={onCancelForQualifiedConfirmation}
                    onClose={onClose}
                    productPackage={qualifiedCandidatesPackage!}
                    jobPositionId={jobPositionId}
                />}
        </>
    );
}

export default ProductPackageSelection;
