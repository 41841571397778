import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Divider, Stack, Typography} from '@mui/material';
import {useAtomValue} from "jotai";
import {productPackagesAtom} from "../../../store/ordersStore";
import ProductPackageContainer from "./ProductPackageContainer";
import {IPackageType} from "../../../models/IPackageType";


function QualifiedProductPackage({
                                     onProceed,
                                     onCancel,
                                     showOnlyQualifiedPackage = false,
                                     availableContactRequests,
                                 }: {
    onProceed: () => void;
    onCancel: () => void;
    showOnlyQualifiedPackage?: boolean;
    availableContactRequests: number;
}) {


    let productPackages = useAtomValue(productPackagesAtom);

    let qualifiedCandidatesPackage = productPackages?.find((productPackage) => productPackage.type === IPackageType.QUALIFIED)

    if (qualifiedCandidatesPackage === undefined) {
            return null;
    }
    return (
        <>
            <ProductPackageContainer productPackage={qualifiedCandidatesPackage!}
                                     onCancel={onCancel}
                                     onProceed={onProceed}
                                     sidebarMode={showOnlyQualifiedPackage}
                                     proceedText="Let us select candidates for you">
                <Stack>
                    <Typography mt={2} sx={{minHeight: "63px", px: 2}}>
                        {availableContactRequests === 0 ? <>
                                Submit your job offer and we'll find <strong>{qualifiedCandidatesPackage!.quantity}</strong> top-tier candidates tailored to your requirements.
                            </> :
                            <>
                                If you prefer not to choose candidates yourself and would like us to handle
                                it,
                                you have the option to explore
                                our <strong>{qualifiedCandidatesPackage!.name}</strong> package.
                            </>}

                    </Typography>
                    <Divider sx={{mt: 3}}/>
                    <ul style={{
                        paddingLeft: "24px",
                        paddingRight: "30px",
                        textAlign: "left",
                        marginTop: "32px"
                    }}>
                        <li>Get only quality candidates</li>
                        <li>Save you team's time on finding potential candidates</li>
                        <li>Invite {qualifiedCandidatesPackage!.quantity} top-tier candidates to job
                            interviews
                        </li>
                    </ul>

                </Stack>
            </ProductPackageContainer>
        </>
    );
}

export default QualifiedProductPackage;
