import * as React from 'react';
import {useState} from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import {Box, TextField, ThemeProvider} from "@mui/material";
import {IUser, UserType} from "../../../models/IUser";
import {toast} from "react-toastify";
import {isValidEmail} from "../../../util/formatUtil";
import {tomatoTheme} from "../../../components/theme";
import useCompaniesApi from "../../../api/useCompaniesApi";

export interface AccountTypeDialogProps {
    open: boolean;
    onClose: (reload?: boolean) => void;

    companyId: number;
}

function AddNewUserDialog(props: AccountTypeDialogProps) {
    const { onClose, open, companyId } = props;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const {createCompanyUser} = useCompaniesApi();

    const handleClose = () => {
        onClose();
    };
    const onEmailSubmit = async () => {
        if (isFormValid()) {

            const newUser = {
                fullName: name,
                email: email,
                citizenship: 'ESTONIAN',
                accountType: UserType.COMPANY,
                loginType: 'EMAIL'
            };
            const createdUser = await createCompanyUser(companyId, newUser as IUser)
            toast.info("New user created for company! Instructions sent to email:  " + email)
            onClose(true)
        }
    };

    const isFormValid = () => {
        const errors = [];
        if (name === undefined || name?.trim().length === 0) {
            errors.push('name');
        }
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        setErrorFields(errors);
        return errors.length <= 0;
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Add new user</DialogTitle>
            <ThemeProvider theme={tomatoTheme}>
                <Box sx={{
                    padding: "1rem",
                    backgroundColor: 'white',
                }}>
                    <TextField
                        error={errorFields.includes("name")}
                        helperText={!errorFields.includes("name") ? "" : "Missing or invalid full name!"}
                        variant="outlined"
                        label={"Full name"}
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            borderRadius: '0.5rem',
                            marginBottom: "1rem",
                        }}/>

                    <TextField
                        error={errorFields.includes("email")}
                        helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                        variant="outlined"
                        label={"E-mail"}
                        value={email}
                        fullWidth
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            borderRadius: '0.5rem',
                            marginBottom: "1rem",
                        }}/>

                    <Button
                        id="login-button"
                        sx={{
                            border: '1px solid #FFA149',
                            backgroundColor: 'white',
                            width: '100%',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '3rem',
                            borderRadius: '0.5rem',
                            mb: 2
                        }}
                        onClick={() => onEmailSubmit()}
                    >
                        <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Create
                            account</Typography>
                    </Button>
                </Box>

            </ThemeProvider>


        </Dialog>
    );
}

export default AddNewUserDialog;
