import React from 'react';
import useApi, {BASE_URL} from './useApi';
import {IUser} from "../models/IUser";
import {ICompany} from "../models/ICompany";

const COMPANY_URL = `${BASE_URL}company`;

const useCompaniesApi = () => {
    const {doFetch} = useApi();

    const getCompanies = () => {
        return doFetch(COMPANY_URL);
    };

    const getUsersByCompanyId = (companyId: number) => {
        const url = `${COMPANY_URL}/${companyId}/user`;
        return doFetch(url);
    };

    const createCompanyUser = (companyId: number, user: IUser) => {
        let url = `${COMPANY_URL}/${companyId}/user`;
        return doFetch(url, 'POST', {...user}, true)
    };

    const updateCompany = (company: ICompany) => {
        const url = `${COMPANY_URL}/${company.id}`;
        return doFetch(url, 'PUT', {...company})
    };

    const addCompany = (company: ICompany) => {
        return doFetch(COMPANY_URL, 'POST', {...company})
    };


    return {
        getCompanies, getUsersByCompanyId, createCompanyUser, updateCompany, addCompany
    };
};

export default useCompaniesApi;
