import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Grid, Slider, Typography} from '@mui/material';
import {IMetrixValue} from "../../../models/IMetrixOverviews";
import {useTranslation} from "react-i18next";


interface Props {
    metrixCode: string;
    value: number;
    rightSideContent: JSX.Element;
    average: IMetrixValue | undefined;
}

function UserPersonalityInfo({metrixCode, value, rightSideContent, average}: Props) {
    const {t} = useTranslation();

    return (
        <Grid container sx={{mt: 4}} justifyContent="center">

            <Grid item xs={12}>
                <Typography typography="body2"
                            sx={{fontWeight: 500}}>{t(`company.filter.metrix.${metrixCode}.title`)}</Typography>
                <Typography typography="body2"
                            sx={{mt: 0.5}}>{t(`company.filter.metrix.${metrixCode}.info`)}</Typography>

                <Box sx={{
                    mt: 3
                }}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', position: 'relative'}}>
                        <Slider value={50} disabled track={false} sx={{
                            height: '1px',
                            '.MuiSlider-thumb': {
                                height: '1.5rem', width: '1px', borderRadius: 0, border: '1px solid #D3D3D3'
                            }
                        }}/>
                        {
                            /*
                            <Box sx={{
                            position: 'absolute',
                            backgroundColor: 'white',
                            borderRadius: '100%',
                            minWidth: '2.5rem',
                            height: '2rem',
                            color: 'white',
                            left: value + '%',
                            transform: 'translate(-50%, 0%)'
                        }}></Box>
                             */
                        }

                        <Box sx={{
                            position: 'absolute',
                            backgroundColor: '#A2DA93',
                            borderRadius: '100%',
                            minWidth: '1.5rem',
                            height: '1.5rem',
                            color: 'white',
                            left: value + '%',
                            transform: 'translate(-50%, 0%)',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex'
                        }}/>
                        <Typography sx={{
                            position: 'absolute',
                            color: '#A2DA93',
                            fontWeight: '700',
                            fontSize: '12px',
                            left: value + '%',
                            top: "27px",
                            transform: 'translate(-50%, 0%)',
                            display: 'flex'
                        }}>
                            Me
                        </Typography>
                        {average && average.value ? <><Box sx={{
                            position: 'absolute',
                            backgroundColor: '#FFF',
                            border: "1px solid #91939A",
                            borderRadius: '100%',
                            minWidth: '0.8rem',
                            height: '0.8rem',
                            color: 'white',
                            left: average.value + '%',
                            transform: 'translate(-50%, 0%)',
                            alignItems: 'center',
                            justifyContent: 'center',
                            display: 'flex'
                        }}/>

                            <Typography sx={{
                                position: 'absolute',
                                color: '#91939A',
                                fontWeight: '700',
                                fontSize: '10px',
                                left: average.value + '%',
                                top: "17px",
                                transform: 'translate(-50%, 0%)',
                                display: 'flex'
                            }}>
                                Avg
                            </Typography>
                        </> : ""}
                    </Box>


                    <Box sx={{display: 'flex', mt: 2}}>
                        <Box sx={{width: '50%', textAlign: 'left', mr: 4}}>
                            <Typography
                                typography="caption">{t(`company.filter.metrix.${metrixCode}.leftLabel`)}
                            </Typography>
                            {!metrixCode.startsWith("COMPETENCE_") && <Typography typography="subtitle2" sx={{
                                color: '#91939A',
                                mt: 0.5
                            }}>{t(`company.filter.metrix.${metrixCode}.leftInfo`)}</Typography>
                            }
                        </Box>
                        <Box sx={{width: '50%', textAlign: 'right', ml: 4}}>
                            <Typography
                                typography="caption">{t(`company.filter.metrix.${metrixCode}.rightLabel`)}
                            </Typography>
                            {!metrixCode.startsWith("COMPETENCE_") && <Typography typography="subtitle2" sx={{
                                color: '#91939A',
                                mt: 0.5
                            }}>{t(`company.filter.metrix.${metrixCode}.rightInfo`)}</Typography>
                            }
                        </Box>
                    </Box>
                </Box>
            </Grid>
            {/*<Grid xs={12} md={5} sx={{mt: 3}} pl={{xs: 0, md: 6}}>*/
            }
            {/*    <Paper sx={{height: '100%', boxShadow: '0 4px 8px rgba(197,203,211,.5)', border: '1px solid #e4e6e8'}}>*/
            }
            {/*        <Grid*/
            }
            {/*            container*/
            }
            {/*            direction="column"*/
            }
            {/*            justifyContent="space-between"*/
            }
            {/*            alignItems="stretch"*/
            }
            {/*            sx={{height: '100%'}}*/
            }
            {/*        >*/
            }
            {/*            <Grid sx={{padding: '1.6rem'}}>*/
            }
            {/*                {rightSideContent}*/
            }
            {/*            </Grid>*/
            }

            {/*            <Grid sx={{height: '60px'}}>*/
            }
            {/*            <Divider></Divider>*/
            }
            {/*                <Grid container sx={{height: '100%'}} justifyContent="center" alignItems="center">*/
            }
            {/*                    <Grid item display="flex">*/
            }
            {/*                        More on {METRIX_TEXTS.get(metrixCode).title}*/
            }
            {/*                    </Grid>*/
            }
            {/*                </Grid>*/
            }
            {/*            </Grid>*/
            }
            {/*        </Grid>*/
            }
            {/*    </Paper>*/
            }
            {/*</Grid>*/
            }
        </Grid>
    )
        ;
}

export default UserPersonalityInfo;
