import React from 'react';
import {Box, Radio, RadioGroup, Typography,} from '@mui/material';
import {useParams} from 'react-router-dom';
import {useNavigate} from 'react-router';
import {ISearchFilter} from '../../../../models/ISearchFilter';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

function CompanyFilterRight({
                                searchFilter,
                                updateFilter,
                                matchCount
                            }: { searchFilter: ISearchFilter, updateFilter: any, matchCount: number[] }) {
    const {id} = useParams();
    const navigate = useNavigate();

    const changeMismatchForm = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newFilter = {...searchFilter};
        newFilter.mismatches = parseInt(event.target.value);
        updateFilter(newFilter, false, true, false);
    };

    const getLabel = (text: string, countIndex: number) => (
        <Box sx={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <Typography sx={{maxWidth: '8.25rem', fontSize: '0.875rem'}}>{text}</Typography>
            <Box sx={{flexGrow: 1}}/>
            <Typography color={"primary"} sx={{
                fontWeight: '700',
                fontSize: '1.125rem'
            }}>{matchCount ? matchCount[countIndex] : 0}</Typography>
        </Box>
    );

    return (
        <RadioGroup
            defaultValue="0"
            value={searchFilter.mismatches}
            onChange={changeMismatchForm}
        >
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Radio value="0" icon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}
                       checkedIcon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}/>
                {' '}
                {getLabel('Perfect matches', 0)}
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Radio value="1" icon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}
                       checkedIcon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}/>
                {' '}
                {getLabel('Perfect matches and 1 missed filter', 1)}
            </Box>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Radio value="2" icon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}
                       checkedIcon={<RadioButtonCheckedIcon sx={{width: '1rem'}}/>}/>
                {' '}
                {getLabel('Perfect matches and up to 2 missed filters', 2)}
            </Box>
        </RadioGroup>
    );
}

export default CompanyFilterRight;
