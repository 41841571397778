import React, {useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Grid, Paper, Stack, Tab, Tabs, ThemeProvider, Typography} from '@mui/material';
import {styles, tomatoTheme} from '../../../components/theme';
import UserTestMain from './UserTestMain';
import UserValuesMap from './UserValuesMap';
import UserValuesHighestLowest from './UserValuesHighestLowest';
import UserCompetences from './UserCompetences';
import TopMotivators from './TopMotivators';
import LowestPriority from './LowestPriority';
import {IMetrixOverviews} from '../../../models/IMetrixOverviews';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

function SurveyResults({
                           metrixOverviews,
                           accountNumber = undefined
                       }: {
    metrixOverviews: IMetrixOverviews | null;
    accountNumber?: number | undefined;
}) {
    const [tabValue, setTabValue] = useState(0);
    const [loading, setLoading] = useState(false); // Loading state

    const refs = useRef<any>({});
    const [current, setCurrent] = useState('personality');

    const goTo = (id: any) => {
        setCurrent(id);
        refs.current[id].scrollIntoView(true, {behavior: 'smooth', block: 'start'});
    };

    async function downloadAsPdf(): Promise<void> {
        setLoading(true); // Start loading

        const DATA: HTMLCollectionOf<Element> = document.getElementsByClassName('downloadable-pdf');
        const PDF = new jsPDF('portrait', 'pt', 'a4', true);

        // Add first page
        addFirstPage(PDF);

        // Start from page 2 for the remaining content
        PDF.addPage('a4', 'portrait');

        for (let i = 0; i < DATA.length; i++) {
            await canvasConverter(PDF, DATA[i] as HTMLElement).then(() => {
                if (i + 1 < DATA.length) {
                    PDF.addPage('a4', 'portrait');
                }
            });
        }
        PDF.save('test_results.pdf');

        setLoading(false); // Finish loading
    }


    function addFirstPage(PDF: jsPDF): void {
        const title = 'Survey Results';
        const profileId = 'Profile ID: 12034';

        const pageWidth = PDF.internal.pageSize.getWidth();
        const pageHeight = PDF.internal.pageSize.getHeight();

        PDF.setFont('helvetica', 'bold');
        PDF.setFontSize(24);
        PDF.text(title, pageWidth / 2, pageHeight / 2, {align: 'center', baseline: 'middle'});

        PDF.setFont('helvetica', 'normal');
        PDF.setFontSize(12);
        PDF.text(profileId, pageWidth / 2, pageHeight / 2 + 30, {align: 'center', baseline: 'middle'});
    }

    async function canvasConverter(PDF: jsPDF, element: HTMLElement): Promise<void> {
        const backgroundColor = element.style.backgroundColor;
        element.style.backgroundColor = 'white';
        await html2canvas(element, {scale: 2}).then((canvas) => {
            const MAX_WIDTH: number = PDF.internal.pageSize.getWidth();
            const MAX_HEIGHT: number = PDF.internal.pageSize.getHeight();

            let imgWidth: number = canvas.width;
            let imgHeight: number = canvas.height;

            // Calculate aspect ratio
            const aspectRatio: number = imgWidth / imgHeight;

            // Check if the image width exceeds the page width
            if (imgWidth > MAX_WIDTH) {
                imgWidth = MAX_WIDTH;
                imgHeight = imgWidth / aspectRatio;
            }

            // Check if the image height exceeds the page height
            if (imgHeight > MAX_HEIGHT) {
                imgHeight = MAX_HEIGHT;
                imgWidth = imgHeight * aspectRatio;
            }

            const fileUri: string = canvas.toDataURL('image/jpeg', 0.6);
            const posX: number = 0;
            const posY: number = 0;

            PDF.addImage(fileUri, 'JPEG', posX, posY, imgWidth, imgHeight, '', 'FAST');
        });
        element.style.backgroundColor = backgroundColor;
    }

    return <ThemeProvider theme={tomatoTheme}>
        <Tabs sx={{display: {xs: 'inherit', sm: 'none'}}} value={tabValue}
              onChange={(event, value) => setTabValue(value)}>
            <Tab value={0} label="Personality" sx={{fontSize: "14px"}} onClick={() => goTo('personality')}/>
            <Tab value={1} label="Values" sx={{fontSize: "14px"}} onClick={() => goTo('values')}/>
            <Tab value={2} label="Competencies" sx={{fontSize: "14px"}} onClick={() => goTo('competence')}/>
        </Tabs>


        <Grid container spacing={1} justifyContent="center">
            <Grid item md={10} xs={0} display={{xs: 'none', md: 'block'}}>
                <Typography typography="h2" sx={{mt: 3, mb: 1}}>Survey
                    results</Typography>
            </Grid>
            <Grid item md={3} sx={{display: {xs: 'none', sm: 'block'}}}>
                <Paper elevation={0} sx={{...styles.paperCenter, height: 'auto', textAlign: 'left'}}>
                    <Typography
                        sx={{mb: 2, fontWeight: "600"}}>{"Profile ID: " + accountNumber}</Typography>
                    <Typography typography="body2" sx={{
                        mb: 2,
                        cursor: 'pointer',
                        color: current === 'personality' ? '#809252' : 'primary'
                    }} onClick={() => goTo('personality')}>Personality map</Typography>
                    <Typography typography="body2" sx={{
                        mb: 2,
                        cursor: 'pointer',
                        color: current === 'values' ? '#809252' : 'primary'
                    }} onClick={() => goTo('values')}>Values map</Typography>
                    <Typography typography="body2" sx={{
                        mb: 2,
                        cursor: 'pointer',
                        color: current === 'top' ? '#809252' : 'primary'
                    }} onClick={() => goTo('top')}>Top motivators</Typography>
                    <Typography variant="body2" sx={{
                        mb: 2,
                        cursor: 'pointer',
                        color: current === 'lowest' ? '#809252' : 'primary'
                    }} onClick={() => goTo('lowest')}>Lowest priority</Typography>
                    <Typography typography="body2" sx={{
                        cursor: 'pointer',
                        color: current === 'competence' ? '#809252' : 'primary'
                    }} onClick={() => goTo('competence')}>Competence map</Typography>

                    {loading ? (
                        <CircularProgress size={24} sx={{mt: "2rem"}}/>
                    ) : (
                        <Box
                            sx={{
                                mt: "2rem",
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                                '& > :first-child': {
                                    marginRight: '8px',
                                },
                            }}
                            onClick={() => downloadAsPdf()}>

                            <Typography variant="body2"><DownloadIcon fontSize={"inherit"}
                                                                      sx={{mr: 1}}/>Download
                                PDF</Typography>

                        </Box>
                    )}


                </Paper>
            </Grid>
            <Grid item xs={12} md={7}>
                <Stack spacing={1}>
                    <Paper elevation={0} sx={{...styles.paperCenter}}
                           ref={(el) => refs.current['personality'] = el}>
                        <UserTestMain metrixOverview={metrixOverviews?.personalityOverview}/>
                    </Paper>

                    <Paper elevation={0} sx={{...styles.paperCenter}} className={"downloadable-pdf"}
                    >
                        <Typography typography="h3">Personality map</Typography>

                        <Typography typography="body2" sx={{mt: 1, mb: 3}}>Your unique traits and
                            behavioural characteristics. How
                            you interact with other people and the surrounding environment.</Typography>

                        <UserValuesHighestLowest
                            elements={metrixOverviews?.personalityOverview.values}
                            averages={metrixOverviews?.averages}/>
                    </Paper>

                    <Paper elevation={0} sx={{...styles.paperCenter}} className={"downloadable-pdf"}
                           ref={(el) => refs.current['values'] = el}>
                        <UserValuesMap overviewData={metrixOverviews?.valuesOverview}/>
                    </Paper>

                    <Paper elevation={0} sx={{...styles.paperCenter}} className={"downloadable-pdf"}
                           ref={(el) => refs.current['top'] = el}>
                        <TopMotivators overviewData={metrixOverviews?.valuesOverview}
                                       averages={metrixOverviews?.averages}/>
                    </Paper>

                    <Paper elevation={0} sx={{...styles.paperCenter}} className={"downloadable-pdf"}
                           ref={(el) => refs.current['lowest'] = el}>
                        <LowestPriority overviewData={metrixOverviews?.valuesOverview}
                                        averages={metrixOverviews?.averages}/>
                    </Paper>

                    <Paper elevation={0} sx={{...styles.paperCenter}} className={"downloadable-pdf"}
                           ref={(el) => refs.current['competence'] = el}>
                        <UserCompetences elements={metrixOverviews?.competenciesOverview.values}/>
                    </Paper>
                </Stack>
            </Grid>

        </Grid>
    </ThemeProvider>
}

export default SurveyResults;
