import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Dialog from "@mui/material/Dialog";
import ProductPackageSelection from "./ProductPackageSelection";



function ProductPackageSelectionModal({open, onClose, onCancel, availableContactRequests, jobPositionId,
                                          showOnlyQualifiedPackage = false,
                                          jumpToQualifiedPackageConfirmation = false,
}: {
    open: boolean;
    onClose: () => void;
    onCancel: () => void;
    availableContactRequests: number;
    jobPositionId: number;
    showOnlyQualifiedPackage?: boolean;
    jumpToQualifiedPackageConfirmation?: boolean;
}) {



    return (
        <Dialog
            open={open}
            onClose={() => {}}
            PaperProps={{ style: { maxWidth: '900px' } }}
        >
            <ProductPackageSelection
                open={open}
                onClose={onClose}
                onCancel={onCancel}
                availableContactRequests={availableContactRequests}
                jobPositionId={jobPositionId}
                showOnlyQualifiedPackage={showOnlyQualifiedPackage}
                jumpToQualifiedPackageConfirmation={jumpToQualifiedPackageConfirmation}
            />
        </Dialog>
    );
}

export default ProductPackageSelectionModal;
