import React from 'react';
import {Navigate, Route, Routes} from 'react-router';
import {getAuth} from 'firebase/auth';
import {useAtomValue} from 'jotai';
import CreateAccount from '../pages/CreateAccount';
import Offers from '../pages/Student/Offers/Offers';
import StudentPreferences from '../pages/Student/Preferences/StudentDashboard';
import CompanyFilter from '../pages/Company/Positions/Filter/CompanyFilter';
import CompanyProfileSelect from '../pages/Company/Positions/Select/CompanyProfileSelect';
import CompanyOffer from '../pages/Company/Positions/Offer/CompanyOffer';
import CompanyOfferStatus from '../pages/Company/OfferStatus/CompanyOfferStatus';
import {userAtom} from '../store/userStore';
import {UserType} from '../models/IUser';
import Account from '../pages/Company/Account/Account';
import AdminDashboard from '../pages/Admin/Dashboard/AdminDashboard';
import TermsAndConditions from "../pages/Login/TermsAndConditions";
import Welcome from "../pages/CreateAccount/Welcome";
import Test from "../pages/Student/Test";
import Survey from "../pages/Student/Survey";
import Login from "../pages/Login/Login";
import PrivacyPolicy from "../pages/Login/PrivacyPolicy";
import Game from "./Game";
import Game2 from "./Game2";
import StudentAccount from "../pages/Student/Account/StudentAccount";
import EmailConfirmLogin from "../pages/Login/EmailConfirmLogin";
import PhoneLogin from "../pages/Login/PhoneLogin";
import PasswordReset from "../pages/Login/PasswordReset";
import CompanyDashBoard from "../pages/Company/CompanyDashboard";
import CreateAccountCompany from "../pages/CreateAccount/Company/CreateAccountCompany";
import CompanySettings from "../pages/Company/Settings/CompanySettings";

function AppRoutes() {
    const user = useAtomValue(userAtom);
    const isCompany = user?.accountType === UserType.COMPANY;
    const isAdmin = user?.isAdmin === true;

    const auth = getAuth().currentUser;

    const routes = [<Route path="/game" element={<Game/>}/>,
        <Route path="/mess_game" element={<Game2/>}/>];

    if (auth) {
        if (user) {
            if (isCompany) {
                routes.push(
                    <Route path="/" element={<CompanyDashBoard/>}/>,
                    <Route path="/offer-status" element={<CompanyOfferStatus/>}/>,
                    <Route path="/account" element={<Account/>}/>,
                    <Route path="/settings" element={<CompanySettings/>}/>,
                    <Route path="/positions/:id/filter" element={<CompanyFilter/>}/>,
                    <Route path="/positions/:id/select" element={<CompanyProfileSelect/>}/>,
                    <Route path="/positions/:id/offer" element={<CompanyOffer/>}/>,
                );
                if (isAdmin) {
                    routes.push(
                        <Route path="/admin" element={<AdminDashboard/>}/>,
                    );
                }
            } else {
                routes.push(
                    <Route path="/preferences" element={<StudentPreferences/>}/>,
                    <Route path="/account" element={<StudentAccount/>}/>,
                    <Route path="/test" element={<Test/>}/>,
                    <Route path="/survey" element={<Survey/>}/>,
                    <Route path="/" element={<Offers/>}/>,
                    <Route path="/offers" element={<Offers/>}/>,
                );
            }
            routes.push(
                <Route path="*" element={<Navigate replace to={"/"}/>}/>
            );
        }

    } else {
        routes.push(
            <Route path="/login" element={<Login/>}/>,
            <Route path="/create-account" element={<CreateAccount/>}/>,
            <Route path="/create-account-company" element={<CreateAccountCompany/>}/>,
            <Route path="/login/confirm" element={<EmailConfirmLogin/>}/>,
            <Route path="/login/confirm/:email" element={<EmailConfirmLogin/>}/>,
            <Route path="/password-reset" element={<PasswordReset/>}/>,
            <Route path="/login/phone" element={<PhoneLogin/>}/>,
            <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>,
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>,
            <Route path="/welcome" element={<Welcome/>}/>,
            <Route path="*" element={<Navigate replace to={"/login"}/>}/>
        );
    }

    return (
        <Routes>
            {routes.map((route, i) => <React.Fragment key={i}>{route}</React.Fragment>)}
        </Routes>
    );
}


export default AppRoutes;
