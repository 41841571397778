import {Box, Link, Typography} from "@mui/material";
import UploadIcon from "../../../../assets/UploadIcon";
import React, {ChangeEvent, useEffect, useState} from "react";
import useFileApi from "../../../../api/useFileApi";
import {toast} from "react-toastify";
import DeleteIcon2 from "../../../../assets/DeleteIcon2";


const styles = {
    formBox: {
        height: '5.6rem',
        width: '100%',
        maxWidth: '100%',
        textAlign: 'center',
        position: 'relative',
        border: '1px dashed #99DEFF',
        backgroundColor: '#F2F6FB',
        borderRadius: '0.5rem',
        '&:hover': {
            //backgroundColor: 'red',
            //opacity: '0.04'
        },
    },
    uploadBox: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
    }
};

const DragDropFileUpload = () => {
    const [dragActive, setDragActive] = useState(false);
    const [file, setFile] = useState<any | null>(null);
    const inputRef = React.useRef<any>(null);
    const {uploadGradeSheet, getGradeSheet, deleteGradeSheet} = useFileApi();

    const loadGradeSheet = async () => {
        try {
            const gradeSheet = await getGradeSheet();
            setFile(gradeSheet);
        } catch (e: any) {
            if (e.message !== 'OBJECT_NOT_FOUND') {
                throw e
            }
        }
    }

    const submitUpload = async (uploadFile: File) => {
        if (!uploadFile) {
            toast.error('No file to upload');
            return;
        }
        const formData = new FormData();
        formData.append('file', uploadFile);

        await uploadGradeSheet(formData).then(() => {
            toast.success('Grade sheet upload was successful!');
        }).catch(() => {
            toast.error('Failed to find user with email!');
        });
    };

    const handleChange = async function (e: ChangeEvent<HTMLInputElement>) {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            await submitUpload(e.target.files[0]);
            await loadGradeSheet()
        }
    };

    const handleDrop = async (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            await submitUpload(e.dataTransfer.files[0]);
        }
    };

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    const handleDelete = async () => {
        await deleteGradeSheet()
        setFile(null)
    }

    useEffect(() => {
        loadGradeSheet();
    }, [])

    if (file) {
        return (
            <Box sx={{border: '1px solid #D3D3D3', borderRadius: '0.5rem', backgroundColor: '#F6F6F6'}}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', padding: '0.7rem', alignItems: 'center'}}>
                    <Link sx={{textDecoration: 'none'}} download="grade-sheet.pdf"
                          href={`data:application/pdf;base64,${file.content}`}>Download grade sheet</Link>
                    <Box onClick={handleDelete} sx={{cursor: 'pointer'}}>
                        <DeleteIcon2/>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <Box onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()} sx={styles.formBox}>
                <input ref={inputRef} type="file" id="input-file-upload" multiple={true} onChange={handleChange}
                       style={{display: 'none'}}/>
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : ""}
                       style={dragActive ? {backgroundColor: '#ffffff'} : {}}>
                    <Box sx={styles.uploadBox}>
                        <UploadIcon/>
                        <Typography sx={{fontSize: '0.75rem', color: '#000000', fontWeight: '400'}}>Drag or browse
                            gradesheet here
                            (optional)</Typography>

                    </Box>
                </label>
                {dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag}
                                    onDragOver={handleDrag} onDrop={handleDrop}
                                    style={{
                                        position: 'absolute',
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '1rem',
                                        top: 0,
                                        right: 0,
                                        bottom: 0,
                                        left: 0
                                    }}
                />}

            </Box>
        </>
    )
};

export default DragDropFileUpload;
