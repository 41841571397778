import React, {useEffect, useState} from 'react';
import {Box, Button, Divider, Grid, Link, TextField, ThemeProvider, Typography} from '@mui/material';
import LoginImage from "../../assets/LoginImage";
import JoblingLogo from "../../assets/JoblingLogo";
import GoogleIcon from "../../assets/GoogleIcon";
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';

import {
    beforeAuthStateChanged,
    getAuth,
    GoogleAuthProvider,
    OAuthCredential,
    signInWithEmailAndPassword,
    signInWithPopup
} from "firebase/auth";
import useUserApi from "../../api/useUserApi";
import {tomatoTheme} from "../../components/theme";
import {toast} from "react-toastify";
import {useNavigate} from "react-router";
import {isValidEmail} from "../../util/formatUtil";
import AccountTypeDialog from "../CreateAccount/AccountTypeDialog";


function Login() {

    const [email, setEmail] = useState('');
    const [password, setPassWord] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const {getUserByEmail} = useUserApi()
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);


    useEffect(() => {
        const unsubscribe = beforeAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
                return;
            }

            try {
                await getUserByEmail(user.email!)
            } catch (e: any) {
                console.log(e)
            }

        });

        return unsubscribe;
    }, []);


    const isFormValid = () => {
        const errors = [];
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        if (password === undefined || password?.trim().length === 0) {
            errors.push('password');
        }
        setErrorFields(errors);
        return errors.length <= 0;
    }

    const onEmailSubmit = async () => {
        if (isFormValid()) {
            signInWithEmailAndPassword(getAuth(), email, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found') {
                        toast.error('User not found!')
                    } else if (error.code === 'auth/wrong-password') {
                        toast.error('Wrong password!')
                    } else {
                        console.log(error.code)
                        const errorMessage = error.message;
                        toast.error(errorMessage)
                    }

                });
        }
    };

    const handleKeyDown = async (key: string) => {
        if (key === 'Enter') {
            await onEmailSubmit();
        }
    }

    const googleHandler = async (e: any) => {

        e.preventDefault()

        // login || signup
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        })

        signInWithPopup(getAuth(), provider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result) as OAuthCredential;
                const token = credential.accessToken;
                const user = result.user;
            }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(error)
        });
    };

    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <Box sx={{cursor: 'pointer'}} onClick={() => window.location.href = 'https://www.jobling.ee/'}>
                        <JoblingLogo/>
                    </Box>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>

                    <ThemeProvider theme={tomatoTheme}>

                        <Box sx={{maxWidth: '25rem', pl: 1, pr: 1, width: '100%'}}>

                            <Button id={"google-login"} sx={{
                                border: '1px solid #FFA149',
                                backgroundColor: 'white',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '3rem',
                                borderRadius: '0.5rem',
                                mb: 3
                            }} onClick={googleHandler}>
                                <GoogleIcon/>
                                <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600, ml: 1}}>Log in
                                    with Google</Typography>
                            </Button>

                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider><Typography>or</Typography></Divider>
                            </Box>

                            <Box sx={{
                                border: '1px solid #FFA149',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                <TextField
                                    error={errorFields.includes("email")}
                                    helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                                    variant="outlined"
                                    label={"E-mail"}
                                    value={email}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>
                                <TextField
                                    error={errorFields.includes("password")}
                                    helperText={!errorFields.includes("password") ? "" : "Missing password"}
                                    type={"password"}
                                    variant="outlined"
                                    label={"Password"}
                                    value={password}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => setPassWord(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",

                                    }}/>

                                <Button
                                    id="login-button"
                                    sx={{
                                        border: '1px solid #FFA149',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '3rem',
                                        borderRadius: '0.5rem',
                                        mb: 2
                                    }}
                                    onClick={() => onEmailSubmit()}
                                >
                                    <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Log in
                                        with e-mail</Typography>
                                </Button>
                                <Link href={"/password-reset"} sx={{color: '#FFA149', textDecorationColor: '#FFA149'}}>
                                    <Typography textAlign={"right"} fontSize={"0.7rem"}>
                                        Forgot password?
                                    </Typography>
                                </Link>
                            </Box>

                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider><Typography>or</Typography></Divider>
                            </Box>


                            <Button
                                id="login-button"
                                sx={{
                                    border: '1px solid #FFA149',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '3rem',
                                    borderRadius: '0.5rem',
                                    mb: 2
                                }}
                                onClick={() => navigate('/login/phone')}
                            >
                                <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>
                                    <MobileFriendlyIcon fontSize={"inherit"} sx={{marginRight: "0.5rem"}}/>
                                    Log in with phone number
                                </Typography>
                            </Button>
                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider></Divider>
                            </Box>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                Don't have an account yet? <Link onClick={() => setOpen(true)} sx={{cursor: 'pointer'}}>Create account</Link>
                            </Box>
                            <AccountTypeDialog
                                open={open}
                                onClose={() => setOpen(false)}
                            />
                        </Box>
                    </ThemeProvider>

                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    );
}

export default Login;
