import React from 'react';
import {atom} from 'jotai';
import {IUser} from '../models/IUser';
import {IUserProfile} from '../models/IUserProfile';

export const userAtom = atom<IUser | null>(null);
export const profileAtom = atom<IUserProfile | null>(null);
export const profileVisibleAtom = atom<boolean>(false);
export const profileSubmittedAtom = atom<boolean>(false);
export const unsavedPreferencesAtom = atom<boolean>(false);

