import React from 'react';
import {IUniversity} from './IUniversity';
import {MetrixCode} from './ChartData';

export enum FilterStatus {
    PENDING = 'PENDING',
    CONFIRMED = 'CONFIRMED',
    REJECTED = 'REJECTED',
}

export enum OfferStatus {
    PENDING = 'PENDING',
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
}

export interface IUserProfile {
    id: number,
    accountNumber: number,
    profileViews: number,
    profileVisible: boolean,
    profileSubmitted: boolean,
    modifiedDate: string | null,
    waysOfWorking?: string[];
    fieldOfWorking?: string[];
    location?: string[];
    fieldOfWorkingOther?: string;
    locationOther?: string;
    languages?: string[];
    description?: string;
    typeOfWork?: string;
    universities: IUniversity[];
    filterStatus?: FilterStatus | null;

    fullName?: string;
    email: string;
    callingCode: string;
    phone: string;
}

export interface IUserMetrix {
    metrix: MetrixCode;
    value: number;
}
