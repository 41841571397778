import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Button, Grid, Stack, Typography} from "@mui/material";
import TestIntroImage from "../../../assets/TestIntroImage";
import useUserApi from "../../../api/useUserApi";
import {useAtomValue} from "jotai";
import {userAtom} from "../../../store/userStore";
import {getAuth} from "firebase/auth";
import {Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import IntroCarosel1Image from "../../../assets/IntroCarosel1Image";
import IntroCarosel2Image from "../../../assets/IntroCarosel2Image";
import IntroCarosel3Image from "../../../assets/IntroCarosel3Image";
import IntroCarosel4Image from "../../../assets/IntroCarosel4Image";

const styles = {
    slide: {
        fontSize: "18px",
        background: "#fff",
        display: "flex",
    },
};


function TestIntro({
                       setActiveStep,
                       answers,
                       buttonAction
                   }: { setActiveStep: (step: number) => void, answers: number, buttonAction: () => void }) {

    const user = useAtomValue(userAtom)
    const {remindLater} = useUserApi()

    const remindMeLater = async () => {
        await remindLater(user?.id!)
        await getAuth().signOut()
    }

    return (

        <Box sx={{display: 'flex', justifyContent: 'center', height: '100vh'}}>
            <Box sx={{maxWidth: '66.5rem', width: '100%', mt: {xs: 0, md: '5rem'}}}>
                <Grid container>
                    <Grid item sx={{p: {xs: 0, md: 0}}}>
                        <Box sx={{maxWidth: '39.5rem', width: '100%'}}>
                            <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                                <Typography typography="h1" sx={{fontSize: {xs: '1.5rem', md: '2.5rem'}}}>Welcome to
                                    Jobling.</Typography>
                                <Typography typography="h1" sx={{mb: 3, fontSize: {xs: '1.5rem', md: '2.5rem'}}}>We’re
                                    glad you’re here.</Typography>

                                <Typography typography="h2" sx={{mb: 3, fontSize: {xs: '1.25rem', md: '1.5rem'}}}>3 easy
                                    steps to land your dream job</Typography>
                                <Box sx={{pl: 1}}>
                                    <Typography typography="body1">1. Take the personality test (15min). Plan your time,
                                        make sure you are well-rested and not be disturbed during that time</Typography>
                                    <Typography typography="body1">2. Finalize your profile and establish your goals
                                        (5min)</Typography>
                                    <Typography typography="body1" sx={{mb: 2}}>3. Make your profile visible to
                                        employers. Sit back and we will inform you as soon as the invitation
                                        arrives.</Typography>
                                </Box>
                            </Box>
                            <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                                <Swiper
                                    style={{
                                        width: "100vw",
                                        height: "500px",
                                        alignItems: 'center'
                                    }}
                                    spaceBetween={30}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    <SwiperSlide style={styles.slide}>
                                        <Stack alignItems="center" sx={{padding: "25px"}}>
                                            <Typography typography="h1" sx={{fontSize: {xs: '20px'}}}>Welcome to
                                                Jobling</Typography>
                                            <Typography typography="h1" sx={{mb: 3, fontSize: {xs: '20px'}}}>We’re
                                                glad you’re here</Typography>
                                            <IntroCarosel1Image/>
                                            <Typography typography="h2"
                                                        sx={{textAlign: "center", mt: 2, fontSize: {xs: '16px'}}}>4 easy
                                                steps to land your dream job</Typography>

                                            <Typography typography="body1" sx={{mt: 1, textAlign: "center"}}>If you
                                                receive an
                                                invitation to a job
                                                interview, Jobling will send you a notice to your e-mail, which you can
                                                ACCEPT or REJECT </Typography>

                                        </Stack>
                                    </SwiperSlide>
                                    <SwiperSlide style={styles.slide}>
                                        <Stack alignItems="center" sx={{padding: "25px"}}>
                                            <Typography typography="h1"
                                                        sx={{mb: 3, textAlign: "center", fontSize: {xs: '20px'}}}>1.
                                                Take the
                                                personality test (15min)</Typography>
                                            <IntroCarosel2Image/>
                                            <Typography typography="body1" sx={{mt: 2, textAlign: "center"}}>Plan your
                                                time, make sure you are well-rested and not be disturbed during that
                                                time</Typography>

                                        </Stack>
                                    </SwiperSlide>
                                    <SwiperSlide style={styles.slide}>
                                        <Stack alignItems="center" sx={{padding: "25px"}}>
                                            <Typography typography="h1"
                                                        sx={{mb: 3, textAlign: "center", fontSize: {xs: '20px'}}}>2.
                                                Finalize your
                                                profile (5min)</Typography>
                                            <IntroCarosel3Image/>
                                            <Typography typography="body1" sx={{mt: 2, textAlign: "center"}}>Finalize
                                                your profile and establish your goals</Typography>
                                        </Stack>
                                    </SwiperSlide>
                                    <SwiperSlide style={styles.slide}>
                                        <Stack alignItems="center" sx={{padding: "25px"}}>
                                            <Typography typography="h1"
                                                        sx={{mb: 3, textAlign: "center", fontSize: {xs: '20px'}}}>3.
                                                Make your profile
                                                visible to employers</Typography>
                                            <IntroCarosel4Image/>
                                            <Typography typography="body1" sx={{mt: 2, textAlign: "center"}}>Sit back
                                                and we will inform you as soon as the invitation arrives.</Typography>
                                        </Stack>
                                    </SwiperSlide>
                                </Swiper>
                            </Box>

                            <Box sx={{
                                display: {xs: 'block', md: 'flex'},
                                textAlign: 'center',
                                padding: "0px 25px 0px 25px"
                            }}>
                                <Button variant="contained"
                                        sx={{mb: {xs: 1, md: 0}, width: {xs: '100%', md: 'inherit'}}}
                                        onClick={() => buttonAction()}>
                                    {answers > 0 ? 'Continue your journey' : 'Start your journey'}

                                </Button>
                                <Button variant="outlined" sx={{ml: {xs: 0, md: 2}, width: {xs: '100%', md: 'inherit'}}}
                                        onClick={() => remindMeLater()}>Remind me after 5 days</Button>

                            </Box>

                        </Box>
                    </Grid>
                    <Grid item sx={{display: {xs: 'none', md: 'inline'}}}>
                        <Box sx={{ml: '7.75rem'}}>
                            <TestIntroImage/>
                        </Box>
                    </Grid>

                </Grid>
            </Box>

        </Box>
    );
}

export default TestIntro;
