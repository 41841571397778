import React, {useEffect, useState} from 'react';
import {Box, Link, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../../components/theme';
import {FilterStatus, IUserProfile} from '../../../../models/IUserProfile';
import RejectedIndicator from '../../../../assets/RejectedIndicator';
import {IFilteredStudent} from '../../../../models/IFilteredStudent';
import {formatProfileLabel} from "../../../../util/formatUtil";

function CompanyProfileSelectLeft({
                                      students,
                                      activeProfile,
                                      setActiveProfile,
                                  }: {
    students: IFilteredStudent[],
    activeProfile: IUserProfile | null,
    setActiveProfile: any
}) {
    const [pendingProfiles, setPendingProfiles] = useState<IFilteredStudent[]>([]);
    const [pendingProfilesOneMismatch, setPendingProfilesOneMismatch] = useState<IFilteredStudent[]>([]);
    const [pendingProfilesMoreMismatches, setPendingProfilesMoreMismatches] = useState<IFilteredStudent[]>([]);
    const [rejectedProfiles, setRejectedProfiles] = useState<IFilteredStudent[]>([]);

    useEffect(() => {
        const pending: IFilteredStudent[] = [];
        const oneMismatch: IFilteredStudent[] = [];
        const moreMismatches: IFilteredStudent[] = [];
        students.forEach(student => {
            if(student.filterStatus === FilterStatus.PENDING) {
                if(student.mismatches === 0) {
                    pending.push(student)
                }
                if(student.mismatches === 1) {
                    oneMismatch.push(student)
                }
                if(student.mismatches > 1) {
                    moreMismatches.push(student)
                }
            }
        })

        setPendingProfiles(prev => pending);
        setPendingProfilesOneMismatch(prev => oneMismatch);
        setPendingProfilesMoreMismatches(prev => moreMismatches);
        setRejectedProfiles(students.filter((students) => students.filterStatus === FilterStatus.REJECTED));
    }, [students]);

    const renderProfileItem = (p: IFilteredStudent, i: number) => {
        const color = p.id === activeProfile?.id ? '#809252' : '#303030';
        const fontWeight = p.id === activeProfile?.id ? 'bold' : 'normal';
        return (
            <Box key={i} display="flex">
                <Link
                    href="#"
                    onClick={() => setActiveProfile(p)}
                    style={{
                        color: color,
                        fontWeight: fontWeight,
                        textDecoration: 'none',
                        marginBottom: '1rem'
                    }}
                >
                    {p.accountNumber}
                </Link>
                {
                    p.mismatches > 0
                        ? (
                            <Typography sx={styles.mismatch}>
                                (-
                                {p.mismatches}
                                )
                            </Typography>
                        ) : ''
                }

            </Box>
        );
    }

    const showPending = () => {
        return (pendingProfiles && pendingProfiles.length > 0) || (pendingProfilesOneMismatch && pendingProfilesOneMismatch.length > 0) || (pendingProfilesMoreMismatches && pendingProfilesMoreMismatches.length > 0)
    }

    return (
        <Stack sx={styles.leftStack} spacing={1}>
            {showPending()
                ? (
                    <Paper elevation={0} sx={styles.paper}>
                        <Typography sx={{fontSize: '0.75rem', fontWeight: '700', color: '#000000', marginBottom: '1rem'}}>Perfect profile match ({pendingProfiles.length})</Typography>
                        {pendingProfiles?.map((p, i) => renderProfileItem(p, i))}
                        <Typography sx={{fontSize: '0.75rem', fontWeight: '700', color: '#000000', marginBottom: '1rem'}}>1 profile mismatch ({pendingProfilesOneMismatch.length})</Typography>
                        {pendingProfilesOneMismatch?.map((p, i) => renderProfileItem(p, i))}
                        <Typography sx={{fontSize: '0.75rem', fontWeight: '700', color: '#000000', marginBottom: '1rem'}}>2 or more profile mismatches ({pendingProfilesMoreMismatches.length})</Typography>
                        {pendingProfilesMoreMismatches?.map((p, i) => renderProfileItem(p, i))}
                    </Paper>
                ) : ''}
            {rejectedProfiles && rejectedProfiles.length > 0

                ? (
                    <Paper elevation={0} sx={styles.paper}>

                        {rejectedProfiles?.map((p, i) => {
                            const color = p.id === activeProfile?.id ? '#809252' : '#303030';
                            const fontWeight = p.id === activeProfile?.id ? 'bold' : 'normal';
                            const margin = i === rejectedProfiles.length - 1 ? '0rem' : '1rem';

                            return (
                                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: margin}} key={i}>
                                    <Link
                                        href="#"
                                        onClick={() => setActiveProfile(p)}
                                        style={{
                                            color: color,
                                            fontWeight: fontWeight,
                                            textDecoration: 'none',
                                        }}
                                    >
                                        {formatProfileLabel(p.accountNumber)}
                                    </Link>
                                    <Box sx={{flexGrow: 1}}/>
                                    <RejectedIndicator/>
                                </Box>
                            );
                        })}

                    </Paper>
                ) : ''}

            <Box width="100%" height="4rem"/>

        </Stack>
    );
}

export default CompanyProfileSelectLeft;
