import React from 'react';

function GoogleIcon() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 8.17999C16 7.65332 15.9523 7.15332 15.8706 6.66666H8.17371V9.67332H12.5807C12.3832 10.66 11.8042 11.4933 10.9459 12.06V14.06H13.5752C15.1145 12.6667 16 10.6133 16 8.17999Z" fill="#4285F4"/>
            <path d="M8.17368 16C10.3806 16 12.2265 15.28 13.5751 14.06L10.9459 12.06C10.2103 12.54 9.27713 12.8334 8.17368 12.8334C6.04171 12.8334 4.23669 11.4267 3.5896 9.5267H0.878662V11.5867C2.22051 14.2 4.97913 16 8.17368 16Z" fill="#34A853"/>
            <path d="M3.58961 9.52666C3.41933 9.04666 3.33078 8.53333 3.33078 7.99999C3.33078 7.46666 3.42614 6.95333 3.58961 6.47333V4.41333H0.878671C0.320136 5.49333 0 6.70666 0 7.99999C0 9.29333 0.320136 10.5067 0.878671 11.5867L3.58961 9.52666Z" fill="#FBBC05"/>
            <path d="M8.17368 3.16667C9.3793 3.16667 10.4555 3.57334 11.3069 4.36667L13.6364 2.08667C12.2265 0.793334 10.3806 0 8.17368 0C4.97913 0 2.22051 1.8 0.878662 4.41334L3.5896 6.47334C4.23669 4.57334 6.04171 3.16667 8.17368 3.16667Z" fill="#EA4335"/>
        </svg>

    );
}

export default GoogleIcon;
