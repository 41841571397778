import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Button, CircularProgress, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import Dialog from "@mui/material/Dialog";
import {IOrderRequest} from "../../../models/IOrderRequest";
import {IOrderResponse} from "../../../models/IOrderResponse";
import useOrdersApi from "../../../api/useOrderApi";
import {IPackageType} from "../../../models/IPackageType";
import {useAtomValue} from "jotai";
import {productPackagesAtom} from "../../../store/ordersStore";
import PaymentMethodSelection from './PaymentMethodSelection';
import {IOrderRequestPaymentMethod} from "../../../models/IOrderRequestPaymentMethod";


interface RejectOfferDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

function PaymentConfirmationDialog({open, setOpen}: RejectOfferDialogProps) {

    const [isSubmitting, setIsSubmitting] = useState(false);
    const {createOrder} = useOrdersApi();
    let productPackages = useAtomValue(productPackagesAtom);
    let chooseCandidatesPackage = productPackages?.find((productPackage) => productPackage.type === IPackageType.CHOOSE)

    const [isPaymentMethodSelectionScreen, setIsPaymentMethodSelectionScreen] = useState(false);

    const onSubmit = async (paymentMethod: IOrderRequestPaymentMethod) => {
        setIsSubmitting(true);
        if (chooseCandidatesPackage === undefined) {
            alert("Failed to load product packages. Please reload the page and try again");
            return;
        }
        if (paymentMethod === null) {
            alert("Payment method is not selected");
            return;
        }
        let returnUrl = window.location.href;
        const indexOfQuery = returnUrl.indexOf('?');
        if (indexOfQuery !== -1) {
            returnUrl = returnUrl.substring(0, indexOfQuery);
        }
        const order: IOrderRequest = {
            productPackageId: chooseCandidatesPackage.id,
            paymentMethod: paymentMethod,
            returnUrl: returnUrl
        }
        try {
            await createOrder(order);
        } catch (e) {
        } finally {
            setIsSubmitting(false);
            setOpen(false);
        }
    };

    const selectPaymentMethod = async () => {
        setIsPaymentMethodSelectionScreen(true);
    };

    function paymentMethodCancel() {
        setOpen(false);
        setIsPaymentMethodSelectionScreen(false);
    }

    function configurePaymentMethod(paymentMethod: IOrderRequestPaymentMethod) {
        onSubmit(paymentMethod);
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{style: {maxWidth: '900px'}}}
        >
            {!isPaymentMethodSelectionScreen ? <>
                    <DialogContent sx={{padding: "50px"}}>
                        <DialogContentText id="alert-dialog-description">
                            Dear Platform User,<br/>
                            <br/>
                            We trust you're having a positive experience on our platform, and we'd like to update you some
                            important details:<br/>
                            <br/>
                            <br/>
                            <strong>Payment Notice:</strong><br/>
                            You've selected the "<strong>{chooseCandidatesPackage?.name}</strong>" option, priced
                            at <strong>{chooseCandidatesPackage?.price} €</strong><br/>
                            From now on, sending interview invitations to candidates on our platform will result in charges
                            as
                            per our payment terms and an invoice will be generated accordingly.<br/>
                            <br/>
                            <br/>
                            <strong>Candidate Offers:</strong><br/>
                            With this option, you can extend offers to up to {chooseCandidatesPackage?.quantity} suitable
                            candidates. Please keep in mind that
                            the respose rate is typically in the range of 20% to 30%.<br/>
                            <br/>
                            By clicking "<strong>Accept and Pay</strong>" you confirm your acceptance of the payment terms.
                            If
                            you need any
                            help, please don't hesitate to contact as at <strong>info@jobling.ee</strong>. We're here to
                            assist
                            you with any
                            additional requirements.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button
                            color='primary'
                            variant='contained'
                            type="submit"
                            // onClick={onSubmit}
                            onClick={selectPaymentMethod}
                            sx={{ml: 2}}
                        >
                            {isSubmitting && <CircularProgress disableShrink size={16} sx={{mr: 1}}/>}
                            Accept and Proceed
                        </Button>
                    </DialogActions>
                </> :
                <PaymentMethodSelection setPaymentMethod={configurePaymentMethod} onCancel={paymentMethodCancel}
                                        isSubmitting={isSubmitting}/>}
        </Dialog>
    );
}

export default PaymentConfirmationDialog;
