import React, {ChangeEvent, useEffect, useState} from 'react';
import {Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,} from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import {styles} from '../../../components/theme';
import useUserApi from "../../../api/useUserApi";
import {IUserItem} from "../../../models/IUserItem";
import useFileApi from "../../../api/useFileApi";
import {toast} from "react-toastify";

const COLUMNS = [
    {name: 'Name', width: '8rem'},
    {name: 'Email', width: '11rem'},
    {name: 'Phone', width: '7rem'},
    {name: 'Created', width: '7rem'},
    {name: 'Wisnio PDF', width: '2rem'},
    {name: '', width: '2rem'},
];

function AdminDashboardMiddle({
                                  activePage,
                              }: {
    activePage: any,
}) {
    const {uploadTestFile} = useFileApi();

    const [users, setUsers] = useState<IUserItem[]>([]);
    const {getUsers} = useUserApi();

    const loadData = async () => {
        const fetchedUsers = await getUsers();
        setUsers(fetchedUsers);
    };

    const uploadUserTestFile = async (event: ChangeEvent<HTMLInputElement>, email: string) => {
        if (event.target.files) {
            const file = event.target.files[0];

            if (!file || email.length === 0) {
                toast.error('Both file and email are required!');
                return;
            }
            const formData = new FormData();
            formData.append('file', file);
            formData.append('email', email);

            await uploadTestFile(formData).then(() => {
                toast.success('PDF upload for student was successful!');
                loadData()
            }).catch(() => {
                toast.error('Failed to find user with email!');
            });
        }
    };

    useEffect(() => {
        loadData();
    }, [activePage]);

    return (
        <Stack spacing={1}>
            <Paper elevation={0} sx={styles.paper}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {COLUMNS.map((column, index) => (
                                    <TableCell key={index} >{column.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.fullName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{item.createdDate}</TableCell>
                                    <TableCell>{item.testFileId ? 'YES' : 'NO'}</TableCell>
                                    <TableCell>
                                        <label htmlFor={`contained-button-file-${index}`} style={{marginBottom: '2rem'}}>
                                            <input
                                                accept="application/pdf"
                                                id={`contained-button-file-${index}`}
                                                multiple
                                                type="file"
                                                onChange={(e) => uploadUserTestFile(e, item.email)}
                                                style={{display: 'none'}}
                                            />
                                            <UploadIcon sx={{cursor: 'pointer'}} />
                                        </label>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Paper>
        </Stack>
    );
}

export default AdminDashboardMiddle;
