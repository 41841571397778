import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import React from 'react';

const style = {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

function FilterIntroModal({open, handleClose}: { open: boolean; handleClose: () => void; }) {

    return (
        <Dialog open={open} sx={style} >
            <Box>
                <DialogTitle>Notification</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography typography="h4">You can use more than 40 different filters in the profile selection,
                            we recommend using 5-6 filters that are important</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>OK</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}

export default FilterIntroModal;
