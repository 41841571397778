import React from 'react';
import {Box, Typography,} from '@mui/material';
import {IMetrixValue} from "../../../models/IMetrixOverviews";
import MetrixRadar from "./MetrixRadar";
import {styles} from "../../../components/theme";


function UserValuesMap({elements}: {elements: IMetrixValue[] | undefined}) {

    return (
        <Box>
            <Typography sx={styles.h2}>Competency map</Typography>
            <Typography sx={{...styles.body4, mt: 0.5}}>The competencies you rely on most to achieve your professional goals.</Typography>

            <MetrixRadar data={elements}/>

        </Box>
    );
}

export default UserValuesMap;
