import React, {useEffect, useState} from 'react';
import {alpha, Autocomplete, Box, CircularProgress, Grid, Paper, Stack, TextField, Typography,} from '@mui/material';
import {useAtom, useAtomValue} from 'jotai';
import {useUpdateAtom} from 'jotai/utils';
import DomainValueSelect from '../../../components/DomainValueSelect';
import StudentFooterBar from '../../../components/student/StudentFooterBar';
import {IUserProfile} from '../../../models/IUserProfile';
import {DomainCode, IDomainValue} from '../../../models/IDomainValue';
import {
    profileAtom,
    profileSubmittedAtom,
    profileVisibleAtom,
    unsavedPreferencesAtom,
    userAtom
} from '../../../store/userStore';
import {styles} from '../../../components/theme';
import useUserProfileApi from '../../../api/useUserProfileApi';
import AdditionalInformation from "./middle/AdditionalInformation";
import HigherEducation from "./middle/HigherEducation";
import {hasValidUniversities, isValidEmail, isValidPhone} from '../../../util/formatUtil';
import {createNewUniversity} from "../../../util/educationUtil";
import {useCallbackPrompt} from "../../../hooks/useCallbackPrompt";
import UnsavedConfirmationDialog from "../../Home/UnsavedConfirmationDialog";
import {toast} from "react-toastify";


export interface IProfileForm {
    callingCode: string;
    phone: string;
    fullName: string;
    email: string;
}

const callingCodes = [
    '+372', '+358', '+371'
];

function StudentDashboardMiddle() {
    const [profile, setProfile] = useAtom(profileAtom);
    const setProfileSubmitted = useUpdateAtom(profileSubmittedAtom);
    const [profileVisible, setProfileVisible] = useAtom(profileVisibleAtom);

    const [prevUserProfile, setPrevUserProfile] = useState<IUserProfile | null>(null);
    const [userProfile, setUserProfile] = useState<IUserProfile | null>(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const {updateProfile, submitProfile} = useUserProfileApi();
    const [hasUnsavedChanges, setHasUnsavedChanges] = useAtom(unsavedPreferencesAtom);
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [showPrompt, confirmNavigation, cancelNavigation] =
        useCallbackPrompt(showDialog)
    const user = useAtomValue(userAtom);

    useEffect(() => {
        resetProfile()
    }, [profile]);

    useEffect(() => {
        if (profileVisible) {
            resetProfile()
        }
    }, [profileVisible]);

    useEffect(() => {
        if (userProfile && prevUserProfile) {
            if (hasUnsavedChanges && JSON.stringify(userProfile) === JSON.stringify(prevUserProfile)) {
                setHasUnsavedChanges(false)
                setShowDialog(false)

            } else if (!hasUnsavedChanges && JSON.stringify(userProfile) !== JSON.stringify(prevUserProfile)) {
                setHasUnsavedChanges(true)
                setShowDialog(true)
            }
        }
    }, [userProfile]);

    const resetProfile = () => {
        if (profile) {
            if (!profile.universities || profile.universities.length === 0) {
                profile.universities = [];
                const initialStudy = createNewUniversity();
                profile.universities.push(initialStudy);
            }

            const loadedProfile = {
                ...profile,
                phone: profile.phone ? profile.phone.substring(4) : '',
                callingCode: profile.phone ? profile.phone.substring(0, 4) : '',
            }
            setUserProfile(loadedProfile);
            setPrevUserProfile(loadedProfile);

            setIsLoaded(true);
        }
    };


    const setWaysOfWorking = (value: string[]) => {
        if (userProfile) {
            setUserProfile({...userProfile, waysOfWorking: value});
        }
    };

    const setFieldOfWorking = (value: string[]) => {
        if (userProfile) {
            setUserProfile({...userProfile, fieldOfWorking: value});
        }
    };

    const setLocation = (value: string[]) => {
        if (userProfile) {
            setUserProfile({...userProfile, location: value});
        }
    };

    const setLanguages = (value: string[]) => {
        if (userProfile) {
            setUserProfile({...userProfile, languages: value});
        }
    };

    const setFieldOfWorkOther = (value: string) => {
        if (userProfile) {
            setUserProfile({...userProfile, fieldOfWorkingOther: value});
        }
    };

    const setLocationOther = (value: string) => {
        if (userProfile) {
            setUserProfile({...userProfile, locationOther: value});
        }
    };

    const onSave = () => {
        setErrorFields([]);
        if (userProfile) {
            let fixedUniversities = userProfile.universities;
            if (userProfile.universities.length === 1 && userProfile.universities[0].university === undefined) {
                fixedUniversities = [];
            }
            const newUserProfile = {
                ...userProfile,
                modifiedDate: null,
                universities: fixedUniversities,
            } as IUserProfile;
            updateProfile(userProfile.id, newUserProfile);
            setProfile(userProfile);
            setPrevUserProfile(userProfile)
        }
    };

    const isFormValid = () => {
        if (!userProfile) {
            return false;
        }
        const errors = [];

        if (userProfile.fullName === undefined || userProfile?.fullName.trim().length === 0) {
            errors.push('form');
        }
        if (!isValidEmail(userProfile?.email!)) {
            errors.push('form');
        }
        if (!isValidPhone(userProfile?.callingCode! + userProfile?.phone!)) {
            errors.push('form');
        }

        if (userProfile.waysOfWorking?.length === 0) {
            errors.push('waysOfWorking');
        }
        if (userProfile.fieldOfWorking?.length === 0) {
            errors.push('fieldOfWorking');
        }
        if (userProfile.location?.length === 0) {
            errors.push('location');
        }
        if (!userProfile.description || userProfile.description.trim().length === 0) {
            // errors.push('description');
        }

        if (userProfile.languages?.length === 0) {
            errors.push('languages');
        }
        const valid = hasValidUniversities(userProfile!);
        if (!valid) {
            errors.push('universities');
        }
        setErrorFields(errors);
        if (errors.length > 0) {
            return false;
        }
        return true;
    };

    const submit = () => {
        if (userProfile) {
            const newUserProfile = {
                ...userProfile,
                phone: userProfile?.phone ? `${userProfile?.callingCode}${userProfile?.phone}` : "",
                modifiedDate: null
            } as IUserProfile;

            submitProfile(newUserProfile).then((submittedProfile) => {
                toast.success('Profile submitted! Don´t forget to make your profile visible to employers.');
                setProfileSubmitted(true);
                setProfile(newUserProfile);
            }).catch((e: Error) => {
                console.log(e)
                if (e.name.includes('uq_user_phone')) {
                    toast.error('An account already exists with this phone number!');
                } else if (e.name.includes('uq_user_email')) {
                    toast.error('An account already exists with this email!');
                } else {
                    toast.error('Failed to submit profile');
                }
            });
        }
    };

    const onSubmit = async () => {
        // await saveProfile()
        if (isFormValid()) {
            submit();
        } else {
            if (userProfile) {
                let fixedUniversities = userProfile.universities;
                if (userProfile.universities.length === 1 && userProfile.universities[0].university === undefined) {
                    fixedUniversities = [];
                }
                const newUserProfile = {
                    ...userProfile,
                    modifiedDate: null,
                    universities: fixedUniversities,
                    profileSubmitted: false,
                    profileVisible: false,
                    phone: userProfile?.phone ? `${userProfile?.callingCode}${userProfile?.phone}` : "",
                } as IUserProfile;
                updateProfile(userProfile.id, newUserProfile);
                setProfile(newUserProfile);
                setProfileVisible(false)
                setProfileSubmitted(false)
            }
        }
    };


    const formStyles = (fieldName: string) => {
        if (errorFields.includes(fieldName)) {
            return {...styles.paperCenter, border: '1px solid red', backgroundColor: alpha('#FF0000', 0.03)};
        }
        return {...styles.paperCenter};
    };


    const onFieldChange = (field: string, value: string | boolean) => {
        setUserProfile({...userProfile, [field]: value} as IUserProfile);
    };

    if (!isLoaded) {
        return (
            <CircularProgress/>
        );
    }


    function locationChangeCallback(code: string, newSelected: any, domainValues: IDomainValue[]) {
        if (code === 'ANY' && newSelected[code]) {
            newSelected = {...newSelected, TALLINN: true, TARTU: true};
        } else if (code !== 'ANY' && code !== 'REMOTE' && code !== 'OTHER' && !newSelected[code]) {
            newSelected = {...newSelected, ANY: false};
        }
        return newSelected
    }

    return (
        <>
            <UnsavedConfirmationDialog
                // @ts-ignore
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}/>
            <Stack spacing={1}>

                <Paper elevation={0} sx={formStyles('form')}>
                    <Typography typography="body1">My profile</Typography>

                    <Box sx={{display: 'flex', mt: 1}}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={6}>
                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Full name"
                                    value={userProfile?.fullName}
                                    onChange={(e) => onFieldChange('fullName', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>

                                <TextField
                                    fullWidth
                                    variant="standard"
                                    label="Email"
                                    disabled={user?.loginType === 'EMAIL' && (profile?.email ? profile?.email.length : 0) > 0}
                                    value={userProfile?.email}
                                    onChange={(e) => onFieldChange('email', e.target.value)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>

                                <Box sx={{display: 'flex', width: '100%'}}>
                                    <Autocomplete
                                        disabled={user?.loginType === 'PHONE' && (profile?.phone ? profile?.phone.length : 0) > 0}
                                        sx={{fontSize: '0.875rem'}}
                                        disableClearable
                                        disablePortal
                                        options={callingCodes}
                                        value={userProfile?.callingCode}
                                        onChange={(e, value: string) => onFieldChange('callingCode', value)}
                                        renderInput={(params) => <TextField variant="standard"
                                                                            label={"Code"}
                                                                            sx={{width: '5rem'}} {...params} />}
                                    />
                                    <TextField
                                        sx={{width: '100%', ml: 2}}
                                        variant="standard"
                                        label="Mobile"
                                        value={userProfile?.phone}
                                        disabled={user?.loginType === 'PHONE' && (profile?.phone ? profile?.phone.length : 0) > 0}
                                        onChange={(e) => onFieldChange('phone', e.target.value)}
                                    />
                                </Box>
                            </Grid>

                        </Grid>
                    </Box>

                </Paper>

                <Paper elevation={0} sx={formStyles('waysOfWorking')}>
                    <Typography typography="body1">Employment type</Typography>
                    <Typography typography="body2" sx={{mt: 1}}>I am looking for a position that is (choose as many as
                        you
                        like)</Typography>
                    <DomainValueSelect
                        domainCode={DomainCode.WORK_TYPE}
                        selectedValues={userProfile?.waysOfWorking ? userProfile?.waysOfWorking : []}
                        onSelect={setWaysOfWorking}
                    />
                </Paper>

                <Paper elevation={0} sx={formStyles('location')}>
                    <Typography typography="body1">Preferred work location</Typography>
                    <Typography typography="body2" sx={{mt: 1}}>I can work at following locations (choose as many as you like)</Typography>
                    <DomainValueSelect
                        domainCode={DomainCode.LOCATION}
                        selectedValues={userProfile?.location ? userProfile?.location : []}
                        onSelect={setLocation}
                        customCallback={locationChangeCallback}
                    />
                    {
                        userProfile?.location?.includes('OTHER') && (
                            <>
                                <TextField
                                    sx={{width: '100%', mt: 1, mb: -1}}
                                    variant="standard"
                                    placeholder="Where would you like to work"
                                    value={userProfile?.locationOther}
                                    onChange={(e) => setLocationOther(e.target.value)}
                                />
                            </>
                        )
                    }
                </Paper>


                <Paper elevation={0} sx={formStyles('fieldOfWorking')}>
                    <Typography typography="body1">Preferred field of work</Typography>
                    <Typography typography="body2" sx={{mt: 1}}>I am interested in the following fields (choose as many
                        as
                        you like)</Typography>
                    <DomainValueSelect
                        domainCode={DomainCode.FIELD_OF_WORK}
                        selectedValues={userProfile?.fieldOfWorking ? userProfile?.fieldOfWorking : []}
                        onSelect={setFieldOfWorking}
                    />
                    {
                        userProfile?.fieldOfWorking?.includes('OTHER') && (
                            <>
                                <TextField
                                    sx={{width: '100%', mt: 1, mb: -1}}
                                    variant="standard"
                                    placeholder="What's your preferred type of work?"
                                    value={userProfile?.fieldOfWorkingOther}
                                    onChange={(e) => setFieldOfWorkOther(e.target.value)}
                                />
                            </>
                        )
                    }
                </Paper>

                <Paper elevation={0} sx={formStyles('languages')}>
                    <Typography typography="body1">Language</Typography>
                    <Typography typography="body2" sx={{mt: 1}}>I am able to work in the following language
                        environment</Typography>
                    <DomainValueSelect
                        domainCode={DomainCode.LANGUAGE}
                        selectedValues={userProfile?.languages ? userProfile?.languages : []}
                        onSelect={setLanguages}
                    />
                </Paper>

                <Paper elevation={0} sx={formStyles('universities')}>
                    <HigherEducation userProfile={userProfile} setUserProfile={setUserProfile}/>
                </Paper>

                <Paper elevation={0} sx={formStyles('description')}>
                    <AdditionalInformation userProfile={userProfile} setUserProfile={setUserProfile}/>
                </Paper>


                <Box sx={{paddingBottom: '5rem'}}/>

                <StudentFooterBar save={onSave} submit={() => onSubmit()}/>
            </Stack>
        </>
    );
}

export default StudentDashboardMiddle;
