import useApi, {BASE_URL} from './useApi';
import {IOrderRequest} from "../models/IOrderRequest";
import {toast} from "react-toastify";

const ORDERS_URL = `${BASE_URL}orders`;

const useOrdersApi = () => {
    const {doFetch} = useApi();

    const getOrdersOverview = () => {
        const url = `${ORDERS_URL}/overview`;
        return doFetch(url);
    };

    const getPaymentMethods = () => {
        const url = `${ORDERS_URL}/payment-methods`;
        return doFetch(url);
    };

    const paymentCallback = (token: string) => {
        const url = `${ORDERS_URL}/montonio/callback`;
        return doFetch(url, 'POST', {orderToken: token});
    };

    const createOrder = async (orderRequest: IOrderRequest) => {
        const url = `${ORDERS_URL}`;
        try {
            let response = await doFetch(url, 'POST', orderRequest);
            window.location.href = response.paymentUrl;

        } catch (e) {
            console.log(e);
            toast.warn('Failed to create order');
            throw e;
        }
    };

    return {
        getOrdersOverview,
        createOrder,
        getPaymentMethods,
        paymentCallback,
    };
};

export default useOrdersApi;
