import React from 'react';
import useApi, {BASE_URL} from './useApi';
import {IAnswer} from "../models/IAnswer";

const TEST_URL = `${BASE_URL}test`;

const useTestApi = () => {
    const {doFetch} = useApi();

    const getTestQuestions = () => {
        const url = `${TEST_URL}/questions`;
        return doFetch(url);
    };

    const getTestAnswers = () => {
        const url = `${TEST_URL}/answers`;
        return doFetch(url);
    };

    const upsertAnswer = (answer: IAnswer) => {
        let url = TEST_URL + "/answer";
        return doFetch(url, 'POST', {...answer}, true);
    };

    const upsertAnswers = (answers: IAnswer[]) => {
        let url = TEST_URL + "/answers";
        return doFetch(url, 'POST', {answers: answers}, true);
    };

    return {
        getTestQuestions,
        getTestAnswers,
        upsertAnswer,
        upsertAnswers
    };
};

export default useTestApi;
