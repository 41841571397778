import React from 'react';

function JoblingLogo({width = 112}: { width?: number }) {
    const height = width / 3.5
    return (
        <svg width={width} height={height} viewBox="0 0 112 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M14.8121 3.2264V16.9239C14.8121 19.2508 14.1766 21.0302 12.9056 22.2621C11.6541 23.494 9.92362 24.11 7.71402 24.11C5.36755 24.11 3.49038 23.4549 2.08249 22.1448C0.694164 20.8347 0 18.9478 0 16.484H5.69019C5.70975 18.1069 6.30614 18.9184 7.47938 18.9184C8.53529 18.9184 9.06324 18.2536 9.06324 16.9239V3.2264H14.8121Z"
                fill="#231F20"/>
            <path
                d="M25.8987 24.11C24.2562 24.11 22.7799 23.7678 21.4698 23.0834C20.1792 22.399 19.1624 21.4213 18.4193 20.1503C17.6763 18.8793 17.3048 17.3834 17.3048 15.6627C17.3048 13.9615 17.6763 12.4754 18.4193 11.2044C19.1819 9.9334 20.2085 8.9557 21.4991 8.27131C22.8092 7.58692 24.2855 7.24473 25.928 7.24473C27.5706 7.24473 29.0371 7.58692 30.3277 8.27131C31.6378 8.9557 32.6644 9.9334 33.4074 11.2044C34.17 12.4754 34.5513 13.9615 34.5513 15.6627C34.5513 17.3639 34.17 18.8598 33.4074 20.1503C32.6644 21.4213 31.6378 22.399 30.3277 23.0834C29.0176 23.7678 27.5412 24.11 25.8987 24.11ZM25.8987 19.1237C26.7004 19.1237 27.3653 18.8304 27.8932 18.2438C28.4407 17.6376 28.7145 16.7773 28.7145 15.6627C28.7145 14.5481 28.4407 13.6975 27.8932 13.1109C27.3653 12.5243 26.7102 12.231 25.928 12.231C25.1459 12.231 24.4908 12.5243 23.9629 13.1109C23.4349 13.6975 23.1709 14.5481 23.1709 15.6627C23.1709 16.7968 23.4251 17.6572 23.9335 18.2438C24.4419 18.8304 25.097 19.1237 25.8987 19.1237Z"
                fill="#231F20"/>
            <path
                d="M42.6391 9.73786C43.0888 8.9557 43.7243 8.34953 44.5456 7.91934C45.3669 7.4696 46.3348 7.24473 47.4494 7.24473C48.7595 7.24473 49.9425 7.58692 50.9984 8.27131C52.0739 8.9557 52.9147 9.9334 53.5208 11.2044C54.1466 12.4754 54.4594 13.9615 54.4594 15.6627C54.4594 17.3639 54.1466 18.8598 53.5208 20.1503C52.9147 21.4213 52.0739 22.399 50.9984 23.0834C49.9425 23.7678 48.7595 24.11 47.4494 24.11C46.3348 24.11 45.3669 23.8949 44.5456 23.4647C43.7243 23.015 43.0888 22.399 42.6391 21.6169V23.9047H36.8902V2.19982H42.6391V9.73786ZM48.6226 15.6627C48.6226 14.5872 48.3293 13.7562 47.7427 13.1696C47.1756 12.5634 46.4717 12.2603 45.6308 12.2603C44.79 12.2603 44.0763 12.5634 43.4897 13.1696C42.9226 13.7757 42.6391 14.6068 42.6391 15.6627C42.6391 16.7382 42.9226 17.579 43.4897 18.1852C44.0763 18.7913 44.79 19.0944 45.6308 19.0944C46.4717 19.0944 47.1756 18.7913 47.7427 18.1852C48.3293 17.5594 48.6226 16.7186 48.6226 15.6627Z"
                fill="#231F20"/>
            <path d="M62.5749 2.19982V23.9047H56.8261V2.19982H62.5749Z" fill="#231F20"/>
            <path
                d="M68.7525 5.92484C67.7357 5.92484 66.9144 5.65108 66.2887 5.10358C65.6825 4.53651 65.3795 3.83257 65.3795 2.99175C65.3795 2.13138 65.6825 1.41766 66.2887 0.850597C66.9144 0.283532 67.7357 0 68.7525 0C69.7498 0 70.5515 0.283532 71.1576 0.850597C71.7834 1.41766 72.0962 2.13138 72.0962 2.99175C72.0962 3.83257 71.7834 4.53651 71.1576 5.10358C70.5515 5.65108 69.7498 5.92484 68.7525 5.92484ZM71.5976 7.45005V23.9047H65.8488V7.45005H71.5976Z"
                fill="#231F20"/>
            <path
                d="M85.6359 7.30339C87.5326 7.30339 89.0383 7.93889 90.1528 9.2099C91.2674 10.4614 91.8247 12.1625 91.8247 14.3135V23.9047H86.0758V15.0761C86.0758 14.1375 85.8314 13.4042 85.3426 12.8763C84.8537 12.3288 84.1987 12.055 83.3774 12.055C82.517 12.055 81.8424 12.3288 81.3536 12.8763C80.8647 13.4042 80.6203 14.1375 80.6203 15.0761V23.9047H74.8714V7.45005H80.6203V9.79652C81.1287 9.05347 81.8131 8.45707 82.6735 8.00733C83.5338 7.53804 84.5213 7.30339 85.6359 7.30339Z"
                fill="#231F20"/>
            <path
                d="M101.051 7.24473C102.165 7.24473 103.133 7.4696 103.954 7.91934C104.795 8.36908 105.44 8.97525 105.89 9.73786V7.45005H111.639V23.7287C111.639 25.2735 111.346 26.6618 110.759 27.8937C110.173 29.1451 109.254 30.1424 108.002 30.8854C106.77 31.6285 105.206 32 103.309 32C100.767 32 98.7335 31.4036 97.2083 30.2108C95.6831 29.018 94.8129 27.3951 94.5978 25.3419H100.259C100.376 25.8698 100.669 26.2805 101.139 26.5738C101.608 26.8671 102.214 27.0138 102.957 27.0138C104.913 27.0138 105.89 25.9187 105.89 23.7287V21.6169C105.44 22.3795 104.795 22.9856 103.954 23.4354C103.133 23.8851 102.165 24.11 101.051 24.11C99.7405 24.11 98.5477 23.7678 97.4723 23.0834C96.4164 22.399 95.5755 21.4213 94.9498 20.1503C94.3436 18.8598 94.0406 17.3639 94.0406 15.6627C94.0406 13.9615 94.3436 12.4754 94.9498 11.2044C95.5755 9.9334 96.4164 8.9557 97.4723 8.27131C98.5477 7.58692 99.7405 7.24473 101.051 7.24473ZM105.89 15.6627C105.89 14.6068 105.597 13.7757 105.01 13.1696C104.443 12.5634 103.739 12.2603 102.898 12.2603C102.038 12.2603 101.324 12.5634 100.757 13.1696C100.19 13.7562 99.9067 14.5872 99.9067 15.6627C99.9067 16.7186 100.19 17.5594 100.757 18.1852C101.324 18.7913 102.038 19.0944 102.898 19.0944C103.739 19.0944 104.443 18.7913 105.01 18.1852C105.597 17.579 105.89 16.7382 105.89 15.6627Z"
                fill="#231F20"/>
        </svg>
    );
}

export default JoblingLogo;
