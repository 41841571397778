import React, {useEffect, useState} from 'react';
import {Box, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../components/theme';
import AcceptedIndicator from '../../../assets/AcceptedIndicator';
import RejectedIndicator from '../../../assets/RejectedIndicator';
import {OfferStatus} from '../../../models/IUserProfile';
import {IStudentJobPosition} from '../../../models/IStudentJobPosition';
import DeleteIcon from '../../../assets/DeleteIcon';

function OffersLeft({
                        offers,
                        activeOffer,
                        setActiveOffer,
                    }: {
    offers: IStudentJobPosition[],
    activeOffer: IStudentJobPosition | null,
    setActiveOffer: any
}) {
    const [pendingOffers, setPendingOffers] = useState<IStudentJobPosition[]>([]);
    const [acceptedOffers, setAcceptedOffers] = useState<IStudentJobPosition[]>([]);
    const [rejectedOffers, setRejectedOffers] = useState<IStudentJobPosition[]>([]);

    useEffect(() => {
        setPendingOffers(offers.filter((offer) => offer.offerStatus === OfferStatus.PENDING));
        setAcceptedOffers(offers.filter((offer) => offer.offerStatus === OfferStatus.ACCEPTED));
        setRejectedOffers(offers.filter((offer) => offer.offerStatus === OfferStatus.REJECTED));
    }, [offers]);

    const displayDeletedOffer = (offer: IStudentJobPosition) => (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{cursor: 'pointer', mr: 2}} onClick={() => console.log('remove offer: ', offer)}>
                <DeleteIcon/>
            </Box>

            <RejectedIndicator/>
        </Box>
    );

    function OffersGroup({offers, status}: { offers: IStudentJobPosition[], status: OfferStatus }) {
        if (offers.length === 0) {
            return <></>;
        }

        let label = "Pending offers"
        if(OfferStatus.ACCEPTED === status) {
            label = "You have accepted"
        }
        if(OfferStatus.REJECTED === status) {
            label = "You have rejected"
        }

        return (
            <Paper elevation={0} sx={{...styles.paperCenter, p: {xs: 2, sm: 3}}}>
                <Typography typography="body1" sx={{mb: 3, fontWeight: 700}}>{label}</Typography>
                {offers.map((offer, index) => {
                    const color = offer.id === activeOffer?.id ? '#809252' : '#000000';

                    return (
                        <Box sx={{display: 'flex', alignItems: 'center', mb: index < offers.length - 1 ? 2 : 0}} key={index}>
                            <Typography typography="body2" sx={{color, cursor: 'pointer'}}
                                        onClick={() => setActiveOffer(offer)}>{offer.companyName || 'Unnamed Company'}</Typography>
                            <Box sx={{flexGrow: 1}}/>
                            {
                                offer.offerStatus === OfferStatus.ACCEPTED && (
                                    <AcceptedIndicator/>
                                )
                            }
                            {
                                offer.offerStatus === OfferStatus.REJECTED && (
                                    displayDeletedOffer(offer)
                                )
                            }
                        </Box>
                    );
                })}
            </Paper>
        );
    }

    return (
        <Stack sx={{}} spacing={1}>
            <OffersGroup offers={pendingOffers} status={OfferStatus.PENDING}/>
            <OffersGroup offers={acceptedOffers} status={OfferStatus.ACCEPTED}/>
            <OffersGroup offers={rejectedOffers} status={OfferStatus.REJECTED}/>
        </Stack>
    );
}

export default OffersLeft;
