import React from 'react';
import {useAtom} from 'jotai';
import {domainValueAtom, universityCountAtom} from '../store/domainValueStore';
import {DomainCode, IDomainValue} from '../models/IDomainValue';
import useDomainApi from '../api/useDomainApi';
import {useUpdateAtom} from "jotai/utils";

const useDomainValues = () => {
    const [dvMap, setDvMap] = useAtom(domainValueAtom);
    const setUniversityCount = useUpdateAtom(universityCountAtom);
    const {getDomainValues, getUniversityCounts} = useDomainApi();

    const findDomainValuesByCode = (domainCode: DomainCode): IDomainValue[] => {
        const response = dvMap.get(domainCode) as IDomainValue[];
        return response || [];
    };

    const findDomainValueByCode = (domainCode: DomainCode, code: string): IDomainValue | null => {
        const domains = dvMap.get(domainCode);
        const dv = domains?.find((dv) => dv.code === code);
        return dv || null;
    };

    const findDomainValueStringByCode = (domainCode: DomainCode, code: string): String | null => {
        const dv = findDomainValueByCode(domainCode, code);
        return dv ? dv.value : null;
    };

    const loadDomainValues = async () => {
        const domainValues = await getDomainValues() as IDomainValue[];
        const universityCounts = await getUniversityCounts();
        const countMap = new Map<String, number>();
        universityCounts.forEach((item: any) => {
            countMap.set(item.universityCode + ":" + item.majorCode, item.count);
            if(countMap.get(item.universityCode) == null) {
                countMap.set(item.universityCode, 0)
            }
            countMap.set(item.universityCode, countMap.get(item.universityCode) + item.count)
        })

        setUniversityCount(countMap)
        const map = new Map<String, IDomainValue[]>();
        if (domainValues) {
            domainValues.forEach((dv) => {
                if (!map.has(dv.domainCode)) {
                    map.set(dv.domainCode, []);
                }
                let domainValue = dv;
                if(dv.domainCode === DomainCode.UNIVERSITY) {
                    domainValue.count = countMap.get(dv.code) || 0;
                }
                map.get(dv.domainCode)?.push(domainValue);
            });
        }
        setDvMap(map);
    };

    return {
        findDomainValuesByCode, findDomainValueByCode, findDomainValueStringByCode, loadDomainValues,
    };
};

export default useDomainValues;
