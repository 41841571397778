import * as React from 'react';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';

const accountTypes = ['STUDENT', 'COMPANY'];

export interface AccountTypeDialogProps {
    open: boolean;
    onClose: () => void;
}

function AccountTypeDialog(props: AccountTypeDialogProps) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    const handleListItemClick = (value: string) => {
        if(value === 'STUDENT') {
            window.location.href = '/create-account';
        } else if(value === 'COMPANY') {
            window.location.href = '/create-account-company';
        }
    };

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Choose account type to create</DialogTitle>
            <List sx={{ pt: 0 }}>
                {accountTypes.map((accountType) => (
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => handleListItemClick(accountType)} key={accountType}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: '#80925220', color: '#809252' }}>
                                    {accountType === 'STUDENT' ? <PersonIcon /> : <BusinessIcon />}
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={accountType} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Dialog>
    );
}

export default AccountTypeDialog;
