import React from 'react';
import {toast} from 'react-toastify';
import {ISearchFilter} from '../models/ISearchFilter';
import useApi, {BASE_URL} from './useApi';

const FILTER_URL = `${BASE_URL}filter`;

const COUNT_URL = `${FILTER_URL}/count`;
const SEARCH_URL = `${FILTER_URL}/search`;
const MISMATCH_URL = `${FILTER_URL}/mismatch`;

const useFilterApi = () => {
    const {doFetch} = useApi();

    const searchCount = (jobPositionId: number, filter: ISearchFilter, allowedMismatches: number) => {
        const url = new URL(COUNT_URL);
        url.search = new URLSearchParams({
            jobPositionId: jobPositionId.toString(),
            allowedMismatches: allowedMismatches.toString(),
        }).toString();

        return doFetch(url.toString(), 'POST', {...filter})
            .catch((e) => {
                toast.error('Failed to filter profiles');
            });
    };

    const searchResults = (jobPositionId: number) => {
        const url = new URL(SEARCH_URL);
        url.search = new URLSearchParams({jobPositionId: jobPositionId.toString()}).toString();

        return doFetch(url.toString())
            .catch((e) => {
                toast.error('Failed to load profiles');
            });
    };

    const getMismatchMetrixes = (jobPositionId: number, userId: number) => {
        const url = new URL(MISMATCH_URL);
        url.search = new URLSearchParams({
            jobPositionId: jobPositionId.toString(),
            userId: userId.toString(),
        }).toString();

        return doFetch(url.toString())
            .catch((e) => {
                toast.error('Failed to load mismatched charts');
            });
    };

    return {searchCount, searchResults, getMismatchMetrixes};
};

export default useFilterApi;
