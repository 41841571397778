import React from 'react';
import {AppBar, Box, Button, Container, IconButton, Menu, MenuItem, Toolbar, Typography} from '@mui/material';
import {getAuth} from 'firebase/auth';
import {useNavigate} from 'react-router';
import StudentHeaderLinks from './student/StudentHeaderLinks';
import MenuIcon from '@mui/icons-material/Menu';


const styles = {
    appbar: {
        backgroundColor: 'background.paper',
        height: '2.625rem',
        justifyContent: 'center',

    },
    logoText: {
        color: '#809252',
        fontSize: '0.75rem',
        // lineHeight: "1.05em",
        fontWeight: '900',
        fontFamily: 'TT Hazelnuts',
        marginRight: '1.75rem',
    },
    logoutText: {
        fontSize: '0.75rem',
        fontWeight: '500',
    },
};

const STUDENT_LINKS = [
    {label: 'My personality', path: '/test-result'},
    {label: 'My preferences', path: '/preferences'},
    {label: 'My offers', path: '/offers'},
    {label: 'My profile', path: '/profile'},
];

function NewHeaderBar() {
    const navigate = useNavigate();

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const logout = () => {
        getAuth().signOut().then(() => {
            navigate('/login');
        });
    };

    return (
        <AppBar elevation={0} position="sticky" sx={styles.appbar}>
            <Container sx={{pl: 4, pr: 4}}>
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        sx={{
                            color: '#009ae5',
                            fontSize: '1.3rem',
                            fontWeight: '900',
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                        }}
                    >
                        JOBLING //
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {STUDENT_LINKS.map((page) => (
                                <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center"
                                                onClick={() => navigate(page.path)}>{page.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        sx={{
                            color: '#809252',
                            fontSize: '0.75rem',
                            fontWeight: '900',
                            fontFamily: 'TT Hazelnuts',
                            mr: 2,
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                        }}
                    >
                        JOBLING
                    </Typography>

                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        <StudentHeaderLinks/>
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <Button sx={{cursor: 'pointer', color: '#000000',}} onClick={() => logout()}>Log out</Button>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default NewHeaderBar;
