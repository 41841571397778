import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Typography} from '@mui/material';
import {IMetrixOverview} from "../../../models/IMetrixOverviews";
import {useTranslation} from "react-i18next";

interface INormalizedValues {
    type: string,
    value: number,
    label: string | undefined
}

function UserTestMain({metrixOverview}: { metrixOverview: IMetrixOverview | undefined }) {

    const {t} = useTranslation();

    let allStrongestValues = metrixOverview?.highestValues.concat(metrixOverview?.lowestValues);
    let normalizedValues: INormalizedValues[] | undefined = allStrongestValues?.map(e => (
        {
            type: e.type,
            value: e.value < 50 ? 100 - e.value : e.value,
            label: e.value < 50 ? t(`company.filter.metrix.${e.type}.leftLabel`).toLowerCase() :
                t(`company.filter.metrix.${e.type}.rightLabel`).toLowerCase()
        }
    ));
    normalizedValues?.sort((a, b) => {
        return b.value - a.value
    })
    let highestValuePersonalityTraits = normalizedValues?.slice(0, 2);

    return (
        <Box sx={{textAlign: 'left'}}>
            <Typography typography="body2">You are best described
                as {highestValuePersonalityTraits ? highestValuePersonalityTraits[0]?.label : ""} and {highestValuePersonalityTraits ? highestValuePersonalityTraits[1]?.label : ""}.</Typography>

        </Box>
    );
}

export default UserTestMain;
