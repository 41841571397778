import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fontsource/poppins/100.css'
import '@fontsource/poppins/200.css'
import '@fontsource/poppins/300.css'
import '@fontsource/poppins/400.css'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'

import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from 'jotai';
import {BrowserRouter} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {QueryClient, QueryClientProvider} from "react-query";
import ScrollToTop from "./components/ScrollToTop";
import {BrowserTracing} from "@sentry/tracing";
import ReactPixel from 'react-facebook-pixel';

const queryClient = new QueryClient()

if (process.env.REACT_APP_ENV === 'prod') {
    Sentry.init({
        dsn: "https://f25b3ac95493461ba0943b33a8519dde@o4504440978931712.ingest.sentry.io/4504440984240128",
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });
    ReactPixel.init('417557290525762', undefined, {
        autoConfig: true,
        debug: false
    });
    ReactPixel.pageView(); // For tracking page view
}

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider>
                <QueryClientProvider client={queryClient}>
                    <ScrollToTop/>
                    <App/>
                </QueryClientProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
