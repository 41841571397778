import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, LinearProgress, Stack, Typography} from "@mui/material";

interface Props {
    activeStep: number;
    type: string;
}

function TestProgressBar({activeStep, type}: Props) {

    const currentQuestionIndex = () => {
        if (type === 'COMPETENCE') {
            return activeStep + 1 + 44 + 20
        }
        return activeStep + 1
    }

    const sliderValue = () => {
        let singleQuestionProgress = 100 / 66

        if (type === 'COMPETENCE') {
            return singleQuestionProgress * (activeStep + 1 + 44 + 20)
        }
        return singleQuestionProgress * (activeStep + 1)
    }

    return (
        <Stack sx={{
            alignItems: 'center',
            mb: {xs: '2rem', md: '6.25rem'},
            height: ['PERSONALITY', 'VALUE'].includes(type) ? '8rem' : '0rem'
        }}>
            <Box sx={{mt: {xs: 0, md: 2}}}>
                <Typography typography="body1">Question {currentQuestionIndex()} out of 66</Typography>
                <LinearProgress variant="determinate" value={sliderValue()} sx={{
                    height: '2px',
                    backgroundColor: '#e4e6e8', '& .MuiLinearProgress-bar': {
                        backgroundColor: '#FFA149'
                    },
                }}/>
            </Box>

            {activeStep === 0 && type === 'PERSONALITY' && (
                <Box sx={{mt: {xs: 3, md: 4}, textAlign: 'center'}}>
                    <Typography typography="body1" sx={{color: '#91939a'}}>Tap on the circle to select your
                        answer</Typography>
                    <Typography typography="body1" sx={{color: '#91939a'}}>Don´t worry, you can go back to the previous
                        question if needed.</Typography>
                </Box>
            )}
        </Stack>
    );
}

export default TestProgressBar;
