import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import {styles} from '../../../components/theme';
import {IUserItem} from "../../../models/IUserItem";
import DeleteIcon from "../../../assets/DeleteIcon";
import {useAtomValue} from "jotai";
import {userAtom} from "../../../store/userStore";
import AddNewUserDialog from "./AddNewUserDialog";
import useCompaniesApi from "../../../api/useCompaniesApi";

const COLUMNS = [
    {name: 'Name', width: '8rem'},
    {name: 'Email', width: '11rem'},
    {name: '', width: '2rem'},
];

function CompanyUsersMiddle({
                                activePage,
                            }: {
    activePage: any,
}) {
    const user = useAtomValue(userAtom);

    const [users, setUsers] = useState<IUserItem[]>([]);
    const {getUsersByCompanyId} = useCompaniesApi();
    const [open, setOpen] = useState(false);

    const loadData = async () => {
        if(user) {
            const fetchedUsers = await getUsersByCompanyId(user?.companyId!!);
            setUsers(fetchedUsers);
        }
    };

    useEffect(() => {
        loadData();
    }, [activePage]);

    const closeDialog= (reload: boolean) => {
        setOpen(false)
        if(reload) {
            loadData()
        }
    }

    return (
        <Stack sx={styles.middleStack} spacing={1}>
            <Paper elevation={0} sx={styles.paper}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {COLUMNS.map((column, index) => (
                                    <TableCell key={index}>{column.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>{item.fullName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>
                                        {item.id == user?.id ? null : (
                                            <DeleteIcon />
                                        )}

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{display: 'flex', justifyContent: 'center', mt: 6}}>
                    <Button variant="contained" onClick={() => setOpen(true)}>Add new user</Button>
                </Box>

                <AddNewUserDialog
                    open={open}
                    onClose={(reload: boolean = false) => closeDialog(reload)}
                    companyId={user?.companyId!!}
                />

            </Paper>
        </Stack>
    );
}

export default CompanyUsersMiddle;
