import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Button, CircularProgress, Grid, Stack, Tab, Tabs, ThemeProvider, Typography} from '@mui/material';
import OffersLeft from './OffersLeft';
import OffersMiddle from './OffersMiddle';
import {OfferStatus} from '../../../models/IUserProfile';
import {IStudentJobPosition} from '../../../models/IStudentJobPosition';
import useStudentPositionApi from '../../../api/useStudentPositionApi';
import {styles, tomatoTheme} from "../../../components/theme";
import {useAtomValue} from "jotai";
import {useNavigate} from "react-router";
import {profileVisibleAtom} from "../../../store/userStore";
import PreferencesImage from "../../../assets/PreferencesImage";
import JoblingLayout from "../../JoblingLayout";
import {toast} from "react-toastify";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function Offers() {
    const profileVisible = useAtomValue(profileVisibleAtom);
    const {getJobPositionsForStudent, updateOfferStatus} = useStudentPositionApi();
    const [offers, setOffers] = useState<IStudentJobPosition[]>([]);
    const [activeOffer, setActiveOffer] = useState<IStudentJobPosition | null>(null);

    const [value, setValue] = React.useState(0);
    const [loaded, setLoaded] = useState(false)

    const navigate = useNavigate()

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: {xs: 1, m: 3}}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const loadOffers = async () => {
        const offersForMe = await getJobPositionsForStudent();
        setOffers(offersForMe);

        if (offersForMe.length > 0) {
            await activateOffer(offersForMe[0]);
        }
        setLoaded(true)
    };

    useEffect(() => {
        loadOffers();
    }, []);

    const activateOffer = async (offer: IStudentJobPosition) => {
        setActiveOffer((prev) => offer);
        setValue(1)
    };

    const changeStatus = async (offer: IStudentJobPosition, status: OfferStatus) => {
        try {
            await updateOfferStatus(offer.id, status);
        } catch (e) {
            toast.error('Error while updating offer status')
            return
        }

        if (status === OfferStatus.ACCEPTED) {
            toast.success("Offer accepted! The company should contact you soon.")
        } else if (status === OfferStatus.REJECTED) {
            toast.success("Offer rejected!")
        }
        loadOffers();

    };

    if (!loaded) {
        return (
            <CircularProgress/>
        )
    }

    const getBoxStyle = () => {
        if (profileVisible) {
            return {}
        }
        return {
            border: '1px solid #FBAF00',
            borderRadius: '0.5rem',
            p: 3,
        }
    }

    if (offers.length === 0) {
        return (
            <JoblingLayout backgroundColor="white">

                <Box sx={{display: 'flex', alignItems: 'center', height: {md: '100vh'}, backgroundColor: 'white'}}>
                    <ThemeProvider theme={tomatoTheme}>

                        <Grid container spacing={6} justifyContent="center" alignItems="center">
                            <Grid item xs={10} sm={8} lg={4}>
                                <Stack sx={{alignItems: 'center'}}>
                                    <Typography typography="h1" sx={{mb: 4, fontSize: {xs: '1.5rem', sm: '2.5rem'}}}>You
                                        do not have any invitation to a job interview yet.</Typography>
                                    <Typography typography="body1">But no worries, we will inform you as soon as the
                                        invitation arrives.</Typography>
                                </Stack>
                            </Grid>


                            <Grid item xs={10} md={6} lg={4}>
                                <Stack sx={{alignItems: 'center'}}>
                                    <Box sx={getBoxStyle()} textAlign="center">

                                        <Box sx={{display: profileVisible ? 'none' : 'block'}}>
                                            <Typography typography="h2" sx={{color: '#FBAF00', mb: 3}}>Your profile is
                                                not visible</Typography>
                                            <Typography sx={{mb: 2}}>Visit My Preferences to review your profile and
                                                make it visible.</Typography>
                                        </Box>


                                        <PreferencesImage/>
                                        <Typography typography="h2" sx={{mb: 1, mt: 3}}>My preferences</Typography>
                                        <Typography sx={{...styles.body1, mb: 3}}>
                                            Meanwhile, you can continue updating your preferences. Make sure you have
                                            entered everything as precisely as possible.</Typography>
                                        <Button variant="contained"
                                                sx={{padding: '0.6rem', width: '11rem', height: '2.5rem'}}
                                                onClick={() => navigate('/preferences')}>View</Button>
                                    </Box>
                                </Stack>
                            </Grid>


                        </Grid>
                    </ThemeProvider>
                </Box>

            </JoblingLayout>
        );
    }

    return (


        <JoblingLayout>
            <ThemeProvider theme={tomatoTheme}>

                <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                    <Grid container spacing={1} justifyContent="center">

                        <Grid item xs={10}>
                            <Typography typography="h2" sx={{mt: 3, mb: 1}}>Offers</Typography>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <OffersLeft offers={offers} activeOffer={activeOffer} setActiveOffer={activateOffer}/>

                        </Grid>
                        <Grid item xs={12} md={7}>
                            <OffersMiddle activeOffer={activeOffer} changeStatus={changeStatus}/>

                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example"
                                      variant="fullWidth">
                                    <Tab label="Overview" {...a11yProps(0)} />
                                    <Tab label="Description" {...a11yProps(1)} />
                                </Tabs>
                            </Box>

                            <TabPanel value={value} index={0}>
                                <OffersLeft offers={offers} activeOffer={activeOffer} setActiveOffer={activateOffer}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <OffersMiddle activeOffer={activeOffer} changeStatus={changeStatus}/>
                            </TabPanel>

                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
            {

                /*
                  <AdminLayout>
                    <Stack spacing={1}>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                            <Typography typography="h3">Offers</Typography>
                        </Box>

                        <Box sx={{display:  {xs: 'none', sm: 'block'}}}>
                            <Grid container spacing={1} justifyContent="center" >
                                <Grid item xs={12} sm={4} md={3} lg={2}>
                                    <OffersLeft offers={offers} activeOffer={activeOffer} setActiveOffer={activateOffer}/>
                                </Grid>
                                <Grid item xs={12} sm={8} md={5}>
                                    <OffersMiddle activeOffer={activeOffer} changeStatus={changeStatus}/>
                                </Grid>
                            </Grid>
                        </Box>

                        <Box sx={{display:  {xs: 'block', sm: 'none'}}}>
                            <Grid container spacing={1} justifyContent="center" >
                                <Grid item xs={12}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" variant="fullWidth">
                                            <Tab label="Overview" {...a11yProps(0)} />
                                            <Tab label="Information" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>

                                    <TabPanel value={value} index={0}>
                                        <OffersLeft offers={offers} activeOffer={activeOffer} setActiveOffer={activateOffer}/>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <OffersMiddle activeOffer={activeOffer} changeStatus={changeStatus}/>
                                    </TabPanel>

                                </Grid>
                            </Grid>
                        </Box>
                    </Stack>
                </AdminLayout>
                 */
            }
        </JoblingLayout>
    );
}

export default Offers;
