import React from 'react';

export enum UserType {
    STUDENT = 'STUDENT', COMPANY = 'COMPANY'
}

export interface IUser {
    email: string;
    uid: string;
    phone: string;
    id: number;
    accountNumber: number;
    profileViews: number;
    profileVisible: boolean;
    profileSubmitted: boolean;
    isAdmin: boolean;
    modifiedDate: string | null;
    fullName: string;
    citizenship: string;
    companyName: string;
    loginType: string;
    accountType: UserType | null;
    isCompanyAdmin: boolean;
    companyId: number | null;
}
