import React, {useEffect, useState} from 'react';
import {Box, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../components/theme';
import useAuditApi from "../../../api/useAuditApi";
import {IFullStats, IStats} from "../../Admin/Dashboard/AdminDashboardMiddle";

function AccountLeft() {
    const [stats, setStats] = useState<IFullStats | null>(null);
    const {getUserStats} = useAuditApi();


    const loadUserStats = async () => {
        const data = await getUserStats();
        setStats(data);
    }

    useEffect(() => {
        loadUserStats();
    }, []);

    const count = (items: IStats[] | undefined) => (items ? items.map((item) => item.count).reduce((a, b) => a + b, 0) : 0);


    return (
        <Stack sx={styles.leftStack} spacing={1}>

            <Paper elevation={0} sx={styles.paper}>
                <Box textAlign="center">
                    <Typography typography="h6">{count(stats?.jobPositionsCreated)}</Typography>
                    <Typography typography="body1">positions added</Typography>
                </Box>
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Box textAlign="center">
                    <Typography typography="h6">{count(stats?.jobOffersSent)}</Typography>
                    <Typography typography="body1">offers sent</Typography>
                </Box>
            </Paper>

        </Stack>
    );
}

export default AccountLeft;
