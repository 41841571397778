import React, {useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {DialogContent, Grid, Stack, TextField} from '@mui/material';
import {useAtomValue} from "jotai";
import {productPackagesAtom} from "../../../store/ordersStore";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {IProductPackage} from "../../../models/IProductPackage";
import {useForm} from "react-hook-form";
import CircularProgress from "@mui/material/CircularProgress";
import {IOrderRequest} from "../../../models/IOrderRequest";
import useUserProfileApi from "../../../api/useUserProfileApi";
import useOrdersApi from "../../../api/useOrderApi";
import {IOrderResponse} from "../../../models/IOrderResponse";
import PaymentMethodSelection from "./PaymentMethodSelection";
import {IOrderRequestPaymentMethod} from "../../../models/IOrderRequestPaymentMethod";

interface FormInputs {
    comment: string;
}


function ProductPackageQualifiedConfirmation({productPackage, onCancel, onClose, jobPositionId}: {
    productPackage: IProductPackage,
    onCancel: () => void;
    onClose: () => void;
    jobPositionId: number;
}) {

    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>();
    const {createOrder} = useOrdersApi();
    const [isPaymentMethodSelectionScreen, setIsPaymentMethodSelectionScreen] = useState(false);
    const formRef = useRef(null);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (data: FormInputs, paymentMethod: IOrderRequestPaymentMethod) => {
        setIsSubmitting(true);
        let returnUrl = window.location.href;
        const indexOfQuery = returnUrl.indexOf('?');
        if (indexOfQuery !== -1) {
            returnUrl = returnUrl.substring(0, indexOfQuery);
        }
        const order: IOrderRequest = {
            productPackageId: productPackage.id,
            returnUrl: returnUrl,
            paymentMethod: paymentMethod,
            jobPositionId: jobPositionId,
            comment: data.comment
        }
        try {
            await createOrder(order);
        } catch (e) {
        } finally {
            setIsSubmitting(false);
        }
    };
    const selectPaymentMethod = async () => {
        setIsPaymentMethodSelectionScreen(true);
    };

    function configurePaymentMethod(paymentMethod: IOrderRequestPaymentMethod) {
        handleSubmit((formInputs: FormInputs) => onSubmit(formInputs, paymentMethod))()
    }

    function paymentMethodCancel() {
        onClose();
        setIsPaymentMethodSelectionScreen(false);
    }


    return (
        <DialogContent>
            {!isPaymentMethodSelectionScreen ? <form>
                    <Grid container spacing={6} px={2}>
                        <Grid item sx={{textAlign: 'center'}} xs={12}>
                            <Typography variant='h1' sx={{my: 2.5, whiteSpace: 'nowrap', color: 'primary.main',}}>
                                {productPackage?.name}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} textAlign="center">
                            <Typography variant='body1' mb={6}>
                                We're happy to assist you in finding top-tier candidates for your open position. To
                                kickstart the process, please provide us with more detailed information that might be
                                relavant for the position (e.g, a detailed description of the open position).
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={8}
                                label="Detailed description of the open position"
                                sx={{height: "fit-content !important", borderRadius: "4px",
                                    '& fieldset': {
                                        border: '1px solid #d1d1d1 !important',
                                        borderRadius: "6px"
                                    }}}
                                {...register("comment", {required: true})}
                                error={Boolean(errors.comment)}
                                helperText={errors.comment && "This field is required"}
                            />
                            <Typography variant='body1' mt={2}>
                                Once we have this information, we'll begin our search for the perfect candidates.<br/>
                                <br/>
                                Thank you for choosing Jobling!<br/>
                                <br/>
                                Best regards,<br/>
                                The Jobling Team
                            </Typography>
                        </Grid>
                        <Grid item xs={12} textAlign={"right"}>
                            <Button
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                variant='contained'
                                type="submit"
                                disabled={isSubmitting}
                                onClick={selectPaymentMethod}
                                sx={{ml: 2}}
                            >
                                {isSubmitting && <CircularProgress disableShrink size={16} sx={{mr: 1}}/>}
                                Accept and Proceed
                            </Button>
                        </Grid>
                    </Grid>
                </form> :
                <PaymentMethodSelection setPaymentMethod={configurePaymentMethod} onCancel={paymentMethodCancel}
                                        isSubmitting={isSubmitting}/>}
        </DialogContent>
    );
}

export default ProductPackageQualifiedConfirmation;
