import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    Link,
    MenuItem,
    Select,
    TextField,
    ThemeProvider,
    Typography
} from '@mui/material';
import LoginImage from "../../assets/LoginImage";
import JoblingLogo from "../../assets/JoblingLogo";
import {
    ApplicationVerifier,
    beforeAuthStateChanged,
    ConfirmationResult,
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
    UserInfo
} from "firebase/auth";
import {useAtom} from "jotai";
import {loginStepAtom} from "../../store/loginStore";
import useUserApi from "../../api/useUserApi";
import VerificationCodeForm from "./VerificationCodeForm";
import {useSearchParams} from "react-router-dom";
import {tomatoTheme} from "../../components/theme";
import {toast} from "react-toastify";


function PhoneLogin() {
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult>();
    const [verifier, setVerifier] = useState<RecaptchaVerifier>();

    const [loginStep, setLoginStep] = useAtom(loginStepAtom);
    const [phone, setPhone] = useState('');

    const [callingCode, setCallingCode] = useState('+372');

    const {getUserByPhone} = useUserApi()

    const [searchParams] = useSearchParams();


    useEffect(() => {
        const unsubscribe = beforeAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
                return;
            }

            const providerInfo = user.providerData[0] as UserInfo

            if ('phone' === providerInfo.providerId) {
                try {
                    await getUserByPhone(user.phoneNumber!)
                } catch (e: any) {
                    console.log(e)
                }
            }
        });

        return unsubscribe;
    }, []);

    const verifyCaptcha = async () => {
        const verifier = new RecaptchaVerifier('login-button', {
            size: 'invisible',
        }, getAuth());
        setVerifier(verifier);
    }

    useEffect(() => {
        verifyCaptcha();
    }, []);

    useEffect(() => {
        if (verifier && searchParams && searchParams.get('phoneNumber')) {
            signInUsingPhoneNumber(searchParams.get('phoneNumber')!!);
        }
    }, [searchParams, verifier]);

    const signInUsingPhoneNumber = (phoneNumber: string) => {
        signInWithPhoneNumber(getAuth(), phoneNumber, verifier as ApplicationVerifier)
            .then((confirmationResult) => {
                setConfirmationResult(confirmationResult);
                setLoginStep(1);
            }).catch((error) => {
            alert(`Error code: ${error.code}`);
        });
    }

    const onPhoneSubmit = async () => {

        let phoneNumber = callingCode + phone;
        phoneNumber = phoneNumber.trim();
        phoneNumber = phoneNumber.replace(/\s/g, '');

        getUserByPhone(phoneNumber).then((user) => {
            signInUsingPhoneNumber(phoneNumber);
        }).catch((e: any) => {
            console.log("error", e)
            if (e.message === 'OBJECT_NOT_FOUND') {
                toast.error("No user found with this phone number! Please use e-mail login.")
            } else {
                toast.error("Login failed!")
            }
        })
    };


    const handleKeyDown = async (key: string) => {
        if (key === 'Enter') {
            await onPhoneSubmit();
        }
    }

    const renderPrivacyPolicyLink = () => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                <Typography sx={{mr: 0.5}}>
                    By continuing you agree to our <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}}
                    href="/terms-and-conditions" target="_blank" rel="noopener">Terms and conditions</Link> and <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}} href="/privacy-policy"
                    target="_blank" rel="noopener">Privacy policy</Link>
                </Typography>
            </Box>
        )
    }
    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <JoblingLogo/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    {loginStep === 0 ? (

                        <ThemeProvider theme={tomatoTheme}>

                            <Box sx={{maxWidth: '25rem', pl: 1, pr: 1, width: '100%'}}>

                                <Box sx={{
                                    border: '1px solid #FFA149',
                                    borderRadius: '0.5rem',
                                    p: 2,
                                    backgroundColor: 'white'
                                }}>
                                    <Grid container sx={{mb: 2}} spacing={2}>
                                        <Grid item xs={4}>
                                            <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label" sx={{color: '#00000060'}}>Country
                                                    code</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={callingCode}
                                                    label="Country code"
                                                    onChange={(e) => setCallingCode(e.target.value)}
                                                    sx={{
                                                        borderRadius: '0.5rem',
                                                    }}
                                                >
                                                    <MenuItem value={"+372"}>+372</MenuItem>
                                                    <MenuItem value={"+358"}>+358</MenuItem>
                                                    <MenuItem value={"+371"}>+371</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={8}>
                                            <TextField
                                                variant="outlined"
                                                label={"Phone number"}
                                                value={phone}
                                                fullWidth
                                                onKeyDown={(e) => handleKeyDown(
                                                    e.key)}
                                                onChange={(e) => setPhone(e.target.value)}
                                                sx={{
                                                    borderRadius: '0.5rem',
                                                }}/>
                                        </Grid>
                                    </Grid>


                                    <Button
                                        id="login-button"
                                        sx={{
                                            border: '1px solid #FFA149',
                                            backgroundColor: 'white',
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '3rem',
                                            borderRadius: '0.5rem',
                                            mb: 2
                                        }}
                                        onClick={() => onPhoneSubmit()}
                                    >
                                        <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>
                                            Log in
                                        </Typography>
                                    </Button>
                                </Box>
                                <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                    <Divider></Divider>
                                </Box>
                                <Box sx={{
                                    border: '1px solid gray',
                                    borderRadius: '0.5rem',
                                    padding: "1rem",
                                    backgroundColor: 'white',
                                    marginBottom: "2rem",
                                }}>
                                    Login with e-mail? <Link href="/login">Log in</Link>
                                </Box>

                                {renderPrivacyPolicyLink()}
                            </Box>
                        </ThemeProvider>
                    ) : (
                        <VerificationCodeForm confirmationResult={confirmationResult} phone={phone}/>
                    )}
                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    );
}

export default PhoneLogin;
