import React, {useEffect, useState} from 'react';
import {Box, Slider, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import PersonIcon from '@mui/icons-material/Person';
import {IDomainValue} from '../models/IDomainValue';
import {IStudentMismatchData} from '../models/IStudentMismatchData';
import HatsBarChart from './HatsBarChart';
import MetrixTooltip from './MetrixTooltip';

function ChartDisplay({
                          chartData,
                          metrixDomain,
                      }: { chartData: IStudentMismatchData, metrixDomain: IDomainValue }) {
    const [smallCount, setSmallCount] = useState<number>(0);

    const {t} = useTranslation();

    useEffect(() => {
        if (chartData) {
            let sum = 0;
            for (let i = chartData.filterValue[0] + 5; i <= chartData.filterValue[1] - 5; i += 10) {
                const dp = chartData.chartData.data.find((dp) => dp.key === i);
                sum += dp ? dp.value : 0;
            }
            setSmallCount((prev) => sum);
        }
    }, [chartData]);

    return (
        <>
            <Box sx={{
                width: '100%', display: 'flex', alignItems: 'center', marginTop: '-0.5rem',
            }}
            >
                <Typography
                    style={{fontWeight: 'bold'}}>{t(`company.filter.metrix.${metrixDomain.code}.title`)}</Typography>
                {metrixDomain.code !== 'AVG_GRADE' && (
                    <MetrixTooltip metrixCode={metrixDomain.code}/>
                )}
                <Box sx={{flexGrow: 1}}/>
                <PersonIcon sx={{fontSize: '0.8rem'}}/>
                <Typography style={{fontSize: '0.7rem'}}>{smallCount}</Typography>
            </Box>
            <HatsBarChart data={chartData.chartData.data} color="#EF2D56" redBarValue={chartData.value}/>
            <Slider
                style={{padding: 0, color: '#DBDBDB'}}
                value={chartData.filterValue}
                disabled
            />
            <Box sx={{display: 'flex', marginLeft: '-0.5rem', marginRight: '-0.5rem', marginTop: '0.25rem'}}>
                <Typography
                    sx={{fontSize: '0.7rem'}}
                >
                    {t(`company.filter.metrix.${metrixDomain.code}.leftLabel`)}
                </Typography>
                <Box sx={{flexGrow: 1}}/>
                <Typography
                    sx={{fontSize: '0.7rem'}}
                >
                    {t(`company.filter.metrix.${metrixDomain.code}.rightLabel`)}
                </Typography>
            </Box>
        </>
    );
}

export default ChartDisplay;
