import React, {useEffect, useState} from 'react';
import {Stack} from '@mui/material';
import {toast} from 'react-toastify';
import {styles} from '../../../components/theme';
import {IJobPosition} from '../../../models/IJobPosition';
import {OfferStatus} from '../../../models/IUserProfile';
import useJobPositionApi from '../../../api/useJobPositionApi';
import {IOfferedStudent} from '../../../models/IOfferedStudent';
import CompanyOfferStatusTable from './CompanyOfferStatusTable';
import useStudentPositionApi from '../../../api/useStudentPositionApi';
import useOrders from "../../../hooks/useOrders";

function CompanyOfferStatusMiddle({
                                    activePosition,
                                }: {
    activePosition: IJobPosition | null,
}) {
    const {getPositionOffers} = useJobPositionApi();
    const {updateStudentContacted} = useStudentPositionApi();
    const [offers, setOffers] = useState<IOfferedStudent[]>([]);
    const {isJobPositionManagedByJobling} = useOrders();


    const acceptedOffers = offers.filter((o) => o.offerStatus === OfferStatus.ACCEPTED);
    const rejectedOffers = offers.filter((o) => o.offerStatus === OfferStatus.REJECTED);
    const pendingOffers = offers.filter((o) => o.offerStatus === OfferStatus.PENDING);

    const loadOffers = async () => {
        if (activePosition) {
            const positionOffers = await getPositionOffers(activePosition.id);
            setOffers(positionOffers);
        }
    };

    const toggleStudentContacted = (offer: IOfferedStudent) => {
        const newValue = !offer.studentContacted;
        updateStudentContacted(offer.studentPositionId, newValue).then(() => {
            toast.success(`Student marked as ${newValue ? 'contacted!' : 'not contacted!'}`);
            loadOffers();
        });
    };

    useEffect(() => {
        loadOffers();
    }, [activePosition]);

    if (offers.length === 0) {
        return (
            <Stack sx={styles.middleStack} spacing={1}>
            </Stack>
        );
    }

    return (
        <Stack sx={styles.middleStack} spacing={1}>
            <CompanyOfferStatusTable offers={acceptedOffers} toggleStudentContacted={toggleStudentContacted}
                                     status={OfferStatus.ACCEPTED}/>
            {!isJobPositionManagedByJobling(activePosition?.id!!) &&
                <>
                    <CompanyOfferStatusTable offers={pendingOffers} status={OfferStatus.PENDING}/>
                    <CompanyOfferStatusTable offers={rejectedOffers} status={OfferStatus.REJECTED}/>
                </>
            }

        </Stack>
    );
}

export default CompanyOfferStatusMiddle;
