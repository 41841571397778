import React from "react";
import {IMetrixOverviews} from "../models/IMetrixOverviews";
import InterestsIcon from '@mui/icons-material/Interests';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import HandshakeIcon from "../assets/metrix/HandshakeIcon";
import ThumbsUpIcon from "../assets/metrix/ThumbsUpIcon";
import ListCheckIcon from "../assets/metrix/ListCheckIcon";


export const VALUE = [
    /*
    'USER_VALUE_INDEPENDENCE_THOUGHT',
    'USER_VALUE_INDEPENDENCE_ACTION',
    'USER_VALUE_CHALLENGE_CHALLENGE',
    'USER_VALUE_HEDONISM_PLEASURE',
    'USER_VALUE_ACHIEVEMENT_ACHIEVEMENT',
    'USER_VALUE_POWER_DOMINANCE',
    'USER_VALUE_POWER_RESOURCES',
    'USER_VALUE_POWER_FACE',
    'USER_VALUE_SECURITY_PERSONAL',
    'USER_VALUE_SECURITY_SOCIETAL',
    'USER_VALUE_TRADITION_TRADITION',
    'USER_VALUE_OBEDIENCE_RULES',
    'USER_VALUE_OBEDIENCE_INTERPERSONAL',
    'USER_VALUE_OBEDIENCE_HUMILITY',
    'USER_VALUE_UNIVERSALISM_NATURE',
    'USER_VALUE_UNIVERSALISM_SOCIETY',
    'USER_VALUE_UNIVERSALISM_TOLERANCE',
    'USER_VALUE_KINDNESS_DEPENDABILITY',
    'USER_VALUE_KINDNESS_CARING',
     */

    'VALUE_CONFORMITY',
    'VALUE_TRADITION',
    'VALUE_BENEVOLENCE',
    'VALUE_UNIVERSALISM',
    'VALUE_SELF_DIRECTION',
    'VALUE_STIMULATION',
    'VALUE_HEDONISM',
    'VALUE_ACHIEVEMENT',
    'VALUE_POWER',
    'VALUE_SECURITY',
];

export const PERSONALITY = [
    /*
    'USER_BIG5_EXTRAVERSION',
    'USER_BIG5_AGREEABLENESS',
    'USER_BIG5_CONSCIENTIOUSNESS',
    'USER_BIG5_EMOTIONAL_RANGE',
    'USER_BIG5_OPENNESS',

     */

    'PERSONALITY_EXTRAVERSION',
    'PERSONALITY_AGREEABLENESS',
    'PERSONALITY_CONSCIENTIOUSNESS',
    'PERSONALITY_EMOTIONAL_RANGE',
    'PERSONALITY_OPENNESS',
];

export const COMPETENCE = [
    /*
    'USER_COMPE_ACHIEVEMENT_ORIENTATION',
    'USER_COMPE_INFORMATION_SEEKING',
    'USER_COMPE_ATTENTION_TO_DETAIL',
    'USER_COMPE_ADAPTING_TO_CHANGES',
    'USER_COMPE_RESILIENCE',
    'USER_COMPE_ANALYTICAL_THINKING',
    'USER_COMPE_CONCEPTUAL_THINKING',
    'USER_COMPE_TECHNOLOGICAL_MINDSET',
    'USER_COMPE_BUSINESS_ACUMEN',
    'USER_COMPE_STRATEGIC_THINKING',
    'USER_COMPE_INTERPERSONAL_UNDERSTANDING',
    'USER_COMPE_BUILDING_RELATIONSHIPS',
    'USER_COMPE_INFLUENCING_OTHERS',
    'USER_COMPE_TEAMWORK_AND_COLLABORATION',
    'USER_COMPE_CUSTOMER_FOCUS',
    'USER_COMPE_PLANNING_AND_ORGANIZING',
    'USER_COMPE_ORGANIZATIONAL_AWARENESS',
    'USER_COMPE_DEVELOPING_OTHERS',
    'USER_COMPE_LEADING_TEAMS',
    'USER_COMPE_ENSURING_ACCOUNTABILITY',

     */
    'COMPETENCE_TECHNOLOGICAL_MINDSET',
    'COMPETENCE_INFLUENCING_OTHERS',
    'COMPETENCE_PLANNING_AND_ORGANIZING',
    'COMPETENCE_INFORMATION_SEEKING',
    'COMPETENCE_RESILIENCE',
    'COMPETENCE_ATTENTION_TO_DETAIL',
    'COMPETENCE_ANALYTICAL_THINKING',
    'COMPETENCE_ACHIEVEMENT_ORIENTATION',
    'COMPETENCE_TEAMWORK_AND_COLLABORATION',
    'COMPETENCE_BUILDING_RELATIONSHIPS',
    'COMPETENCE_ADAPTING_TO_CHANGES',
    'COMPETENCE_CONCEPTUAL_THINKING',
];


export function findNotCompletedTests(metrixOverviews: IMetrixOverviews | null) {
    const hasNotCompletedValuesTest = metrixOverviews !== null && metrixOverviews.valuesOverview.values.length === 0;
    const hasNotCompletedPersonalityTest = metrixOverviews !== null && metrixOverviews.personalityOverview.values.length === 0;
    const hasNotCompletedCompetenciesTest = metrixOverviews !== null && metrixOverviews.competenciesOverview.values.length === 0;
    const notCompletedTests = []

    if (hasNotCompletedPersonalityTest) {
        notCompletedTests.push("PERSONALITY")
    }
    if (hasNotCompletedValuesTest) {
        notCompletedTests.push("VALUE")
    }
    if (hasNotCompletedCompetenciesTest) {
        notCompletedTests.push("COMPETENCE")
    }
    return notCompletedTests
}

export function getMetrixIcon(code: string, bigIcon = false) {
    if (code.startsWith("VALUE_") && !bigIcon) {
        return <HandshakeIcon/>
    } else if (code === "PERSONALITY_EXTRAVERSION") {
        return bigIcon ? <HandshakeIcon/> : <HandshakeIcon size={36}/>
    } else if (code === "PERSONALITY_AGREEABLENESS") {
        return bigIcon ? <ThumbsUpIcon/> : <ThumbsUpIcon size={36}/>
    } else if (code === "PERSONALITY_CONSCIENTIOUSNESS") {
        return bigIcon ? <ListCheckIcon/> : <ListCheckIcon size={36}/>
    } else if (code === "PERSONALITY_EMOTIONAL_RANGE") {
        return bigIcon ? <TheaterComedyIcon/> : <TheaterComedyIcon sx={{width: '2.25rem', height: '2.25rem'}}/>
    } else if (code === "PERSONALITY_OPENNESS") {
        return bigIcon ? <InterestsIcon/> : <InterestsIcon sx={{width: '2.25rem', height: '2.25rem'}}/>
    }
    return undefined
}

