import React from 'react';
import {Bar, BarChart, CartesianGrid, Cell, XAxis,} from 'recharts';
import {Box} from '@mui/material';
import {IDataPoint} from '../models/ChartData';

function HatsBarChart({
                          data,
                          color,
                          redBarValue,
                      }: { data: IDataPoint[], color: string, redBarValue?: number }) {
    const orderedData = data.sort((a, b) => a.key - b.key);

    return (
        <Box sx={{justifyContent: 'center', width: '100%', display: 'flex'}}>

            <BarChart width={276} height={100} data={data} margin={{bottom: -30}}>
                <XAxis
                    type="number"
                    dataKey="key"
                    domain={[0, 100]}
                    tick={false}
                    axisLine={false}
                    tickLine={false}
                />

                <CartesianGrid vertical={false} horizontal={false}/>
                <Bar dataKey="value" fill="#02B033" radius={[5, 5, 0, 0]}>
                    {
                        data.map((entry, index) => {
                            const barColor = redBarValue ? ((redBarValue > entry.key - 5 && redBarValue <= entry.key + 5) ? color : '#02B033') : color;
                            return <Cell key={`cell-${index}`} fill={barColor}/>;
                        })
                    }
                </Bar>
            </BarChart>
            {
                /*
                <AreaChart width={240} height={100} data={orderedData}>
                        <Area dataKey="value" fill={"#02B03340"} stroke={"#02B033"} type="monotone"/>

                    </AreaChart>
                 */
            }
        </Box>
    );
}

export default HatsBarChart;
