import React from 'react';

function CheckedIcon() {
    return (
        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M4 1H16C17.6569 1 19 2.34315 19 4V16C19 17.6569 17.6569 19 16 19H4C2.34315 19 1 17.6569 1 16V4C1 2.34315 2.34315 1 4 1ZM0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4ZM8.5863 11.5709C8.52437 11.6458 8.46928 11.726 8.42171 11.8106C8.37626 11.7676 8.3391 11.7336 8.30719 11.7044C8.25797 11.6593 8.22127 11.6257 8.18593 11.5883L6.20992 9.62946C6.14037 9.55632 6.06625 9.48762 5.988 9.42375C5.86963 9.33114 5.73009 9.26899 5.58174 9.2428C5.43338 9.21662 5.28082 9.22721 5.13758 9.27363C4.99433 9.32005 4.86484 9.40088 4.7606 9.50893C4.65635 9.61698 4.58057 9.74892 4.53998 9.89303C4.42624 10.2824 4.56402 10.591 4.84142 10.8665C5.75745 11.7757 6.67348 12.6843 7.58952 13.5922C8.13229 14.1341 8.61959 14.1359 9.16145 13.5977C11.1445 11.63 13.1261 9.66099 15.1061 7.69078C15.1839 7.6164 15.2559 7.53627 15.3216 7.45108C15.4087 7.33383 15.4661 7.19744 15.4889 7.05346C15.5117 6.90949 15.4992 6.76218 15.4525 6.62402C15.4058 6.48586 15.3262 6.36094 15.2205 6.25983C15.1148 6.15872 14.9861 6.08442 14.8454 6.04322C14.4357 5.91924 14.1158 6.07352 13.8282 6.35914C12.0781 8.09608 10.3308 9.83334 8.5863 11.5709Z" fill="#809252"/>
        </svg>
    );
}

export default CheckedIcon;
