import {atom} from 'jotai';
import {CompanyOrderOverview} from "../models/ICompanyOrderOverview";
import {IProductPackage} from "../models/IProductPackage";

const initialDefaultValue = {
    paidContactRequests: 0,
    availableContactRequests: 0,
    jobPositionsManagedByJoblings: []
};

export const orderOverviewAtom = atom<CompanyOrderOverview>(initialDefaultValue);
export const orderModalOpenAtom = atom<boolean>(false);
export const positionsWithAlreadyShownModalAtom = atom<string[]>([]);
export const orderModalShouldNotOpenAtom = atom<boolean>(false);
export const productPackagesAtom = atom<IProductPackage[] | null>(null);
export const orderWasSuccessfulAtom = atom<boolean | null>(null);

