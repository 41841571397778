import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, Divider, Grid, Link, TextField, ThemeProvider, Typography} from '@mui/material';
import LoginImage from "../../../assets/LoginImage";
import JoblingLogo from "../../../assets/JoblingLogo";
import {beforeAuthStateChanged, getAuth} from "firebase/auth";
import useUserApi from "../../../api/useUserApi";
import {IUser, UserType} from "../../../models/IUser";
import {tomatoTheme} from "../../../components/theme";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router';
import {isValidEmail} from "../../../util/formatUtil";

let company = "";
let fullName = "";

function CreateAccountCompany() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const {getUserByEmail, createUser} = useUserApi()

    const updateCompanyName = (value: string) => {
        company = value;
        setCompanyName(value);
    }

    const updateName = (value: string) => {
        fullName = value;
        setName(value);
    }

    useEffect(() => {
        const unsubscribe = beforeAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
                return;
            }

            try {
                await getUserByEmail(user.email!)
            } catch (e: any) {
                if (e.message === 'OBJECT_NOT_FOUND') {
                    const newUser = {
                        fullName: fullName,
                        companyName: company,
                        email: user.email,
                        citizenship: 'ESTONIAN',
                        accountType: UserType.COMPANY,
                        loginType: 'GOOGLE'
                    };
                    console.log("4", newUser)
                    await createUser(newUser as IUser);
                }
            }
        });

        return unsubscribe;
    }, []);


    const onEmailSubmit = async () => {
        if (isFormValid()) {
            const newUser = {
                fullName: name,
                companyName: companyName,
                email: email,
                citizenship: 'ESTONIAN',
                accountType: UserType.COMPANY,
                loginType: 'EMAIL'
            };
            const createdUser = await createUser(newUser as IUser)
            if (createdUser) {
                toast.info("Please check your e-mail " + email + " for further instructions!")
                navigate('/login/confirm/' + email);

            }
        }
    };

    const isFormValid = () => {
        const errors = [];
        if (name === undefined || name?.trim().length === 0) {
            errors.push('name');
        }
        if (companyName === undefined || companyName?.trim().length === 0) {
            errors.push('companyName');
        }
        if (!acceptTerms) {
            errors.push('acceptTerms');
            toast.info("Please accept the terms and conditions!")
        }
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        setErrorFields(errors);
        return errors.length <= 0;
    }

    const termsStyle = {
        color: '#809252',
    }

    const renderPrivacyPolicyLink = () => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Checkbox checked={acceptTerms} onChange={(e, checked) => setAcceptTerms(checked)} style={termsStyle} />
                <Typography sx={{mr: 0.5}}>
                    I agree with <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}}
                    href="/terms-and-conditions" target="_blank" rel="noopener">Terms and conditions</Link> and <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}} href="/privacy-policy"
                    target="_blank" rel="noopener">Privacy policy</Link>
                </Typography>
            </Box>
        )
    }

    function handleKeyDown(key: string) {
        if (key === 'Enter') {
            onEmailSubmit();
        }
    }

    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <JoblingLogo/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ThemeProvider theme={tomatoTheme}>

                        <Typography typography="h2" sx={{mb: 8}}>For company</Typography>


                        <Box sx={{maxWidth: '25rem', pl: 1, pr: 1, width: '100%'}}>

                            <Box sx={{
                                border: '1px solid #FFA149',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                <TextField
                                    error={errorFields.includes("name")}
                                    helperText={!errorFields.includes("name") ? "" : "Missing or invalid full name!"}
                                    variant="outlined"
                                    label={"Full name"}
                                    value={name}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => updateName(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>

                                <TextField
                                    error={errorFields.includes("companyName")}
                                    helperText={!errorFields.includes("companyName") ? "" : "Missing or invalid company name!"}
                                    variant="outlined"
                                    label={"Company name"}
                                    value={companyName}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => updateCompanyName(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>

                                <TextField
                                    error={errorFields.includes("email")}
                                    helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                                    variant="outlined"
                                    label={"E-mail"}
                                    value={email}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>

                                {renderPrivacyPolicyLink()}

                                <Button
                                    id="login-button"
                                    sx={{
                                        border: '1px solid #FFA149',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '3rem',
                                        borderRadius: '0.5rem',
                                        mb: 2
                                    }}
                                    onClick={() => onEmailSubmit()}
                                >
                                    <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Create
                                        account</Typography>
                                </Button>
                            </Box>

                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider></Divider>
                            </Box>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                Already have an account? <Link href="/login">Log in</Link>
                            </Box>

                        </Box>
                    </ThemeProvider>

                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    );
}

export default CreateAccountCompany;
