import {IUniversity} from '../models/IUniversity';
import React from "react";
import {IUserProfile} from "../models/IUserProfile";

export function formatAvgGrade(university: IUniversity): string {
    let scale: number = 100;
    if (university.gradeScale === 'FIVE_GRADE') {
        scale = 5;
    }
    return `${university.avgGrade}/${scale}`;
}

export function formatAvgGradeForStudent(university: IUniversity): string {
    let scale: number = 5;
    if (university.avgGrade) {
        const formattedNumber = (university.avgGrade / 20).toFixed(2)
        return `${formattedNumber}/${scale}`
    }
    return `0/${scale}`;
}

export function formatProfileLabel(accountNumber: number): string {
    return "PROFILE " + accountNumber

}

export const isValidEmail = (email: string) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    );

export const isValidPhone = (phone: string) =>
    // eslint-disable-next-line no-useless-escape
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
        phone
    );


export const hasValidUniversities = (userProfile: IUserProfile) => {
    let valid = true;
    userProfile?.universities.forEach((u) => {
        if (u.university === undefined) {
            valid = false;
        }
        if (u.major === '') {
            // valid = false;
        }
        if (u.year === undefined || u.year === null || u.year < 2000) {
            valid = false;
        }
        if (u.avgGrade === undefined || u.avgGrade === null || u.avgGrade < 0 || u.avgGrade > 100) {
            valid = false;
        }
        if (u.gradeScale === '') {
            // valid = false;
        }
    });

    return valid;
};

export const formatDate = (date: Date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}.${month}.${year}`;
};

