import React, {useEffect, useState} from 'react';
import {Box, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../components/theme';
import useAuditApi from '../../../api/useAuditApi';

export interface IFullStats {
    accountsCreated: IStats[],
    jobOffersSent: IStats[],
    jobPositionsCreated: IStats[],
    profilesSubmitted: IStats[],
    profilesUpdated: IStats[],
}

export interface IStats {
    date: string;
    count: number;
}

/*
ACCOUNT_CREATED,
    PROFILE_SUBMIT,
    PROFILE_UPDATE,
    PROFILE_VISIBLE,
    JOB_POSITION_CREATE,
    JOB_POSITION_DELETE,
    JOB_OFFER_SEND,
    JOB_OFFER_ACCEPT,
    JOB_OFFER_REJECT
 */

function AdminDashboardMiddle({
                                  activePage,
                              }: {
    activePage: any,
}) {
    const [stats, setStats] = useState<IFullStats | null>(null);
    const {getAllStats} = useAuditApi();

    const loadData = async () => {
        const fetchedData = await getAllStats();
        setStats(fetchedData);
    };

    useEffect(() => {
        loadData();
    }, [activePage]);

    const count = (items: IStats[] | undefined) => (items ? items.map((item) => item.count).reduce((a, b) => a + b, 0) : 0);

    return (
        <Stack spacing={1}>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Accounts</Typography>
                <Typography typography="subtitle">
                    Total created:
                    {count(stats?.accountsCreated)}
                </Typography>

                <Box sx={{marginBottom: '2rem'}}/>

                <Typography typography="h4">Profiles</Typography>
                <Typography typography="subtitle">
                    Updated:
                    {count(stats?.profilesUpdated)}
                </Typography>
                <Typography typography="subtitle">
                    Submitted:
                    {count(stats?.profilesSubmitted)}
                </Typography>

                <Box sx={{marginBottom: '2rem'}}/>

                <Typography typography="h4">Job positions</Typography>
                <Typography typography="subtitle">
                    Created:
                    {count(stats?.jobPositionsCreated)}
                </Typography>

                <Box sx={{marginBottom: '2rem'}}/>

                <Typography typography="h4">Job offers</Typography>
                <Typography typography="subtitle">
                    Total sent:
                    {count(stats?.jobOffersSent)}
                </Typography>
            </Paper>
            {
                /*
                    <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Accounts</Typography>
                <Typography typography="subtitle">Total</Typography>

                <BarChart width={400} height={100} data={accounts}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Profiles</Typography>

                <Typography typography="subtitle">Submits</Typography>
                <BarChart width={400} height={100} data={profileSubmits}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>

                <Typography typography="subtitle">Updates</Typography>
                <BarChart width={400} height={100} data={profileUpdates}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Job positions</Typography>

                <BarChart width={400} height={100} data={jobPositions}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>
            </Paper>

            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Job offers</Typography>

                <Typography typography="subtitle">Offers sent</Typography>
                <BarChart width={400} height={100} data={offersSent}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>

                <Typography typography="subtitle">Offers accepted</Typography>
                <BarChart width={400} height={100} data={offersAccepted}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>

                <Typography typography="subtitle">Offers rejected</Typography>
                <BarChart width={400} height={100} data={offersRejected}>
                    <XAxis dataKey="date" domain={['auto', 'auto']}/>
                    <Tooltip />
                    <Bar dataKey="count" fill="#8884d8" maxBarSize={10}/>
                </BarChart>

            </Paper>
                 */
            }

        </Stack>
    );
}

export default AdminDashboardMiddle;
