import React from 'react';
import {Box, Typography,} from '@mui/material';
import {IMetrixOverview} from "../../../models/IMetrixOverviews";
import MetrixRadar from "./MetrixRadar";
import {styles} from "../../../components/theme";


function UserValuesMap({overviewData}: {overviewData: IMetrixOverview | undefined}) {

    return (
        <Box>
            <Typography sx={styles.h2}>Values map</Typography>
            <Typography sx={{...styles.body4, mt: 0.5}}>Your personal motivators. How you interact with other people and the surrounding environment.</Typography>
            <MetrixRadar data={overviewData?.values}/>
        </Box>
    );
}

export default UserValuesMap;
