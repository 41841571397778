import React from 'react';
import {AppBar, Box, Typography} from '@mui/material';
import {getAuth} from 'firebase/auth';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router';
import StudentHeaderLinks from './student/StudentHeaderLinks';
import CompanyHeaderLinks from './company/CompanyHeaderLinks';
import {userAtom} from '../store/userStore';
import {UserType} from '../models/IUser';
import JoblingLogo from "../assets/JoblingLogo";

const styles = {
    appbar: {
        backgroundColor: 'background.paper',
        height: '6rem',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottom: '2px solid #F7F7F7',
    },

};

function HeaderBar() {
    const user = useAtomValue(userAtom);
    const navigate = useNavigate();
    const isCompany = user?.accountType === UserType.COMPANY;

    const logout = () => {
        getAuth().signOut().then(() => {
            navigate('/login');
        });
    };

    return (
        <AppBar elevation={0} position="sticky" sx={styles.appbar}>
            <Box sx={{display: 'flex', maxWidth: '120rem', minWidth: '80rem', alignItems: 'center'}}>
                <Typography
                    variant="h6"
                    noWrap
                    component="a"
                    sx={{
                        color: '#000000',
                        cursor: 'pointer',
                        fontSize: '1.3rem',
                        fontWeight: '900',
                        mr: 2,
                        display: {xs: 'none', md: 'flex'},
                    }}
                    onClick={() => navigate('/')}
                >
                    <JoblingLogo width={111}/>
                </Typography>

                {!isCompany ? (
                    <StudentHeaderLinks/>
                ) : (
                    <CompanyHeaderLinks/>
                )}
                <Typography typography="body1" sx={{
                    cursor: 'pointer', fontWeight: 600,
                    fontSize: "1rem",
                    color: '#FFA149'
                }} onClick={() => logout()}>Log out</Typography>
                <Box sx={{width: '1.5rem'}}/>
            </Box>
        </AppBar>
    );
}

export default HeaderBar;
