import React from 'react';
import {Container} from '@mui/material';

const html = '\n' +
    '<!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01 Transitional//EN"><html><head><META http-equiv="Content-Type" content="text/html; charset=utf-8"><style>p.m_MsoNormal,li.m_MsoNormal,div.m_MsoNormal{margin:0cm;line-height:115%;font-size:11.0pt;font-family:"Arial",sans-serif}h1{margin-top:20.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:115%;page-break-after:avoid;font-size:20.0pt;font-family:"Arial",sans-serif;font-weight:normal}span.m_MsoHyperlink{color:blue;text-decoration:underline}p.m_MsoListParagraph,li.m_MsoListParagraph,div.m_MsoListParagraph{margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:115%;font-size:11.0pt;font-family:"Arial",sans-serif}p.m_MsoListParagraphCxSpFirst,li.m_MsoListParagraphCxSpFirst,div.m_MsoListParagraphCxSpFirst{margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:115%;font-size:11.0pt;font-family:"Arial",sans-serif}p.m_MsoListParagraphCxSpMiddle,li.m_MsoListParagraphCxSpMiddle,div.m_MsoListParagraphCxSpMiddle{margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:115%;font-size:11.0pt;font-family:"Arial",sans-serif}p.m_MsoListParagraphCxSpLast,li.m_MsoListParagraphCxSpLast,div.m_MsoListParagraphCxSpLast{margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:115%;font-size:11.0pt;font-family:"Arial",sans-serif}.m_MsoChpDefault{font-family:"Arial",sans-serif}.m_MsoPapDefault{line-height:115%}@page WordSection1{margin:72.0pt 72.0pt 72.0pt 72.0pt}div.m_WordSection1{}ol{margin-bottom:0cm}ul{margin-bottom:0cm}</style></head><body>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '<div lang="ET" link="blue" vlink="purple" style="word-wrap:break-word">\n' +
    '\n' +
    '<div class="m_WordSection1">\n' +
    '\n' +
    '<h1 align="center" style="text-align:center"><b><span lang="EN-GB" style="font-size:12.0pt;line-height:115%;font-family:&quot;Calibri Light&quot;,sans-serif">General\n' +
    'Terms and Conditions of Use of the Platform</span></b></h1>\n' +
    '\n' +
    '<p class="MsoNormal" align="right" style="margin-bottom:6.0pt;text-align:right;line-height:normal"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">Last\n' +
    'update: 01.12.2022</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">1.<span style="font:7.0pt &quot;Times New Roman&quot;">    \n' +
    '</span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">GENERAL PROVISIONS</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">1.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'present terms and conditions of use (hereinafter - <b>“the terms and conditions\n' +
    'of use”</b>) regulate services provided on the website </span><span lang="et"><a href="https://www.google.com/url?q=http://www.jobling.ee&amp;source=gmail-html&amp;ust=1670063534166000&amp;usg=AOvVaw3LXA4goBM1dn0Bz9pvda05" target="_blank" rel="noreferrer"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">www.jobling.ee</span></a></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black"> by\n' +
    'Jobling OÜ, registry code 16605946, address Lauteri Street 3, 10114, Tallinn,\n' +
    'Estonia (hereinafter - <b>“the Service Provider”</b>).</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">1.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The given\n' +
    'services and website are hereinafter referred to as the platform. Please\n' +
    'familiarise yourself with the terms and conditions of use before starting to\n' +
    'use the platform. By agreeing with the terms and conditions of use of the\n' +
    'platform you confirm that you are at least an 18-years-old person and that you\n' +
    'are studying in or have graduated from a university. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">1.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">By\n' +
    'accepting the terms and conditions of use </span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif"><span style="color:black">the\n' +
    'user confirms that they have familiarised themselves with the terms and\n' +
    'conditions of use and agree with them. If you do not agree with the terms and\n' +
    'conditions of use of the platform, you do not have the right to use the\n' +
    'platform. </span></span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><a name="m___Hlk120274620" rel="noreferrer"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">1.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider has the right to restrict access of the User to the\n' +
    'Application, if the Service Provider suspects that the User performs or can\n' +
    'perform prohibited actions in the Application, including actions that can pose\n' +
    'danger to security or operation of the Application. The Service Provider\n' +
    'informs the User of such restriction of access.</span></a></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">2.<span style="font:7.0pt &quot;Times New Roman&quot;">     </span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">FUNCTIONS\n' +
    'OF THE PLATFORM</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">2.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">The purpose of the\n' +
    'platform is to bring together providers of work requiring higher education,\n' +
    'including recruitment agencies (hereinafter - “the Job Providers”) and people\n' +
    'who are acquiring or have acquired higher education and who want to find a job\n' +
    'that requires higher education (hereinafter - “the Jobseekers”; jointly also\n' +
    'referred to as “the Users”). </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">2.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">The Jobseekers who\n' +
    'have joined the platform answer a questionnaire, on the basis of which the\n' +
    'platform creates a profile of the Jobseeker and gives it a non-personalised\n' +
    'identifier. The Jobseeker decides whether their non-personalised profile is\n' +
    'visible to the Job Providers on the platform or not. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">2.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">The Employers that\n' +
    'have joined the platform can search among active non-personalised profiles for\n' +
    'those profiles that match the needs of their organisation&#39;s culture and the\n' +
    'vacant position in the best way, and send them invitations for a job interview\n' +
    'through the platform.  The platform forwards invitations for a job interview to\n' +
    'the Jobseeker, and the Jobseeker can either accept (“Accept”) or reject\n' +
    '(“Reject”) them. If the Job Seeker rejects the invitation, the platform\n' +
    'forwards to the Job Provider only the information that their invitation for a\n' +
    'job interview has been rejected. If the Jobseeker accepts the invitation, they\n' +
    'give the platform the right to forward their name, mobile phone number and\n' +
    'e-mail address to the Job Provider that made the accepted offer, following\n' +
    'which the platform does so.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">2.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">The platform does not\n' +
    'guarantee a job interview to the Jobseeker who has accepted an invitation for a\n' +
    'job interview, and it is not responsible for what the Job Provider does with\n' +
    'the information provided to them in accordance with the terms and conditions of\n' +
    'use of the platform.  </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.<span style="font:7.0pt &quot;Times New Roman&quot;">    \n' +
    '</span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">CREATING A USER ACCOUNT</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">In order\n' +
    'to use the platform the Jobseeker must create a personal account. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">In order\n' +
    'to create an account the Jobseeker must enter the following details regarding\n' +
    'themselves:</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.2.1.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">Name and\n' +
    'surname;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.2.2.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">E-mail\n' +
    'address;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.2.3.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">Mobile\n' +
    'phone number.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">3.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">When the\n' +
    'Jobseeker logs into the platform with a user account that they created, they\n' +
    'must authenticate themselves. </span></p>\n' +
    '\n' +
    '<p class="m_MsoListParagraphCxSpFirst" style="margin-left:39.6pt"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">3.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">An account is created\n' +
    'for the Job Provider by the Service Provider in accordance with provisions of\n' +
    'the special terms and conditions agreed between the Service Provider and the\n' +
    'Job Provider. </span></p>\n' +
    '\n' +
    '<p class="m_MsoListParagraphCxSpLast" style="margin-left:39.6pt"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">3.5.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">When the Job Provider\n' +
    'enters the platform, the Job Provider must authenticate itself. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.<span style="font:7.0pt &quot;Times New Roman&quot;">    \n' +
    '</span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">USE OF THE PLATFORM</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">Use of\n' +
    'the platform by the Jobseeker is free of charge.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">After\n' +
    'creating an account the Jobseeker can create a personal profile on the\n' +
    'platform.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">To create\n' +
    'a profile, the Jobseeker can publish the following information about\n' +
    'themselves:</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.1.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">questions\n' +
    'asked within the framework of the personality test;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.2.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">information\n' +
    'as to which type of work the Jobseeker is looking for (internship, full-time or\n' +
    'part-time work, project-based work, etc.);</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.3.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">which\n' +
    'areas the Jobseeker is interested in to find a job;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.4.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">language\n' +
    'skills;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.5.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">establishment\n' +
    'where the Jobseeker is presently studying;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.6.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">average\n' +
    'mark;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.7.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">information\n' +
    'regarding previous work experience;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.8.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">information\n' +
    'regarding skills;</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:61.2pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.3.9.<span style="font:7.0pt &quot;Times New Roman&quot;"> </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">other\n' +
    'information related to the job the Jobseeker is looking for.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Jobseeker can change the data entered on the platform, except a personality\n' +
    'test which can be retaken no more than once a year.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.5.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The Job\n' +
    'Provider can describe on the platform the position for which it needs an\n' +
    'employee and set filters for personal characteristics, competencies, and values\n' +
    '​​that the Job Provider believes are important based on the culture\n' +
    'of their organisation, the vacant position and the needs of the team. Filters\n' +
    'allow to create a sample of suitable size from profiles of the Jobseekers, and\n' +
    'to send to selected Jobseekers invitations for an interview with the necessary\n' +
    'information, which helps the Jobseeker to decide whether to accept or reject\n' +
    'the received invitation. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.6.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">If the\n' +
    'Jobseeker accepts the invitation for a job interview, the Jobseeker&#39;s first and\n' +
    'last name, e-mail address and, in some cases, mobile phone number provided to\n' +
    'the platform by the Jobseeker become visible to the Job Provider who sent the\n' +
    'invitation. The Job Provider can use contact details of the Jobseeker to\n' +
    'arrange a time for a job interview, as well as other details. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.7.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The Job\n' +
    'Provider and the Jobseeker agree on the time, place and other details of the\n' +
    'job interview outside the platform. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.8.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'maximum number of invitations for a job interview per calendar month and the\n' +
    'fee payable by the Job Provider for using the platform are agreed upon in the\n' +
    'special terms and conditions of use of the platform. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">4.9.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The User\n' +
    'undertakes to use the platform in good faith and for its intended purpose. If\n' +
    'the User encounters inappropriate behaviour of other Users on the platform\n' +
    '(e.g. incitement of hate; job offers not suitable for the platform or provision\n' +
    'of false information), we ask the User to inform the Service Provider thereof\n' +
    'at e-mail address <a href="mailto:info@jobling.ee" target="_blank" rel="noreferrer">info@jobling.ee</a>.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;page-break-after:avoid;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.<span style="font:7.0pt &quot;Times New Roman&quot;">     </span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">LIABILITY</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">Smooth\n' +
    'and trouble-free operation of the platform depends, among other things, on\n' +
    'operation of mobile data communication and specifications of the User&#39;s smart\n' +
    'device (including age of the smart device, software used, digital and physical\n' +
    'security, etc.).</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">Use of\n' +
    'the platform is personal. The User is obliged to take measures to prevent third\n' +
    'parties from accessing the platform. The User of a smart device is responsible\n' +
    'for actions performed by third parties on the platform using this device. If\n' +
    'the User suspects that a third party has used the platform through their\n' +
    'account, the User must immediately notify the Service Provider thereof at\n' +
    'e-mail address <a href="mailto:info@jobling.ee" target="_blank" rel="noreferrer">info@jobling.ee</a>.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">In order\n' +
    'to operate successfully, the platform from time to time requires software\n' +
    'updates to improve or supplement the platform&#39;s functions and to ensure the\n' +
    'platform&#39;s security.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The User\n' +
    'understands that actions performed through the platform have legally binding\n' +
    'consequences for both the User and the owner of the platform. The User is\n' +
    'responsible for lawfulness of actions of the User and for their consequences.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.5.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The User\n' +
    'understands that the Service Provider is solely responsible for operation of\n' +
    'the platform in accordance with the terms and conditions.  </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.6.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The Job\n' +
    'Provider understands that responsibility of the Service Provider as the\n' +
    'platform operator is limited to offering a platform for invitations for job\n' +
    'interviews. After the Job Provider has invited the Jobseeker for a job\n' +
    'interview and the Jobseeker has accepted the invitation, the Service Provider\n' +
    'no longer mediates communication between the Jobseeker and the Job Provider.\n' +
    'The Service Provider is not responsible for conducting job interviews. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.7.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider is not responsible if the Jobseeker has provided incorrect\n' +
    'information about themselves on the platform.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.8.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider is not responsible towards the Job Provider for quality of the\n' +
    'work performed by the student.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.9.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider does not guarantee round-the-clock and error-free operation of\n' +
    'the platform. The Service Provider is not responsible for any direct or\n' +
    'indirect damage that may occur due to the platform not operating or the User\n' +
    'not being able to use it as desired.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:42.55pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.10.<span style="font:7.0pt &quot;Times New Roman&quot;">  </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">In case\n' +
    'of questions or notifications we ask the User to contact us at <a href="mailto:info@jobling.ee" target="_blank" rel="noreferrer">info@jobling.ee</a>.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:42.55pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">5.11.<span style="font:7.0pt &quot;Times New Roman&quot;">  </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'parties will try to resolve all disagreements quickly, by way of negotiations\n' +
    'between the parties. If the Parties fail to resolve the dispute by way of\n' +
    'negotiations, the dispute is resolved in the Harju County Court.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">6.<span style="font:7.0pt &quot;Times New Roman&quot;">    \n' +
    '</span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">TERMINATION OF THE USER ACCOUNT</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">6.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The User\n' +
    'has the right to stop using the platform at any time by sending an e-mail with\n' +
    'a request to delete their account to the Service Provider.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">6.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">To delete\n' +
    'the user account the User must contact the Service Provider at e-mail address\n' +
    '<a href="mailto:info@jobling.ee" target="_blank" rel="noreferrer">info@jobling.ee</a>.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">6.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">If the\n' +
    'User wants to start using the platform again, they have to create an account\n' +
    'again. </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">6.4.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider has the right to terminate the user account if the User\n' +
    'breaches the rules of the platform, including if the User behaves\n' +
    'inappropriately (see Section 4.9).</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:12.0pt;margin-right:0cm;margin-bottom:12.0pt;margin-left:17.85pt;text-align:justify;line-height:normal;border:none"><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">7.<span style="font:7.0pt &quot;Times New Roman&quot;">    \n' +
    '</span></span></b><b><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">CONCLUDING PROVISIONS</span></b></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">7.1.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The terms\n' +
    'and conditions of use can be amended at any time. If the Service Provider\n' +
    'amends the terms and conditions of use, Users will be notified thereof.\n' +
    'Amendments enter into effect 14 days after submission of a notice of amendment\n' +
    'of the terms and conditions. If the User disagrees with amendments, the User\n' +
    'must notify the Service Provider thereof at e-mail address </span><span lang="et"><a href="mailto:info@jobling.ee" target="_blank" rel="noreferrer"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif">info@jobling.ee</span></a></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">. </span></p>\n' +
    '\n' +
    '<p class="m_MsoListParagraphCxSpFirst" style="margin-left:39.6pt;line-height:normal"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">7.2.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The\n' +
    'Service Provider has the right to restrict access of the User to the\n' +
    'Application, if the Service Provider suspects that the User performs or can\n' +
    'perform prohibited actions in the Application, including actions that can pose\n' +
    'danger to security or operation of the Application. The Service Provider informs\n' +
    'the User of such restriction of access.</span></p>\n' +
    '\n' +
    '<p class="m_MsoListParagraphCxSpLast" style="margin-left:39.6pt;line-height:normal"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black"> </span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-top:0cm;margin-right:0cm;margin-bottom:6.0pt;margin-left:39.6pt;text-align:justify;line-height:normal;border:none"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">7.3.<span style="font:7.0pt &quot;Times New Roman&quot;">    </span></span><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif;color:black">The terms\n' +
    'and conditions of use are regulated by the legislation of the Republic of\n' +
    'Estonia. With regard to questions not regulated by the terms and conditions,\n' +
    'the platform operator and the User are guided by the legislation of the\n' +
    'Republic of Estonia presently in force and by principles of good faith and good\n' +
    'reason.</span></p>\n' +
    '\n' +
    '<p class="MsoNormal" style="margin-bottom:6.0pt;line-height:normal"><span lang="EN-GB" style="font-family:&quot;Calibri Light&quot;,sans-serif"> </span></p>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '\n' +
    '</body></html>';

function TermsAndConditions() {
    return (
        <Container>
            <div dangerouslySetInnerHTML={{__html: html}} />
        </Container>
    );
}

export default TermsAndConditions;
