import {Box, CircularProgress, Typography} from '@mui/material';
import React from 'react';
import {useAtomValue} from "jotai";
import {userAtom} from "../../store/userStore";


const StudentProfileInfo = () => {
    const user = useAtomValue(userAtom);

    if (!user) {
        return (
            <CircularProgress/>
        );
    }

    return (

        <Box >
            <>
                <Box sx={{display: 'flex', alignItems: 'center', mb: 1}}>
                    <Typography typography="body1" sx={{marginRight: 1}}>
                        Profile ID
                    </Typography>
                    <Typography
                        typography="h2"
                        sx={{color: '#809252'}}>{user.accountNumber}</Typography>
                </Box>
                <Typography typography="subtitle2" sx={{color: '#A4A4A4'}}>
                    Last update {user.modifiedDate}
                </Typography>
            </>
        </Box>

    );
}

export default StudentProfileInfo;
