import {Box, TextField, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import DeletePositionModal from './DeletePositionModal';
import AddPositionModal from './AddPositionModal';
import {IJobPosition} from '../../models/IJobPosition';
import useJobPositionApi from '../../api/useJobPositionApi';
import EditIcon from '@mui/icons-material/Edit';
import useJobPositions from "../../hooks/useJobPositions";

const deleteLink = {
    color: '#D32F2F',
    cursor: 'pointer',
    fontSize: '0.875rem',
};

const duplicateLink = {
    color: '#809252',
    cursor: 'pointer',
    fontSize: '0.875rem',
    marginRight: '1rem',
};

function CompanyPositionHeader({showLinks = false}: { showLinks?: boolean }) {
    const {id} = useParams();
    const {getPosition, updateName} = useJobPositionApi();
    const {loadUserJobPositions} = useJobPositions();

    const [position, setPosition] = useState<IJobPosition>();
    const [positionName, setPositionName] = useState('');

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openAdd, setOpenAdd] = useState(false);
    const handleOpenAdd = () => setOpenAdd(true);
    const handleCloseAdd = () => setOpenAdd(false);

    const [edit, setEdit] = useState(false);

    useEffect(() => {
        if (id) {
            loadPosition();
        }
    }, [id]);

    const loadPosition = async () => {
        if (id) {
            const pos = await getPosition(parseInt(id)) as IJobPosition;
            if (pos) {
                setPosition(pos);
                setPositionName(pos.name)
            }
        }
    };

    const updatePositionName = async () => {
        if (id) {
            await updateName(parseInt(id), {name: positionName} as IJobPosition);
            await loadUserJobPositions();

        }
        setEdit(false);
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{width: '1.5rem'}}/>
            {edit ? (
                <>
                    <TextField
                        autoFocus
                        value={positionName}
                        onChange={(event) => setPositionName(event.target.value)}
                        onKeyUp={(e) => {
                            if (e.key === "Enter") {
                                updatePositionName();
                            }
                        }}
                        onBlur={updatePositionName}
                        sx={{
                            '& .MuiInputBase-root': {
                                height: 'inherit',
                                marginTop: '0.5rem',
                                marginBottom: '0.5rem',
                                fontSize: '1.25rem'
                            }, '& .MuiOutlinedInput-input': {
                                padding: 0,
                            }
                        }}
                    />
                </>
            ) : (
                <>
                    <Typography typography="h3" sx={{fontWeight: 700}}>{positionName}</Typography>
                    <EditIcon sx={{width: '1rem', marginLeft: '1rem', cursor: 'pointer'}}
                              onClick={() => setEdit(true)}/>
                </>
            )}

            <Typography sx={{ml: 4}}>Creator: {position?.createdBy} - Last modified by: {position?.lastModifiedBy}</Typography>

            <Box sx={{flexGrow: 1}}/>
            {showLinks && (
                <>
                    <Typography sx={duplicateLink} onClick={() => handleOpenAdd()}>Duplicate position</Typography>
                    <Typography sx={deleteLink} onClick={() => handleOpen()}>Delete position</Typography>
                    <DeletePositionModal open={open} handleClose={handleClose}/>
                    <AddPositionModal open={openAdd} handleClose={handleCloseAdd} template={position}/>
                </>
            )}
            <Box sx={{width: '1.5rem'}}/>
        </Box>
    );
}

export default CompanyPositionHeader;
