import {Box, Paper, Stack, TextField, Typography,} from '@mui/material';
import React, {useState} from 'react';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';
import ActionButton, {ActionButtonType} from '../ActionButton';
import {styles} from '../theme';
import {IJobPosition} from '../../models/IJobPosition';
import {userAtom} from '../../store/userStore';
import useJobPositions from '../../hooks/useJobPositions';
import {toast} from "react-toastify";
import {ISearchFilter} from "../../models/ISearchFilter";
import CompanyFilterLeft from "../../pages/Company/Positions/Filter/CompanyFilterLeft";

const style = {
    // position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '37.75rem',
};
const initialSearchFilterState = {
    metrixRanges: [],
    jobPositionId: null,
    mismatches: 0,
    universities: [],
};

function AddPositionForm() {
    const [name, setName] = useState('');
    const {createJobPosition, savePositionFilter} = useJobPositions();
    const user = useAtomValue(userAtom);
    const navigate = useNavigate();

    const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialSearchFilterState);

    const updateFilter = async (newFilter: ISearchFilter, updateCharts: boolean, saveFilter: boolean = true, updateCount: boolean = true) => {
        setSearchFilter((prev) => newFilter);
    };

    const createNewPosition = async (name: string) => {
        if (!name || name.trim().length < 3) {
            toast.error("Please use name that is at least 3 characters long!");
            return;
        }
        const position = {
            id: 0,
            name,
            userId: user?.id,
            filter: '{}',
        } as IJobPosition;

        const addedPosition = await createJobPosition(position);

        await savePositionFilter(parseInt(addedPosition.id), searchFilter);

        navigate(`/positions/${addedPosition.id}/filter?intro=true`);

    };


    return (
        <Stack sx={style}>
            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Add first position</Typography>
                <TextField
                    sx={{width: '100%', fontSize: '0.8125rem'}}
                    variant="standard"
                    placeholder="Position"
                    autoFocus
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <Box sx={{marginTop: '1rem'}}>

                <CompanyFilterLeft searchFilter={searchFilter} updateFilter={updateFilter}
                                   chartUpdateKey={0}/>
                </Box>

                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box style={{flexGrow: 1}}/>
                    <ActionButton
                        label="Add"
                        type={ActionButtonType.SUBMIT}
                        onClick={() => createNewPosition(name)}
                    />
                </Box>
            </Paper>
        </Stack>
    );
}

export default AddPositionForm;
