import React from 'react';
import {Box, Typography,} from '@mui/material';
import {PersonSearch} from "@mui/icons-material";


function ValuesRightSideContent() {

    return (
        <>
            <Box sx={{display: 'flex', alignItems: 'center', color: '#0ea3c3'}} mb={2}>
                <PersonSearch/>
                <Typography sx={{
                    ml: 1.5,
                    fontSize: '1rem',
                    letterSpacing: '.2px',
                    fontWeight: '400'
                }}>Your attitude</Typography>
            </Box>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent ac risus vitae odio efficitur ornar
        </>
    );
}

export default ValuesRightSideContent;
