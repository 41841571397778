import React from 'react';
import {Box, Container} from '@mui/material';
import NewHeaderBar from "../components/NewHeaderBar";

const styles = {
    container: {
        // width: "100%",
        // height: "100vh",
        // spacing: 0,
        // alignItems: "center",
        // justifyContent: "center",
        // justifyContent: "center"
        // alignContent: "center",
        // alignItems: "center"
    },
    box: {
        // width: "100%",
        // height: "100vh",
        //minWidth: '80rem',
        // maxWidth: "120rem",
        //display: 'flex',
        justifyContent: 'center',
        marginTop: '0.5rem',
    },
};

function AdminLayout({children}: { children: JSX.Element }) {
    return (
        <Container sx={styles.container} disableGutters>
            <NewHeaderBar/>
            {

                <Box sx={styles.box}>
                {children}
            </Box>

            }

        </Container>

    );
}

export default AdminLayout;
