import React from 'react';
import {toast} from 'react-toastify';
import useApi, {BASE_URL} from './useApi';
import {IJobPosition} from '../models/IJobPosition';
import {ISearchFilter} from '../models/ISearchFilter';
import {FilterStatus} from '../models/IUserProfile';

const JOB_POSITIONS_URL = `${BASE_URL}job-positions`;

const useJobPositionApi = () => {
    const {doFetch} = useApi();

    const UPDATE_STUDENT_STATUS_URL = (id: number) => `${JOB_POSITIONS_URL}/${id}/upsert-student`;

    const SEND_OFFER = (id: number) => `${JOB_POSITIONS_URL}/${id}/send-offer`;

    const USER_POSITIONS_URL = (id: number) => `${BASE_URL}user/${id}/positions`;

    const createPosition = (jobPosition: IJobPosition) => {
        const url = JOB_POSITIONS_URL;
        return doFetch(url, 'POST', {...jobPosition, id: null});
    };

    const deletePosition = (jobPositionId: number, comment: string) => {
        const url = `${JOB_POSITIONS_URL}/${jobPositionId}/delete`;
        return doFetch(url, 'POST', {comment}, true);
    };

    const getPosition = (id: number) => {
        console.log('get pos!')
        const url = `${JOB_POSITIONS_URL}/${id}`;
        return doFetch(url);
    };

    const getPositionOffers = (id: number) => {
        const url = `${JOB_POSITIONS_URL}/${id}/offers`;
        return doFetch(url);
    };

    const getConfirmedStudents = (id: number) => {
        const url = `${JOB_POSITIONS_URL}/${id}/confirmed-students`;
        return doFetch(url);
    };

    const saveFilter = (positionId: number, filter: ISearchFilter) => {
        const url = `${JOB_POSITIONS_URL}/${positionId}/filter`;
        return doFetch(url, 'PUT', {...filter}, true)
            .catch((e) => {
                console.log(e);
                toast.warn('Failed to save filter');
            });
    };

    const updateName = (positionId: number, jobPosition: IJobPosition) => {
        const url = `${JOB_POSITIONS_URL}/${positionId}/update-name`;
        return doFetch(url, 'PUT', {...jobPosition}, true)
            .catch((e) => {
                console.log(e);
                toast.warn('Failed to update name');
            });
    };

    const changeStudentFilterStatus = (jobPositionId: number, studentId: number, status: FilterStatus) => {
        const url = UPDATE_STUDENT_STATUS_URL(jobPositionId);

        return doFetch(url, 'POST', {
            studentId,
            jobPositionId,
            filterStatus: status,
        }, true);
    };

    const sendOffer = (jobPositionId: number, fileId: number | undefined | null, subject: string, content: string) => {
        const url = new URL(SEND_OFFER(jobPositionId));
        if (fileId) {
            url.search = new URLSearchParams({
                fileId: fileId.toString(),
            }).toString();
        }

        return doFetch(url.toString(), 'POST', {
            subject,
            content,
        }, true);
    };

    return {
        createPosition,
        deletePosition,
        getPosition,
        saveFilter,
        changeStudentFilterStatus,
        getPositionOffers,
        getConfirmedStudents,
        sendOffer,
        updateName
    };
};

export default useJobPositionApi;
