import React from 'react';
import {Container, Grid, Typography} from '@mui/material';

function Welcome() {
    return (
        <Container>
            <Grid alignItems="center"
                  justifyContent="center"
                  container
                  direction="column"
                height='100vh'>
                <Typography sx={{marginBottom: '2rem'}}>Thanks for joining Jobling!</Typography>
                <Typography >Your account has been successfully activated. We have sent you an email with a link to log in to your account. </Typography>
            </Grid>

        </Container>
    );
}

export default Welcome;
