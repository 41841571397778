import React from 'react';
import {Paper, Stack, Typography} from '@mui/material';
import {styles} from '../../../components/theme';

function AdminDashboardLeft({
                                pages,
                                activePage,
                                setActivePage,
                            }: {
    pages: any,
    activePage: any,
    setActivePage: any
}) {
    return (
        <Stack spacing={1}>
            <Paper elevation={0} sx={styles.paper}>
                {pages.map((page: any, index: number) => {
                    const isActive = activePage ? page.key === activePage?.key : index === 0;

                    const margin = index === pages.length - 1 ? '0rem' : '1rem';
                    const color = isActive ? '#809252' : '#000000';
                    const fontWeight = isActive ? 'bold' : 'normal';

                    return (
                        <Typography
                            typography="body1"
                            key={index}
                            sx={{
                                marginBottom: margin, color, cursor: 'pointer', fontWeight,
                            }}
                            onClick={() => setActivePage(page)}
                        >
                            {page.name}
                        </Typography>
                    );
                })}
            </Paper>
        </Stack>
    );
}

export default AdminDashboardLeft;
