import React, {useEffect, useState} from 'react';
import {Box, Link,} from '@mui/material';
import {FilterStatus, IUserProfile} from '../../../../models/IUserProfile';
import AcceptedIndicator from '../../../../assets/AcceptedIndicator';
import {IFilteredStudent} from '../../../../models/IFilteredStudent';
import {formatProfileLabel} from "../../../../util/formatUtil";

function CompanyProfileSelectRight({
                                       students,
                                       activeProfile,
                                       setActiveProfile,
                                   }: {
    students: IFilteredStudent[],
    activeProfile: IUserProfile | null,
    setActiveProfile: any
}) {
    const [confirmedProfiles, setConfirmedProfiles] = useState<IFilteredStudent[]>([]);

    useEffect(() => {
        setConfirmedProfiles(students.filter((students) => students.filterStatus === FilterStatus.CONFIRMED));
    }, [students]);

    return (
        <Box>
            {confirmedProfiles?.map((p, i) => {
                const color = p.id === activeProfile?.id ? '#809252' : '#303030';
                const fontWeight = p.id === activeProfile?.id ? 'bold' : 'normal';

                return (
                    <Box sx={{display: 'flex', alignItems: 'center'}} key={i}>
                        <Link
                            href="#"
                            onClick={() => setActiveProfile(p)}
                            style={{
                                color: color,
                                fontWeight: fontWeight,
                                textDecoration: 'none',
                            }}
                        >
                            {formatProfileLabel(p.accountNumber)}
                        </Link>
                        <Box sx={{flexGrow: 1}}/>
                        <AcceptedIndicator/>
                    </Box>
                );

            })}
        </Box>
    );
}

export default CompanyProfileSelectRight;
