import {Box, TextField, Tooltip, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {IUserProfile} from "../../../../models/IUserProfile";
import InfoIcon from "@mui/icons-material/InfoSharp";
import {MetrixTooltipContent} from "../../../../components/MetrixTooltip";

const initialDescription = {
    workExperience: '',
    skills: '',
    expectations: ''
};

const AdditionalInformation = ({
                                   userProfile,
                                   setUserProfile
                               }: { userProfile: IUserProfile | null, setUserProfile: any }) => {
    const [description, setDescription] = useState(initialDescription);

    const setDescriptionField = (value: string, fieldName: string) => {
        if (userProfile) {
            const updatedDescription = {...description, [fieldName]: value}

            setDescription(updatedDescription)
            setUserProfile({...userProfile, description: JSON.stringify(updatedDescription)});
        }
    };

    const descriptionLength = () => {
        let count = 0;
        if (description.workExperience) {
            count += description.workExperience.length;
        }
        if (description.skills) {
            count += description.skills.length;
        }
        if (description.expectations) {
            count += description.expectations.length;
        }
        return count;
    }

    useEffect(() => {
        if (userProfile) {
            try {
                const loadedDescription = JSON.parse(userProfile.description!!)
                if(loadedDescription) {
                    setDescription(loadedDescription)
                }
            } catch(e) {
                console.log(e);
            }
        }
    }, [userProfile]);

    return (
        <Box>
            <Typography typography="body1">
                Additional information about myself
                <Tooltip title="Very important for employers. This part makes you stand out from the crowd." placement="right">
                    <InfoIcon sx={{
                        fontSize: '1rem', color: '#FFA049', marginLeft: '0.5rem', cursor: 'pointer',
                    }}
                    />
                </Tooltip>
            </Typography>

            <Box sx={{mt: 2}}>
                <TextField
                    sx={{mb: 2}}
                    variant="standard"
                    multiline
                    label={"Previous work experience"}
                    fullWidth
                    value={description?.workExperience || ''}
                    onChange={(e) => setDescriptionField(e.target.value, 'workExperience')}
                />
                <TextField
                    sx={{mb: 2}}
                    variant="standard"
                    multiline
                    label={"Skills"}
                    fullWidth
                    value={description?.skills || ''}
                    onChange={(e) => setDescriptionField(e.target.value, 'skills')}
                />
                <TextField
                    variant="standard"
                    multiline
                    label={"Expectations about work"}
                    fullWidth
                    value={description?.expectations || ''}
                    onChange={(e) => setDescriptionField(e.target.value, 'expectations')}
                />

                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                    <Typography sx={{fontSize: '0.75rem'}}>{descriptionLength()} / 1000</Typography>
                </Box>
            </Box>

        </Box>
    )
};

export default AdditionalInformation;
