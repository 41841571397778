import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid, Stack, Typography} from '@mui/material';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {ISearchFilter} from '../../../../models/ISearchFilter';
import CompanyFilterLeft from './CompanyFilterLeft';
import CompanyFilterMiddle from './CompanyFilterMiddle';
import Layout from '../../../Layout';
import CompanyPositionHeader from '../../../../components/company/CompanyPositionHeader';
import useJobPositions from '../../../../hooks/useJobPositions';
import useFilterApi from '../../../../api/useFilterApi';
import useJobPositionApi from '../../../../api/useJobPositionApi';
import CompanyFilterRight from './CompanyFilterRight';
import FilterIntroModal from "../../../../components/company/FilterIntroModal";
import PositionStepper from "../PositionStepper";
import {useAtom, useAtomValue} from "jotai";
import {
    orderModalOpenAtom,
    orderModalShouldNotOpenAtom,
    orderOverviewAtom,
    orderWasSuccessfulAtom, positionsWithAlreadyShownModalAtom
} from "../../../../store/ordersStore";
import {userAtom} from "../../../../store/userStore";
import JobManagedByJoblingNotification from "./JobManagedByJoblingNotification";
import useOrders from "../../../../hooks/useOrders";
import ProductPackageSelectionModal from "../../ProductPackage/ProductPackageSelectionModal";
import ProductPackageSelection from "../../ProductPackage/ProductPackageSelection";
import {styles} from "../../../../components/theme";
import QualifiedProductPackage from "../../ProductPackage/QualifiedProductPackage";

function CompanyFilter() {
    const {getPosition} = useJobPositionApi();
    const {savePositionFilter} = useJobPositions();
    const {searchCount} = useFilterApi();
    const {id} = useParams();
    const [params, setSearchParams] = useSearchParams();
    const orderOverview = useAtomValue(orderOverviewAtom);
    const [orderModalOpen, setOrderModalOpen] = useAtom(orderModalOpenAtom);
    const [positionsWithAlreadyShownModal, setPositionsWithAlreadyShownModal] = useAtom(positionsWithAlreadyShownModalAtom);
    const orderModalShouldNotOpen = useAtomValue(orderModalShouldNotOpenAtom);
    const orderWasSuccessful = useAtomValue(orderWasSuccessfulAtom);

    const {isJobPositionManagedByJobling} = useOrders();
    const [jobPositionIsLocked, setJobPositionIsLocked] = useState(false);
    const [openJobManagedByJobling, setOpenJobManagedByJobling] = useState(false);
    const [jumpToQualifiedPackageConfirmation, setJumpToQualifiedPackageConfirmation] = useState(false);
    const [hasAlreadySentSomeOffers, setHasAlreadySentSomeOffers] = useState<boolean | null>(null);
    const user = useAtomValue(userAtom);
    const isNotGlobalAdmin = user?.isAdmin === false;

    const [open, setOpen] = useState(false);
    const handleClose = () => {
        params.delete("intro");
        setSearchParams(params);
        setOpen(false);
    }

    const [searchFilter, setSearchFilter] = useState<ISearchFilter>({
        metrixRanges: [],
        jobPositionId: id ? parseInt(id) : null,
        mismatches: 0,
    });


    const [matchCount, setMatchCount] = useState<number[]>([0, 0, 0]);
    const [chartUpdateKey, setChartUpdateKey] = useState<number>(0);
    const navigate = useNavigate();

    useEffect(() => {
        setHasAlreadySentSomeOffers(null)
        loadFilterFromNewPosition();
        updateOrderRelatedStatus();
    }, [id]);

    useEffect(() => {
        updateOrderRelatedStatus();
    }, [orderOverview, hasAlreadySentSomeOffers]);

    function updateOrderRelatedStatus() {
        let positionIsManagedByUs = isJobPositionManagedByJobling(parseInt(id ?? "0"));
        let positionIsLocked = positionIsManagedByUs && isNotGlobalAdmin;
        setJobPositionIsLocked(positionIsLocked);
        if (orderWasSuccessful !== null) {
            setTimeout(() => {
                setOpenJobManagedByJobling(positionIsLocked)
            }, 4000);
        } else {
            setOpenJobManagedByJobling(positionIsLocked)
        }
        if (!positionIsLocked && hasAlreadySentSomeOffers === false && (id === undefined || positionsWithAlreadyShownModal.indexOf(id ?? "") === -1)) {
            if (!orderModalShouldNotOpen) {
                setTimeout(() => {
                    setPositionsWithAlreadyShownModal((prev) => [...prev, id ?? ""]);
                }, 800);
                setOrderModalOpen(true)
            }
        } else {
            setOrderModalOpen(false)
        }
    }

    const onCancel = () => {
        setPositionsWithAlreadyShownModal((prev) => [...prev.filter((p) => p !== id)]);
        navigate('/offer-status');
    }

    const loadFilterFromNewPosition = async () => {
        if (id) {
            const pos = await getPosition(parseInt(id));
            setHasAlreadySentSomeOffers(pos.offersSentCount > 0);
            if (pos.filter) {
                const loadedFilter = JSON.parse(pos?.filter) as ISearchFilter;
                if (!loadedFilter.mismatches) {
                    loadedFilter.mismatches = 0;
                }
                await updateFilter(loadedFilter, true, false);
            }
            if (params.get("intro")) {
                //setOpen(true);
            }
        }
    };

    const updateFilter = async (newFilter: ISearchFilter, updateCharts: boolean, saveFilter: boolean = true, updateCount: boolean = true) => {
        setSearchFilter((prev) => newFilter);
        if (id) {
            if (updateCount) {
                searchCount(parseInt(id), newFilter, 0).then((counts) => {
                    if (counts !== undefined && counts !== null) {
                        setMatchCount((prev) => counts);
                    } else {
                        setMatchCount((prev) => [0, 0, 0]);
                    }
                });
            }

            if (updateCharts) {
                setChartUpdateKey((prev) => chartUpdateKey + 1);
            }

            if (saveFilter) {
                await savePositionFilter(parseInt(id), newFilter);
            }
        }
    };

    if (!searchFilter) {
        return (
            <Layout>
                <Stack spacing={1}>
                    <Typography typography="h3">Job position was not found</Typography>
                </Stack>
            </Layout>
        );
    }


    return (
        <Layout>

            <Stack spacing={1}>
                <CompanyPositionHeader showLinks/>
                <Stack direction="row" spacing={1}>
                    <CompanyFilterLeft searchFilter={searchFilter} updateFilter={updateFilter}
                                       chartUpdateKey={chartUpdateKey} isSidePanel={true}
                                       isLocked={jobPositionIsLocked}/>
                    <CompanyFilterMiddle
                        searchFilter={searchFilter}
                        updateFilter={updateFilter}
                        chartUpdateKey={chartUpdateKey}
                        matchCount={matchCount}
                        isLocked={jobPositionIsLocked}
                    />
                    {jobPositionIsLocked ? undefined :
                        <Stack sx={styles.rightStack}>
                            <PositionStepper activeStep={0}>
                                <CompanyFilterRight searchFilter={searchFilter} updateFilter={updateFilter}
                                                    matchCount={matchCount}/>
                            </PositionStepper>
                            <QualifiedProductPackage
                                availableContactRequests={orderOverview.availableContactRequests}
                                showOnlyQualifiedPackage={!jobPositionIsLocked && true}
                                onCancel={() => {}}
                                onProceed={() => {
                                    setOrderModalOpen(true);
                                    setJumpToQualifiedPackageConfirmation(true)
                                    setTimeout(() => {
                                        setJumpToQualifiedPackageConfirmation(false)
                                    }, 1000);
                                }}
                            />
                        </Stack>
                    }


                </Stack>
                <FilterIntroModal open={open} handleClose={handleClose}/>
                <JobManagedByJoblingNotification open={openJobManagedByJobling} setOpen={setOpenJobManagedByJobling}/>
                <ProductPackageSelectionModal open={orderModalOpen}
                                              onCancel={onCancel}
                                              onClose={() => setOrderModalOpen(false)}
                                              availableContactRequests={orderOverview.availableContactRequests}
                                              jobPositionId={parseInt(id ?? "0")}
                                              jumpToQualifiedPackageConfirmation={jumpToQualifiedPackageConfirmation}

                />
            </Stack>
        </Layout>
    );
}

export default CompanyFilter;
