import React from 'react';
import {Box, Paper, Slider, Tooltip, Typography,} from '@mui/material';
import {useTranslation} from 'react-i18next';
import InfoIcon from '@mui/icons-material/InfoSharp';
import {styles} from './theme';

export function MetrixTooltipContent({
                                         metrixCode,
                                     }: { metrixCode: string }) {
    const {t} = useTranslation();

    const leftInfo = metrixCode.startsWith("COMPETENCE_") ? null : t(`company.filter.metrix.${metrixCode}.leftInfo`);
    const rightInfo = metrixCode.startsWith("COMPETENCE_") ? null : t(`company.filter.metrix.${metrixCode}.rightInfo`);

    return (<Paper
            elevation={3}
            sx={{
                ...styles.paper,
                padding: '0.5rem',
                width: '150%',
                marginTop: '-0.25rem',
                marginBottom: '-0.25rem',
                marginLeft: '-0.6rem',
                border: '2px solid black',
            }}
        >
            <Typography sx={{
                fontSize: '0.75rem',
                letterSpacing: '.2px',
                fontWeight: '600',
            }}
            >
                {t(`company.filter.metrix.${metrixCode}.title`)}
            </Typography>

            <Typography sx={{
                fontSize: '0.7rem',
                letterSpacing: '.2px',
                fontWeight: '300',
                color: '#474b58',
            }}
            >
                {t(`company.filter.metrix.${metrixCode}.info`)}
            </Typography>

            <Slider value={[0, 100]} disabled style={{color: '#A2DA93'}}/>

            <Box sx={{display: 'flex'}}>
                <Box sx={{width: '10rem'}}>
                    <Typography sx={{
                        fontSize: '0.65rem',
                        letterSpacing: '.2px',
                        fontWeight: '600',
                        color: '#474b58',
                    }}
                    >
                        {t(`company.filter.metrix.${metrixCode}.leftLabel`)}
                    </Typography>

                    {leftInfo && <Typography
                        sx={{
                            fontSize: '0.6rem',
                            fontWeight: '300',
                            color: '#474b58',
                        }}
                    >
                        {leftInfo}
                    </Typography>
                    }
                </Box>

                <Box sx={{flexGrow: 1}}/>
                <Box sx={{width: '10rem'}}>
                    <Typography sx={{
                        fontSize: '0.65rem',
                        letterSpacing: '.2px',
                        fontWeight: '600',
                        color: '#474b58',
                        textAlign: 'right',
                    }}
                    >
                        {t(`company.filter.metrix.${metrixCode}.rightLabel`)}
                    </Typography>

                    {rightInfo && <Typography
                        sx={{
                            fontSize: '0.6rem',
                            fontWeight: '300',
                            color: '#474b58',
                        }}
                    >
                        {rightInfo}
                    </Typography>
                    }
                </Box>

            </Box>

        </Paper>
    );
}


function MetrixTooltip({
                           metrixCode,
                           disabled = false
                       }: { metrixCode: string, disabled?: boolean }) {


    return (
        <>
            {disabled ? (
                <InfoIcon sx={{
                    fontSize: '1rem', color: '#D3D3D3', marginLeft: '0.5rem', cursor: 'pointer',
                }}
                />
            ) : (
                <Tooltip title={<MetrixTooltipContent metrixCode={metrixCode}/>} placement="left">
                    <InfoIcon sx={{
                        fontSize: '1rem', color: '#FFA049', marginLeft: '0.5rem', cursor: 'pointer',
                    }}
                    />
                </Tooltip>
            )}
        </>
    );
}

export default MetrixTooltip;
