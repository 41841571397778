import React, {useEffect} from 'react';
import {Box, CircularProgress, Switch, Tooltip, Typography,} from '@mui/material';
import {useAtom, useAtomValue} from 'jotai';
import {profileSubmittedAtom, profileVisibleAtom, unsavedPreferencesAtom, userAtom} from '../../../store/userStore';
import useUserProfileApi from '../../../api/useUserProfileApi';
import {toast} from "react-toastify";
import {overviewAtom} from "../../../store/metrixStore";
import UnsavedConfirmationDialog from "../../Home/UnsavedConfirmationDialog";

function ProfileVisibility() {
    const user = useAtomValue(userAtom);
    const overview = useAtomValue(overviewAtom);
    const hasUnsavedChanges = useAtomValue(unsavedPreferencesAtom);

    const profileSubmitted = useAtomValue(profileSubmittedAtom);
    const [profileVisible, setProfileVisible] = useAtom(profileVisibleAtom);
    const [showPrompt, setShowPrompt] = React.useState<boolean>(false);

    const {updateProfileVisibility} = useUserProfileApi();

    const notCompletedTests = () => {
        const hasNotCompletedValuesTest = overview !== null && overview.valuesOverview.values.length === 0;
        const hasNotCompletedPersonalityTest = overview !== null && overview.personalityOverview.values.length === 0;
        const hasNotCompletedCompetenciesTest = overview !== null && overview.competenciesOverview.values.length === 0;
        const notCompletedTests = []
        if (hasNotCompletedValuesTest) {
            notCompletedTests.push("VALUE")
        }
        if (hasNotCompletedPersonalityTest) {
            notCompletedTests.push("PERSONALITY")
        }
        if (hasNotCompletedCompetenciesTest) {
            notCompletedTests.push("COMPETENCE")
        }
        return notCompletedTests
    }

    const confirmNavigation = () => {
        if (user) {
            updateProfileVisibility(user.id, true);
            setProfileVisible(true);
        }
        setShowPrompt(false)
    };

    const cancelNavigation = () => {
        setShowPrompt(false)
    };


    const changeProfileVisibility = (value: boolean) => {

        if (value && hasUnsavedChanges) {
            setShowPrompt(true)
            return;
        }

        // TODO: check for tests

        if (notCompletedTests().length > 0) {
            toast.error("You need to have complete all tests before making profile public!");
            return;
        }


        if (user) {
            updateProfileVisibility(user.id, value);
            setProfileVisible(value);
        }
    };

    useEffect(() => {

    }, []);


    if (!user) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <Box>
            <UnsavedConfirmationDialog
                // @ts-ignore
                showDialog={showPrompt}
                confirmNavigation={confirmNavigation}
                cancelNavigation={cancelNavigation}
                message={"You have unsaved changes, which will be lost if you make your profile visible without saving. Are you sure you want to make your profile visible without saving?"}
            />

            <Tooltip
                title={(profileSubmitted) ? "" : "Please fill out all required fields first!"}>

                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Typography typography="body2">
                        Profile is {profileVisible ? 'now visible' : 'not visible'} to
                        companies
                    </Typography>
                    <Switch
                        disabled={!profileSubmitted} color="success" size="small" checked={profileVisible}
                        onChange={(e, value) => changeProfileVisibility(value)}/>
                </Box>
            </Tooltip>

            {!profileVisible ?
                <Typography typography="caption" sx={{color: '#A4A4A4', mt: 1}}>
                    Choose whether your profile is visible to employers.
                    Companies do not see your name or any other personal details. If they are interested in your
                    profile, you will be informed.</Typography> : <></>}

        </Box>
    );
}

export default ProfileVisibility;
