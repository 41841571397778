import useApi, {BASE_URL} from './useApi';

const PRODUCT_PACKAGES_URL = `${BASE_URL}product-packages`;

const useProductPackagesApi = () => {
    const {doFetch} = useApi();


    const getProductPackages = () => {
        const url = `${PRODUCT_PACKAGES_URL}`;
        return doFetch(url);
    };

    return {
        getProductPackages
    };
};

export default useProductPackagesApi;
