import React from 'react';
import {Box, Typography,} from '@mui/material';
import {IMetrixOverview, IMetrixValue} from "../../../models/IMetrixOverviews";
import UserValuesHighestLowest from './UserValuesHighestLowest';
import {styles} from "../../../components/theme";


function TopMotivators({
                           overviewData,
                           averages
                       }: { overviewData: IMetrixOverview | undefined, averages: IMetrixValue[] | undefined }) {

    return (
        <Box>


            <Typography sx={styles.h2}>Your top motivators</Typography>
            <Typography sx={{...styles.body4, mt: 0.5}}>You are driven by and tend to act according to your most
                important
                values.</Typography>

            {
                /*
                            <DividerWithArrow color={'#02B033'}/>

                 */
            }

            <UserValuesHighestLowest elements={overviewData?.highestValues}
                                     averages={averages}></UserValuesHighestLowest>


        </Box>
    );
}

export default TopMotivators;
