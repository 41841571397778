import {productPackagesAtom} from "../store/ordersStore";
import {useAtom, useSetAtom} from "jotai";
import useProductPackagesApi from "../api/useProductPackagesApi";

const useProductPackages = () => {
    const setProductPackagesAtom = useSetAtom(productPackagesAtom);

    const {getProductPackages} = useProductPackagesApi();


    const loadProductPackages = async () => {
        const ordersOverview = await getProductPackages();
        setProductPackagesAtom(ordersOverview);
    };


    return {
        loadProductPackages
    };
};

export default useProductPackages;
