import React from 'react';
import {Box,} from '@mui/material';
import {
    Label,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Tooltip,
    TooltipProps
} from 'recharts';
import {IMetrixValue} from "../../../models/IMetrixOverviews";
import {useTranslation} from "react-i18next";
import {NameType, ValueType} from "recharts/types/component/DefaultTooltipContent";
import {MetrixTooltipContent} from "../../../components/MetrixTooltip";


function MetrixRadar({data}: { data: IMetrixValue[] | undefined }) {

    const {t} = useTranslation();

    const dateWithLabels = data?.map(element => {
        return {
            type: t(`company.filter.metrix.${element.type}.title`),
            value: element.value,
            code: element.type
        }
    })

    const renderLabel = (props: any) => {
        const {payload, x, y} = props;
        return (
            <g>
                <text x={x} y={y} fill="#000000" textAnchor="middle" dominantBaseline="middle" fontSize={12}
                      fontWeight={400}>
                    {payload.value}
                </text>
            </g>
        )
    }

    const CustomTooltip = ({
                               active,
                               payload,
                               label,
                           }: TooltipProps<ValueType, NameType>) => {
        if (active && payload && payload.length) {
            console.log("payload", payload)

            return (
                <Box maxWidth={"15rem"}>
                    <MetrixTooltipContent metrixCode={payload[0].payload.code}/>
                </Box>

            );
        }
        return null;
    };

    return (
        <Box>
            <ResponsiveContainer width="100%" height={600}>
                <RadarChart cx="400px" cy="400px" outerRadius="80%" data={dateWithLabels}>
                    <PolarGrid gridType="polygon"/>
                    <PolarAngleAxis dataKey="type" tick={renderLabel}>
                        <Label value="Pages of my website" offset={0} position="inside"/>
                    </PolarAngleAxis>
                    <PolarRadiusAxis angle={30} domain={[0, 100]}/>
                    <Radar dataKey="value" stroke="#809252" fill="#A2DA93" fillOpacity={0.5}/>
                    <Tooltip content={<CustomTooltip/>}/>
                </RadarChart>
            </ResponsiveContainer>
        </Box>
    );
}

export default MetrixRadar;
