import React, {useState} from 'react';
import {Link, Stack, ThemeProvider, Typography} from "@mui/material";
import {tomatoTheme} from "./theme";
import {isValidEmail} from "../util/formatUtil";
import useFormsApi from "../api/useFormsApi";
import {toast} from "react-toastify";

function Game() {

    const [email, setEmail] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const {postFormData} = useFormsApi();


    const isFormValid = () => {

        const errors = [];

        if (!isValidEmail(email)) {
            errors.push('email');
        }

        setErrorFields(errors);
        return errors.length <= 0;

    };


    const send = async () => {
        if (isFormValid()) {
            const formData = new FormData();
            formData.append("entry.389836881", email)

            postFormData(formData).then((response => {
                if (response.status != 0 && !response.ok) {
                    console.log("error response state")
                } else {
                    setEmail('')
                    toast.success("E-mail submitted!")
                }
            })).catch((e) => {
                console.log("error", e)
            }).finally(() => {

                }
            )
        }

    };

    return (
        <ThemeProvider theme={tomatoTheme}>
            <Stack sx={{
                minWidth: "100%",
                width: "100%",
                height: "100vh",
                maxHeight: "-webkit-fill-available",
                backgroundColor: "#FFFFFF",
                alignItems: "center",
                paddingBottom: "2rem"
            }}>
                <Stack sx={{
                    width: "100%",
                    alignItems: "center",
                    position: "absolute",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    zIndex: 1
                }}/>
                <iframe
                    src="https://turbowarp.org/808609266/embed?autoplay&addons=remove-curved-stage-border&username=apunkto"
                    style={{overflow: "hidden", height: "100%", minWidth: "100%"}}
                    frameBorder="0" scrolling="no" allowFullScreen/>
                <Stack padding={"1rem"}>
                <Typography textAlign={"center"} sx={{marginTop: "1rem", typography: {sm: "h1", xs: "h2"}}}>Win a round trip for 2 to
                    Linnanmäki amusement park!</Typography>
                <Typography textAlign={"center"} variant={"h2"}
                            sx={{marginBottom: "1rem", marginTop: "1rem", typography: {sm: "h2", xs: "h3"}}}>Complete
                    your profile
                    at <Link target={"_blank"}
                             href="https://jobling.ee"><Typography sx={{typography: {sm: "h2", xs: "h3"}}} component={"span"}
                                                           color={"green"}>jobling.ee</Typography></Link> to
                    participate</Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    );
}

export default Game;
