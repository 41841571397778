import {Box, Paper, Typography} from '@mui/material';
import React from 'react';
import {styles} from "../theme";


const StudentInformation = ({description}: {description: string}) => {

    const renderDescription = () => {
        const json = JSON.parse(description) || {};
        return (
            <>
                {
                    json.workExperience && (
                        <>
                            <Typography sx={styles.body1}>Previous work experience: <span style={{fontWeight: '500'}}>{json.workExperience}</span></Typography>
                            <Box sx={{height: '0.5rem'}}/>
                        </>
                    )
                }
                {
                    json.skills && (
                        <>
                            <Typography sx={styles.body1}>Skills: <span style={{fontWeight: '500'}}>{json.skills}</span></Typography>
                            <Box sx={{height: '0.5rem'}}/>
                        </>
                    )
                }
                {
                    json.expectations && (
                        <>
                            <Typography sx={styles.body1}>Expectations about work: <span style={{fontWeight: '500'}}>{json.expectations}</span></Typography>
                        </>
                    )
                }
            </>
        )
    }

    return (
        <Paper elevation={0} sx={styles.paperCenter}>
            <Typography typography="h4">Additional information</Typography>
            {renderDescription()}
        </Paper>
    );
}

export default StudentInformation;
