import React from 'react';

function DoneIcon({fill = '#D3D3D3'}: { fill: string; }) {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3364_1177)">
                <path
                    d="M8.00276 15.9993C6.42043 15.9999 4.87347 15.5313 3.55752 14.6527C2.24156 13.774 1.21574 12.5249 0.60978 11.0632C0.00382319 9.60145 -0.155043 7.99288 0.153274 6.44089C0.46159 4.8889 1.22324 3.46319 2.34189 2.3441C3.46054 1.22501 4.88595 0.462807 6.43782 0.153883C7.98969 -0.15504 9.59832 0.00319796 11.0603 0.608584C12.5222 1.21397 13.7718 2.23931 14.6509 3.55492C15.5301 4.87053 15.9993 6.41731 15.9993 7.99964C15.9993 10.1207 15.1569 12.155 13.6574 13.6552C12.1578 15.1553 10.1239 15.9985 8.00276 15.9993ZM14.7494 8.00651C14.7512 6.21702 14.0423 4.50004 12.7786 3.23303C11.5149 1.96603 9.79975 1.25271 8.01026 1.24989C4.29165 1.24052 1.26364 4.25728 1.24989 7.98464C1.23614 11.712 4.26728 14.7494 7.99963 14.7494C9.78858 14.7494 11.5044 14.0392 12.77 12.7749C14.0356 11.5105 14.7475 9.79546 14.7494 8.00651Z"
                    fill={fill}/>
                <path
                    d="M6.93223 9.23404C6.96438 9.17646 7.00161 9.12187 7.04348 9.07092C8.2226 7.88847 9.4036 6.70622 10.5865 5.52419C10.7808 5.32982 10.9971 5.22482 11.2739 5.3092C11.3691 5.33723 11.4561 5.3878 11.5275 5.4566C11.5989 5.52541 11.6527 5.61043 11.6843 5.70445C11.7159 5.79846 11.7243 5.89871 11.7089 5.99669C11.6935 6.09467 11.6547 6.18749 11.5958 6.26728C11.5515 6.32526 11.5028 6.37979 11.4502 6.4304C10.1119 7.77118 8.77258 9.11112 7.43221 10.4502C7.06598 10.8165 6.73661 10.8152 6.36975 10.4465C5.75061 9.8286 5.13147 9.21029 4.51232 8.59156C4.32483 8.40407 4.23171 8.19408 4.30858 7.92909C4.33602 7.83101 4.38724 7.74123 4.4577 7.6677C4.52816 7.59416 4.61568 7.53916 4.7125 7.50757C4.80931 7.47598 4.91243 7.46877 5.0127 7.48659C5.11297 7.50441 5.20729 7.54671 5.28729 7.60973C5.34019 7.65319 5.39028 7.69995 5.43729 7.74972L6.77286 9.08279C6.81224 9.12467 6.85411 9.15966 6.93223 9.23404Z"
                    fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0_3364_1177">
                    <rect width="16" height="16" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
}

export default DoneIcon;
