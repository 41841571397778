import {Box, Button, FormControlLabel, Modal, Paper, Radio, RadioGroup, Stack, Typography,} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from 'react-router';
import {useParams} from 'react-router-dom';
import ActionButton, {ActionButtonType} from '../ActionButton';
import {styles} from '../theme';
import useJobPositions from '../../hooks/useJobPositions';

const style = {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    width: '20rem',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
};

function DeletePositionModal({open, handleClose}: { open: boolean; handleClose: () => void; }) {
    const {id} = useParams();
    const {deleteJobPosition} = useJobPositions();
    const navigate = useNavigate();
    const [reason, setReason] = useState('hats');

    const deletePosition = async () => {
        if (id) {
            await deleteJobPosition(parseInt(id), reason);
            navigate('/dashboard');
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Stack sx={style}>
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="h3">Delete position</Typography>
                    <RadioGroup onChange={(e) => setReason(e.target.value)} value={reason}>
                        <FormControlLabel
                            value="hats"
                            control={<Radio size="small"/>}
                            label="Found an employee from Jobling"
                        />
                        <FormControlLabel value="other" control={<Radio size="small"/>} label="Other reason"/>
                    </RadioGroup>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box style={{flexGrow: 1}}/>
                        <Button
                            variant="text"
                            sx={{fontSize: '0.875rem', color: '#809252'}}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton label="Delete" type={ActionButtonType.SUBMIT} onClick={() => deletePosition()}/>
                    </Box>
                </Paper>
            </Stack>
        </Modal>
    );
}

export default DeletePositionModal;
