import React from 'react';
import {Box, Typography,} from '@mui/material';
import {IMetrixOverview, IMetrixValue} from "../../../models/IMetrixOverviews";
import UserValuesHighestLowest from './UserValuesHighestLowest';
import {styles} from "../../../components/theme";


function LowestPriority({
                            overviewData,
                            averages
                        }: { overviewData: IMetrixOverview | undefined, averages: IMetrixValue[] | undefined }) {

    return (
        <Box>
            <Typography sx={styles.h2}>Lowest
                priority</Typography>
            <Typography sx={{...styles.body4, mt: 0.5}}>The values that you consider unimportant or might
                oppose.</Typography>
            {
                /*
                <DividerWithArrow color={'#ffb575'}/>
                 */
            }


            <UserValuesHighestLowest elements={overviewData?.lowestValues}
                                     averages={averages}></UserValuesHighestLowest>
        </Box>
    );
}

export default LowestPriority;
