import React from 'react';
import {LinearProgress, Paper, Stack, Typography} from '@mui/material';
import {styles} from '../../../components/theme';
import {IJobPosition} from '../../../models/IJobPosition';
import {useAtomValue} from "jotai";
import {orderOverviewAtom} from "../../../store/ordersStore";
import useOrders from "../../../hooks/useOrders";

function CompanyOfferStatusLeft({
                                  positions,
                                  activePosition,
                                  setActivePosition,
                              }: {
    positions: IJobPosition[],
    activePosition: IJobPosition | null,
    setActivePosition: any
}) {

    const orderOverview = useAtomValue(orderOverviewAtom);
    const {isJobPositionManagedByJobling} = useOrders();
    const positionsWithOffers = positions.filter((p) => p.offersSentCount > 0)
    const positionsWithoutOffers = positions.filter((p) => p.offersSentCount === 0)

    let availableContactRequestsPercetnage = (orderOverview.availableContactRequests * 100)/orderOverview.paidContactRequests;
    if (isNaN(availableContactRequestsPercetnage)) {
        availableContactRequestsPercetnage = 0;
    }
    return (
        <Stack sx={styles.leftStack} spacing={1}>
            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="body1" sx={{mb: 3, fontWeight: 700}}>Available contact requests</Typography>
                <Typography variant="body1" align="center">
                    {availableContactRequestsPercetnage === 0 ? 'No contact requests available' :`${orderOverview.availableContactRequests} out of ${orderOverview.paidContactRequests}`}
                </Typography>
                <LinearProgress variant="determinate"
                                sx={{ height: 15, width: '100%', margin: '0 auto', borderRadius: 10 }}
                                value={availableContactRequestsPercetnage} />



            </Paper>
            {positionsWithOffers.length > 0 && (
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="body1" sx={{mb: 3, fontWeight: 700}}>You have sent job offers</Typography>

                    {positionsWithOffers.map((position, index) => {
                        const isActive = activePosition ? position.id === activePosition?.id : index === 0;

                        const margin = index === positions.length - 1 ? '0rem' : '1rem';
                        const color = isActive ? '#809252' : '#000000';
                        const fontWeight = isActive ? 'bold' : 'normal';

                        return (
                            <Typography
                                typography="body1"
                                key={index}
                                sx={{
                                    marginBottom: margin, color, cursor: 'pointer', fontWeight, textTransform: 'capitalize',
                                }}
                                onClick={() => setActivePosition(position)}
                            >
                                {position.name} {isJobPositionManagedByJobling(position.id) ? '(managed by Jobling)' : ''}
                            </Typography>
                        );
                    })}
                </Paper>
            )}

            {positionsWithoutOffers.length > 0 && (
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="body1" sx={{mb: 3, fontWeight: 700}}>You haven't sent any job offers yet</Typography>

                    {positionsWithoutOffers.map((position, index) => {
                        const isActive = activePosition ? position.id === activePosition?.id : index === 0;

                        const margin = index === positions.length - 1 ? '0rem' : '1rem';
                        const color = isActive ? '#809252' : '#000000';
                        const fontWeight = isActive ? 'bold' : 'normal';

                        return (
                            <Typography
                                typography="body1"
                                key={index}
                                sx={{
                                    marginBottom: margin, color, cursor: 'pointer', fontWeight, textTransform: 'capitalize',
                                }}
                                onClick={() => setActivePosition(position)}
                            >
                                {position.name} {isJobPositionManagedByJobling(position.id) ? '(managed by Jobling)' : ''}
                            </Typography>
                        );
                    })}
                </Paper>
            )}

        </Stack>
    );
}

export default CompanyOfferStatusLeft;
