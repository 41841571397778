import React from 'react';
import useApi, {BASE_URL} from './useApi';

const useAuditApi = () => {
    const {doFetch} = useApi();
    const DOMAIN_URL = `${BASE_URL}audit`;

    const getAllStats = () => {
        const url = `${DOMAIN_URL}/all`;
        return doFetch(url);
    };

    const getUserStats = () => {
        const url = `${DOMAIN_URL}/user`;
        return doFetch(url);
    };

    return {getAllStats, getUserStats};
};

export default useAuditApi;
