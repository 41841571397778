import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";

interface Props {
    showDialog: boolean
    cancelNavigation: any
    confirmNavigation: any
    message?: string
}


function UnsavedConfirmationDialog(props: Props) {

    return (
        <Dialog
            open={props.showDialog}
        >
            <DialogTitle>
                Unsaved changes
            </DialogTitle>
            <DialogContent>

                <Typography>{props.message ? props.message : "You have unsaved changes, are you sure you want to leave this page?"}</Typography>

            </DialogContent>
            <DialogActions>
                <Button sx={{color: 'palette.primary', fontSize: '1rem', fontWeight: '600'}}
                        onClick={props.cancelNavigation}>NO</Button>
                <Button sx={{color: 'palette.secondary', fontSize: '1rem', fontWeight: '600'}}
                        onClick={props.confirmNavigation}>YES</Button>

            </DialogActions>
        </Dialog>
    );
}

export default UnsavedConfirmationDialog;
