import React, {useState} from 'react';
import {Box, Button, Grid, Link, TextField, ThemeProvider, Typography} from '@mui/material';
import LoginImage from "../../assets/LoginImage";
import JoblingLogo from "../../assets/JoblingLogo";
import {getAuth, signInWithEmailAndPassword} from "firebase/auth";
import {tomatoTheme} from "../../components/theme";
import {toast} from "react-toastify";
import {useParams} from "react-router-dom";
import {isValidEmail} from "../../util/formatUtil";


function EmailConfirmLogin() {
    const [loginEmail, setLoginEmail] = useState('');
    const [password, setPassWord] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const {email} = useParams();

    const isFormValid = () => {
        const errors = [];
        if (!isValidEmail(loginEmail)) {
            errors.push('email');
        }
        if (password === undefined || password?.trim().length === 0) {
            errors.push('password');
        }
        setErrorFields(errors);
        return errors.length <= 0;
    }


    const onEmailSubmit = async () => {
        if (isFormValid()) {
            signInWithEmailAndPassword(getAuth(), loginEmail, password)
                .then((userCredential) => {
                    // Signed in
                    const user = userCredential.user;
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found') {
                        toast.error('User not found!')
                    } else if (error.code === 'auth/wrong-password') {
                        toast.error('Wrong password!')
                    } else {
                        console.log(error.code)
                        const errorMessage = error.message;
                        toast.error(errorMessage)
                    }

                });
        }
    };
    const handleKeyDown = async (field: string, key: string) => {
        if (key === 'Enter') {
            await onEmailSubmit();
        }
    }

    const renderPrivacyPolicyLink = () => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mt: 2}}>
                <Typography sx={{mr: 0.5}}>
                    By continuing you agree to our <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}}
                    href="/terms-and-conditions" target="_blank" rel="noopener">Terms and conditions</Link> and <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}} href="/privacy-policy"
                    target="_blank" rel="noopener">Privacy policy</Link>
                </Typography>
            </Box>
        )
    }
    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <JoblingLogo/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ThemeProvider theme={tomatoTheme}>

                        <Box sx={{maxWidth: '25rem', pl: 1, pr: 1, width: '100%'}}>

                            {email && <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                Your password was sent to {email}
                            </Box>
                            }

                            <Box sx={{
                                border: '1px solid #FFA149',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                <TextField
                                    error={errorFields.includes("email")}
                                    helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                                    variant="outlined"
                                    label={"E-mail"}
                                    value={loginEmail}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown('email', e.key)}
                                    onChange={(e) => setLoginEmail(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>
                                <TextField
                                    error={errorFields.includes("password")}
                                    helperText={!errorFields.includes("password") ? "" : "Missing password"}
                                    type={"password"}
                                    variant="outlined"
                                    label={"Password"}
                                    value={password}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown('email', e.key)}
                                    onChange={(e) => setPassWord(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",

                                    }}/>

                                <Button
                                    id="login-button"
                                    sx={{
                                        border: '1px solid #FFA149',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '3rem',
                                        borderRadius: '0.5rem',
                                        mb: 2
                                    }}
                                    onClick={() => onEmailSubmit()}
                                >
                                    <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Log
                                        in </Typography>
                                </Button>
                            </Box>

                            {renderPrivacyPolicyLink()}
                        </Box>
                    </ThemeProvider>

                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    );
}

export default EmailConfirmLogin;
