import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box,
    Modal,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {IOfferedStudent} from '../../../models/IOfferedStudent';
import {IUserProfile, OfferStatus} from '../../../models/IUserProfile';
import DoneIcon from '../../../assets/DoneIcon';
import {styles} from '../../../components/theme';
import {formatProfileLabel} from "../../../util/formatUtil";
import InfoIcon from "@mui/icons-material/InfoSharp";
import CloseIcon from "@mui/icons-material/Close";
import CompanyProfileSelectMiddle from "../Positions/Select/CompanyProfileSelectMiddle";
import useUserProfileApi from "../../../api/useUserProfileApi";

const COLUMNS = [
    {name: 'Profile', width: '5rem'},
    {name: 'Name', width: '8rem'},
    {name: 'Email', width: '11rem'},
    {name: 'Phone', width: '7rem'},
    {name: '', width: '2rem'},
];


const style = {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'start',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    overflow: "scroll",
    height: "100%"
};

function CompanyOfferStatusTable({
                                   offers,
                                   status,
                                   toggleStudentContacted,
                               }: {
    offers: IOfferedStudent[],
    status: OfferStatus,
    toggleStudentContacted?: any
}) {
    const {t} = useTranslation();
    const {getUserProfile} = useUserProfileApi();


    const [profilePopupOpen, setProfilePopupOpen] = useState(false)
    const [previewProfile, setPreviewProfile] = useState<IUserProfile>()


    const renderIcon = (offer: IOfferedStudent) => {
        if (status === OfferStatus.ACCEPTED) {
            const color = offer.studentContacted ? '#02B033' : '#D3D3D3';
            return <Box sx={{cursor: 'pointer'}} onClick={() => toggleStudentContacted(offer)}><DoneIcon fill={color}/></Box>;
        }
        return <></>;
    };
    if (offers.length === 0) {
        return <></>;
    }

    const renderContent = () => (
        <Paper
            elevation={3}
            sx={{
                ...styles.paper,
                padding: '0.5rem',
                width: '150%',
                marginTop: '-0.25rem',
                marginBottom: '-0.25rem',
                marginLeft: '-0.6rem',
            }}
        >
            <Typography sx={{
                fontSize: '0.75rem',
                letterSpacing: '.2px',
                fontWeight: '400',
            }}
            >
                For privacy and avoiding-bias purposes we only show the name and contact information of the person after
                they have accepted your contact request
            </Typography>

        </Paper>
    );

    const openProfilePreview = (item: IOfferedStudent) => {

        getUserProfile(item.studentId).then(profile => {
            setPreviewProfile(profile);
            setProfilePopupOpen(true)
        })
    };

    return (
        <>
            <Modal open={profilePopupOpen} onClose={() => setProfilePopupOpen(false)}>
                <Stack sx={style}>

                    <CloseIcon onClick={() => setProfilePopupOpen(false)} sx={{
                        cursor: 'pointer', top: "10px", left: "calc(100% - 35px)", position: "sticky", zIndex: 2
                    }}/>
                    <Box sx={{
                        top: "-25px",
                        display: 'flex',
                        position: "relative",
                        justifyContent: 'center',
                        backgroundColor: "#F7F7F7",
                        paddingBottom: "3rem"
                    }}>
                        {previewProfile &&
                            <CompanyProfileSelectMiddle activeProfile={previewProfile} changeStatus={null}/>}
                    </Box>
                </Stack>
            </Modal>
            <Paper elevation={0} sx={styles.paper}>
                <Box sx={{display: "flex"}}>
                    <Typography typography="h4">{t(`offerStatus.${status}`)}</Typography>
                    {status === OfferStatus.PENDING && (
                        <Tooltip title={renderContent()} placement="right">
                            <InfoIcon sx={{
                                fontSize: '1rem', color: '#FFA049', marginLeft: '0.5rem', cursor: 'pointer',
                            }}
                            />
                        </Tooltip>
                    )}
                </Box>


                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {COLUMNS.map((column, index) => (
                                    <TableCell key={index} sx={{width: column.width}}>{column.name}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {offers.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{
                                        cursor: "pointer",
                                        fontWeight: 500,
                                        color: '#FFA149'
                                    }}
                                               onClick={() => openProfilePreview(item)}>
                                        {formatProfileLabel(item.accountNumber)}
                                    </TableCell>
                                    <TableCell>{item.fullName}</TableCell>
                                    <TableCell>{item.email}</TableCell>
                                    <TableCell>{item.phone}</TableCell>
                                    <TableCell>{renderIcon(item)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    );
}

export default CompanyOfferStatusTable;
