import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Button, Grid, Stack, ThemeProvider, Typography} from "@mui/material";
import {IQuestion} from "../../../models/IQuestion";
import {DragDropContext, Draggable, DragUpdate, Droppable} from "react-beautiful-dnd";
import {tomatoTheme} from "../../../components/theme";
import {useAtomValue} from "jotai";
import {userAtom} from "../../../store/userStore";
import useTestApi from "../../../api/useTestApi";
import {IAnswer} from "../../../models/IAnswer";
import TestProgressBar from "./TestProgressBar";


const BACKGROUND = [
    '#AEBD89', '#C0CCA4', '#D2DABE', '#E4EAD7', '#EDF0E5', '#EBEBEB', '#E0E0E0', '#CCCCCC', '#B8B8B8', '#A3A3A3'
]

const getItemStyle = function (index: number, isDragging: boolean, isSelected: boolean, draggableStyle: any) {
    return {
        // some basic styles to make the items look a bit nicer
        backgroundColor: isSelected ? "white" : "transparent",
        userSelect: "none",
        display: 'flex',
        height: {xs: '1.425rem', sm: '1.925rem'},
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: {xs: '0.775rem', sm: '1rem'},
        opacity: isSelected ? 1 : 0.4,
        marginBottom: {xs: "0.55rem", sm: "0.70rem"},
        borderRadius: '4px',
        width: {
            xs: 'calc(100% - 0.4rem)',
            lg: 'calc(50% - 0.4rem)'
        },
        ...draggableStyle
    };
};

const getListStyle = () => ({
    borderRadius: '0.5rem',
    minHeight: {xs: '22rem', sm: '29rem'},
    height: {xs: '22rem', sm: '29rem'},
    alignItems: 'center',
    width: '100%',
    position: "relative",
});

function getChipStyle() {
    return {
        whiteSpace: 'nowrap',
        m: {xs: '0.3rem', sm: '0.4rem'},
        p: {xs: '0.3rem', sm: '0.4rem'},
        cursor: 'pointer',
        border: '1px solid #D8D8D8',
        height: {xs: '1.425rem', sm: '1.925rem'},
        fontSize: {xs: '0.75rem', sm: '1rem'},
    }
}


interface Props {
    questions: IQuestion[];
    successfulSubmit: () => void;
    handleBack: () => void;
}

interface ICompetence {
    id: string;
    label: string | undefined;
}

const BLANK_ID = "_blank";


function Competencies({questions, successfulSubmit, handleBack}: Props) {

    const user = useAtomValue(userAtom)
    const [hoverIndex, setHoverIndex] = useState<number>(-1)

    const [currentActiveStep, setCurrentActiveStep] = useState<number>(0)
    const [selectedCompetenceToIndex, setSelectedCompetenceToIndex] = useState(new Map<number, number>());
    const [isNextDisabled, setIsNextDisabled] = useState(true);
    const [selectedCompetences, setSelectedCompetences] = useState<ICompetence[]>([]);
    const [notSelectedCompetences, setNotSelectedCompetences] = useState<ICompetence[]>([]);
    const {upsertAnswers} = useTestApi();

    const getGhostStyle = function (index: number) {
        return {
            opacity: index === hoverIndex ? 0.5 : 1,
            backgroundColor: BACKGROUND[index],
            userSelect: "none",
            display: 'flex',
            height: {xs: '1.825rem', sm: '2.325rem'},
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: {xs: '0.675rem', sm: '1rem'},
            position: "relative",
            marginBottom: {xs: "0.15rem", sm: "0.3rem"},
            top: "-0.25rem",
            width: {xs: '100%', lg: '50%'},
        }
    }

    useEffect(() => {
        if (currentActiveStep >= 2) {
            submitTestIfCompleted(selectedCompetenceToIndex)
        }

        const competenceList = Array.from(questions)
            .splice((currentActiveStep) * 6, 6)
            .map(e => ({
                id: e.id!!.toString(),
                label: e.text
            }))


        let selectedCompetences: { id: string; label: string | undefined }[] = Array.apply(null, Array(10)).map(() => {
            return {
                id: BLANK_ID + Math.floor(100000000 + Math.random() * 900000000),
                label: undefined,
            }
        });

        const selectedCompetenceIds = Array.from(selectedCompetenceToIndex.keys())
        selectedCompetenceToIndex.forEach((index, competenceId) => {
            selectedCompetences[index] = competenceList.find(e => Number(e.id) === competenceId) || selectedCompetences[index]
        });

        let notSelectedCompetences = competenceList.filter(e => !selectedCompetenceIds.includes(Number(e.id)));

        setNotSelectedCompetences(notSelectedCompetences)
        setSelectedCompetences(selectedCompetences)
    }, [currentActiveStep, questions])


    useEffect(() => {
        const hasNotOrderedCompetences = notSelectedCompetences.length > 0
        setIsNextDisabled(hasNotOrderedCompetences)
    }, [notSelectedCompetences]);

    // @ts-ignore
    const onDragEnd = (result) => {
        setHoverIndex(-1)
        if (result.reason === "DROP") {
            if (!result.destination) {
                return;
            }

            const from = result.source.droppableId
            const to = result.destination.droppableId
            const fromIndex = result.source.index
            const toIndex = result.destination.index

            let fromCompetenceList
            let toCompetenceList

            if (from === 'items') {
                fromCompetenceList = notSelectedCompetences
            } else {
                fromCompetenceList = selectedCompetences
            }
            if (to === 'items') {
                toCompetenceList = notSelectedCompetences
            } else {
                toCompetenceList = selectedCompetences
            }

            // If dragging to selected column and over a blank slot, then remove the blank
            if (from === 'items' && to === 'itemsSelected' && toCompetenceList[toIndex].id.startsWith(BLANK_ID)) {
                toCompetenceList.splice(toIndex, 1);
            }

            // Normal dragging logic to swap elements
            const [removed] = fromCompetenceList.splice(fromIndex, 1);
            toCompetenceList.splice(toIndex, 0, removed);

            // If dragging to not selected column, then add back a blank slot
            if (from === 'itemsSelected' && to === 'items') {
                fromCompetenceList.splice(fromIndex, 0, {
                    id: BLANK_ID + Math.floor(100000000 + Math.random() * 900000000),
                    label: undefined,
                });
            }

            // If dragging to selected column and over a already selected item, then remove the next blank slot. So it never goes over 10 elements
            if (from === 'items' && to === 'itemsSelected' && toCompetenceList.length > 10) {
                let nextBlankElementIndex = toCompetenceList.findIndex((element: ICompetence) => element.id.startsWith(BLANK_ID));
                toCompetenceList.splice(nextBlankElementIndex, 1);
            }

            if (from === 'items') {
                setNotSelectedCompetences(fromCompetenceList)
            } else {
                setSelectedCompetences(fromCompetenceList)
            }
            if (to === 'items') {
                setNotSelectedCompetences(toCompetenceList)
            } else {
                setSelectedCompetences(toCompetenceList)
            }

            if (from === 'items' && to === 'itemsSelected') {
                const hasNotOrderedCompetences = fromCompetenceList.length > 0
                setIsNextDisabled(hasNotOrderedCompetences)
            }
        }
    };

    const handleNextButtonAction = async () => {
        const competenceToIndex = new Map(selectedCompetenceToIndex)
        selectedCompetences.forEach((competence, index) => {
            if (!competence.id.startsWith(BLANK_ID)) {
                competenceToIndex.set(Number(competence.id), index)
            }
        })
        setSelectedCompetenceToIndex(competenceToIndex)

        if (currentActiveStep + 1 >= 2) {
            await submitTestIfCompleted(competenceToIndex)
        } else {
            setCurrentActiveStep((previousValue) => previousValue + 1)
        }
    }

    const submitTestIfCompleted = async (competenceToIndex: Map<number, number>) => {
        const answers: IAnswer[] = []

        competenceToIndex.forEach((index, competenceId) => {
            answers.push({
                userId: user?.id!!,
                questionId: competenceId,
                value: 100 - index * 10
            })
        });

        await upsertAnswers(answers)
        await successfulSubmit()

    };


    function onDragUpdate(initial: DragUpdate) {
        if (initial.destination?.droppableId === "itemsSelected" && initial.destination?.index !== undefined) {
            setHoverIndex(initial.destination?.index)
        } else {
            setHoverIndex(-1)
        }
    }

    return (
        <ThemeProvider theme={tomatoTheme}>
            <Box sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                // 'calc(100vh - 5rem)'
                p: 2
            }}>
                <TestProgressBar activeStep={currentActiveStep} type={'COMPETENCE'}/>

                <Box sx={{textAlign: 'center', maxWidth: '40rem', width: {xs: '100%', lg: '40rem'}}}>

                    <Box textAlign="center" sx={{mb: 1, mt: {xs: 1, sm: 0}}}>
                        <Typography typography="h3" sx={{mb: 1}}>Highlight the competencies</Typography>
                        <Typography typography="body1">Drag and drop competencies based on your confidence in each
                            area</Typography>
                    </Box>

                    <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
                        <Grid container justifyContent="center" alignItems={"center"} spacing={2}>
                            <Grid item xs={12} sx={{
                                position: "relative",
                            }}>
                                <Droppable droppableId="items" type="COMPETENCES" isDropDisabled={true}
                                           mode={"standard"} direction="vertical"
                                >
                                    {(provided) => {
                                        return (
                                            <Box
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                sx={{
                                                    flexWrap: "wrap",
                                                    display: "flex",
                                                    overflow: "hidden",
                                                    minHeight: notSelectedCompetences.length === 0 ? "0" : "6rem",
                                                    transition: "min-height 2.15s ease-out"

                                                }}
                                            >
                                                {notSelectedCompetences.map((item: ICompetence, index: number) => {

                                                    if (item.label === undefined) {
                                                        return (
                                                            <></>
                                                        );
                                                    }
                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {
                                                                return (
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={
                                                                            getChipStyle()
                                                                        }
                                                                    >
                                                                        {item.label}
                                                                    </Box>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })}
                                            </Box>
                                        );
                                    }}
                                </Droppable>
                            </Grid>

                            <Grid item xs={12} sx={{position: "relative"}}>

                                <Stack
                                    sx={{
                                        alignItems: 'center',
                                        width: 'calc(100% - 16px)',
                                        position: "absolute",
                                        top: 17,
                                        left: 16
                                    }}
                                >
                                    <Box sx={{...getGhostStyle(0), color: "#809252"}}>Most like me</Box>
                                    <Box sx={getGhostStyle(1)}/>
                                    <Box sx={getGhostStyle(2)}/>
                                    <Box sx={getGhostStyle(3)}/>
                                    <Box sx={{...getGhostStyle(4), color: "#7A7A7A"}}>Neutral</Box>
                                    <Box sx={getGhostStyle(5)}/>
                                    <Box sx={getGhostStyle(6)}/>
                                    <Box sx={getGhostStyle(7)}/>
                                    <Box sx={getGhostStyle(8)}/>
                                    <Box sx={{...getGhostStyle(9), color: "#7A7A7A"}}>Least like me</Box>
                                </Stack>
                                <Droppable droppableId="itemsSelected" type="COMPETENCES">
                                    {(provided) => {
                                        return (
                                            <Stack
                                                sx={
                                                    getListStyle()
                                                }
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                {selectedCompetences.map((item: ICompetence, index: number) => {


                                                    return (
                                                        <Draggable
                                                            key={item.id}
                                                            draggableId={item.id}
                                                            index={index}
                                                        >
                                                            {(provided, snapshot) => {


                                                                return (
                                                                    <Box
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        sx={getItemStyle(
                                                                            index,
                                                                            snapshot.isDragging,
                                                                            !item.id.startsWith("_blank"),
                                                                            provided.draggableProps.style,
                                                                        )}

                                                                    >
                                                                        {item.label}
                                                                    </Box>
                                                                );
                                                            }}
                                                        </Draggable>
                                                    );
                                                })} <Box
                                                sx={{
                                                    width: {
                                                        xs: 'calc(100% - 0.4rem)',
                                                        lg: 'calc(50% - 0.4rem)'
                                                    }
                                                }}

                                            >
                                                {provided.placeholder}
                                            </Box>
                                            </Stack>
                                        );
                                    }}
                                </Droppable>
                            </Grid>
                        </Grid>
                    </DragDropContext>

                    <Box sx={{
                        position: "relative",
                        display: 'flex',
                        justifyContent: 'center',
                        mt: "0rem"
                    }}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            //disabled={currentActiveStep === 0}
                            onClick={handleBack}
                            sx={{height: '3rem'}}
                        >
                            Back
                        </Button>

                        {!isNextDisabled && (
                            <Button variant="contained"
                                    sx={{ml: 2}}
                                    onClick={() => handleNextButtonAction()}
                                    disabled={isNextDisabled}>
                                {currentActiveStep === 1 ? 'Finish' : 'Next'}
                            </Button>
                        )}
                    </Box>
                </Box>


            </Box>
        </ThemeProvider>
    )
        ;
}

export default Competencies;
