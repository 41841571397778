import Button from '@mui/material/Button'
import {styled} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box, {BoxProps} from '@mui/material/Box'
import {IProductPackage} from "../../../models/IProductPackage";
import {Divider, Grid, Stack} from "@mui/material";
import React from "react";

const BoxFeature = styled(Box)<BoxProps>(({theme}) => ({
    marginBottom: theme.spacing(5),
    '& > :not(:first-of-type)': {
        marginTop: theme.spacing(4)
    }
}))

const ProductPackageContainer = ({productPackage, children, onCancel, proceedText, onProceed, sidebarMode = false}: {
    productPackage: IProductPackage,
    children: JSX.Element,
    onCancel: () => void,
    proceedText: string
    onProceed: () => void,
    sidebarMode?: boolean,
}) => {



    return (
        <Grid container sx={{minWidth: sidebarMode ? undefined: '350px',
            height: sidebarMode ? undefined: '100%', alignContent: sidebarMode ? undefined: "space-between", padding: sidebarMode ? 1 : 2}}>
            <Grid item sx={{textAlign: 'center'}} xs={12}>
                <Typography variant={sidebarMode ? 'h6' : 'h1'} sx={{mb: 2.5, mt: sidebarMode ? 6 : 2.5, whiteSpace: 'nowrap', color: 'primary.main',}}>
                    {productPackage?.name}
                </Typography>
                {/*<Typography variant='body2'>{data?.subtitle}</Typography>*/}
                <Box sx={{my: 1, position: 'relative'}}>
                    <Box sx={{display: 'flex', justifyContent: 'center'}}>
                        <Typography variant='h3' sx={{fontWeight: 600, lineHeight: 1.17, fontSize: sidebarMode ? '1.5rem' : '2rem', mb:4}}>
                            {productPackage?.price} €
                        </Typography>
                    </Box>
                    <Divider></Divider>
                </Box>
                <BoxFeature>{children}</BoxFeature>
            </Grid>
            <Grid item xs={12}>
                <Stack direction='row'>
                    {!sidebarMode && <Button
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>}
                    <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        sx={{ml: 2}}
                        onClick={onProceed}
                    >
                        {proceedText}
                    </Button>
                </Stack>
            </Grid>

        </Grid>
    )
}

export default ProductPackageContainer
