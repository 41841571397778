import React, {ChangeEvent, useEffect, useState} from 'react';
import {Box, Button, Paper, Stack, TextareaAutosize, TextField, Typography,} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {styles} from '../../../../components/theme';
import ActionButton, {ActionButtonType} from '../../../../components/ActionButton';
import useJobPositionApi from '../../../../api/useJobPositionApi';
import useFileApi from "../../../../api/useFileApi";
import {toast} from "react-toastify";
import CloseIcon from "@mui/icons-material/Close";
import {IFilteredStudent} from "../../../../models/IFilteredStudent";

function CompanyOfferMiddle({students}: { students: IFilteredStudent[] }) {
    const {id} = useParams();
    const navigate = useNavigate();

    const {getPosition, sendOffer} = useJobPositionApi();
    const [file, setFile] = useState<File | null>(null);
    const {uploadFile} = useFileApi();

    const [subject, setSubject] = useState<string>('');
    const [content, setContent] = useState<string>('');
    const fileInput = React.createRef<HTMLInputElement>()
    const [errorFields, setErrorFields] = useState<string[]>([]);

    useEffect(() => {
        if (id) {
            loadPosition();
        }
    }, [id]);

    const isFormValid = () => {
        const errors = [];
        if (subject === undefined || subject.trim().length === 0) {
            errors.push('subject');
        }
        if (content === undefined || content.trim().length === 0) {
            errors.push('content');
        }
        if (file && file.size / 1024 / 1024 > 2) {
            errors.push('file');
        }
        setErrorFields(errors);
        return errors.length <= 0;

    };

    const submitContactForm = async () => {
        if (!students || students.length === 0) {
            toast.error('There are no accepted profiles connected to this position!');
            return;
        }
        if (isFormValid()) {
            if (file) {
                const formData = new FormData();
                formData.append('file', file);
                if (id) {
                    formData.append('jobPositionId', id);
                }

                uploadFile(formData).then((fileId: number | null) => {
                    console.log("fileId", fileId)
                    sendEmail({subject, content}, fileId)
                }).catch((e) => {
                    console.log(e)
                    toast.error('File upload failed!');
                });
            } else {
                sendEmail({subject, content})
            }
        }
    }

    const loadPosition = async () => {
        if (id) {
            const pos = await getPosition(parseInt(id));
            if (pos?.defaultSubject) {
                setSubject((prev) => pos.defaultSubject);
                setContent((prev) => pos.defaultContent);
            }
        }
    };

    const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length === 1) {

            const f = event.target.files[0]
            if (f.size / 1024 / 1024 > 2) {
                setErrorFields([...errorFields, "file"])
            } else {
                errorFields.splice(errorFields.indexOf("file"), 1);
            }
            setFile(f)
        }

    };

    const upload = () => {
        fileInput.current?.click()
    };

    const removeFile = () => {
        setFile(null);
        //remove "file
        errorFields.splice(errorFields.indexOf("file"), 1);
    };

    const sendEmail = (form: any, fileId?: number | null | undefined) => {

        if (form.subject && form.content) {
            if (id) {
                sendOffer(parseInt(id), fileId, form.subject, form.content)
                    .then((r) => {
                        toast.success('Offer sent!');
                        navigate('/offer-status');
                    })
                    .catch((e) => {
                        console.log(e);
                        toast.error('Failed to send offer');
                    });
            }
        } else {
            toast.error('Please fill message subject and content!');
        }
    };

    return (
        <Stack sx={styles.middleStack}>
            <Paper elevation={0} sx={styles.paper}>
                <Typography typography="h4">Send message to all selected candidates</Typography>
                <Typography>
                    The message will be sent out to all candidates selected from the previous step. The full list of who
                    will receive the message is visible on the left.
                </Typography>
                <TextField
                    variant="standard"
                    placeholder="Subject"
                    key="subject"
                    fullWidth
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{marginTop: '1rem'}}
                />
                {errorFields.includes('subject') && <Typography color={"error"} sx={{marginLeft: "0.5rem"}}>
                    Subject is required!
                </Typography>
                }
                <TextareaAutosize
                    placeholder="Please write your personal message to the candidates here. Feel free to add a link to your company web and/or job offer description. Based on this the recipients decide whether to accept or reject further meetings with you."
                    minRows={10}
                    style={{width: '100%'}}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                {errorFields.includes('content') && <Typography color={"error"} sx={{marginLeft: "0.5rem"}}>
                    Content is required!
                </Typography>
                }
                <Stack sx={{marginTop: "0.5rem"}}>
                    <input ref={fileInput}
                           accept="*/*" id="contained-button-file" multiple type="file"
                           onChange={(e) => onFileChange(e)} style={{display: 'none'}}
                    />
                    <Stack direction="row" alignItems={"end"}>
                        <Button variant="outlined" component="span" sx={{
                            height: '1.875rem',
                            minWidth: '6.25rem',
                            fontSize: '0.875rem',
                        }} onClick={() => upload()}>
                            Choose file
                        </Button>
                        <Typography sx={{marginLeft: "0.5rem"}}>
                            {file?.name}
                            {file && <CloseIcon onClick={() => removeFile()} sx={{cursor: 'pointer'}}
                                                fontSize={"inherit"}/>}
                        </Typography>
                        {errorFields.includes('file') && <Typography color={"error"} sx={{marginLeft: "0.5rem"}}>
                            Maximum size is 2MB!
                        </Typography>
                        }

                    </Stack>
                </Stack>

                <Box width="100%" display="flex" marginTop="1rem">

                    <Box sx={{flexGrow: 1}}/>
                    <ActionButton
                        label="Send"
                        type={ActionButtonType.SUBMIT}
                        onClick={() => submitContactForm()}
                    />
                </Box>

            </Paper>

        </Stack>
    );
}

export default CompanyOfferMiddle;
