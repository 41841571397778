import React from 'react';
import {useUpdateAtom} from 'jotai/utils';
import {profileAtom, profileSubmittedAtom, profileVisibleAtom} from '../store/userStore';
import {IUserProfile} from '../models/IUserProfile';
import useUserProfileApi from '../api/useUserProfileApi';

const useProfile = () => {
    const setProfile = useUpdateAtom(profileAtom);
    const setProfileVisible = useUpdateAtom(profileVisibleAtom);
    const setProfileSubmitted = useUpdateAtom(profileSubmittedAtom);
    const {getMyProfile} = useUserProfileApi();

    const loadProfile = async () => {
        const profile: IUserProfile = await getMyProfile();
        setProfileVisible(profile.profileVisible);
        setProfileSubmitted(profile.profileSubmitted);
        setProfile(profile);
    };

    return {loadProfile};
};

export default useProfile;
