import {Box, Button, Dialog, Paper, Stack, TextField, Typography,} from '@mui/material';
import React, {useState} from 'react';
import ActionButton, {ActionButtonType} from "../../../components/ActionButton";
import {styles} from "../../../components/theme";
import {isValidEmail} from "../../../util/formatUtil";

function AddHrUserModal({
                            open,
                            handleClose,
                            addUser
                        }: { open: boolean; handleClose: () => void; addUser: any }) {

    const [errorFields, setErrorFields] = useState<string[]>([]);
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');


    const isFormValid = () => {
        const errors = [];
        if (!isValidEmail(email)) {
            errors.push('email');
        }
        setErrorFields(errors);
        return errors.length <= 0;
    }

    function handleCreate() {
        if (isFormValid()) {
            console.log("create");

        }
        const newUser = {
            fullName: name,
            email: email,
        };
        addUser(newUser);
    }

    function handleKeyDown(key: string) {


        if (key === "Enter") {
            handleCreate();
        }
    }

    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <Stack sx={{width: '100%'}}>
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="h3">Add new user</Typography>

                    <TextField
                        variant="standard"
                        label={"Name"}
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)}
                        sx={{
                            borderRadius: '0.5rem',
                            marginBottom: "1rem",
                        }}/>

                    <TextField
                        error={errorFields.includes("email")}
                        helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                        variant="standard"
                        label={"E-mail"}
                        value={email}
                        fullWidth
                        onKeyDown={(e) => handleKeyDown(e.key)}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            borderRadius: '0.5rem',
                            marginBottom: "1rem",
                        }}/>

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box style={{flexGrow: 1}}/>
                        <Button
                            variant="text"
                            sx={{fontSize: '0.875rem', color: '#809252'}}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton
                            label="Create"
                            type={ActionButtonType.SUBMIT}
                            onClick={() => handleCreate()}
                        />
                    </Box>
                </Paper>
            </Stack>
        </Dialog>
    );
}

export default AddHrUserModal;
