import React, {useState} from 'react';
import {Box, Button, Grid, TextField, ThemeProvider, Typography} from '@mui/material';
import LoginImage from "../../assets/LoginImage";
import JoblingLogo from "../../assets/JoblingLogo";
import {getAuth, sendPasswordResetEmail} from "firebase/auth";
import {tomatoTheme} from "../../components/theme";
import {toast} from "react-toastify";
import {isValidEmail} from "../../util/formatUtil";
import {useNavigate} from "react-router";


function PasswordReset() {
    const [loginEmail, setLoginEmail] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);
    const navigate = useNavigate();
    const isFormValid = () => {
        const errors = [];
        if (!isValidEmail(loginEmail)) {
            errors.push('email');
        }

        setErrorFields(errors);
        return errors.length <= 0;
    }


    const onEmailSubmit = async () => {
        if (isFormValid()) {
            sendPasswordResetEmail(getAuth(), loginEmail, {url: 'https://app.jobling.ee/login'})
                .then(() => {
                    toast.info('Password reset e-mail sent!')
                    navigate('/login')
                })
                .catch((error) => {
                    if (error.code === 'auth/user-not-found') {
                        toast.error('User not found!')
                    }  else {
                        console.log(error.code)
                        const errorMessage = error.message;
                        toast.error(errorMessage)
                    }
                });
        }
    };
    const handleKeyDown = async (field: string, key: string) => {
        if (key === 'Enter') {
            await onEmailSubmit();
        }
    }

    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <JoblingLogo/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ThemeProvider theme={tomatoTheme}>

                        <Box sx={{
                            border: '1px solid #FFA149',
                            borderRadius: '0.5rem',
                            padding: "1rem",
                            backgroundColor: 'white',
                            marginBottom: "2rem",
                        }}>
                            <TextField
                                error={errorFields.includes("email")}
                                helperText={!errorFields.includes("email") ? "" : "Missing or invalid e-mail!"}
                                variant="outlined"
                                label={"E-mail"}
                                value={loginEmail}
                                fullWidth
                                onKeyDown={(e) => handleKeyDown('email', e.key)}
                                onChange={(e) => setLoginEmail(e.target.value)}
                                sx={{
                                    borderRadius: '0.5rem',
                                    marginBottom: "1rem",
                                }}/>
                            <Button
                                id="login-button"
                                sx={{
                                    border: '1px solid #FFA149',
                                    backgroundColor: 'white',
                                    width: '100%',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    height: '3rem',
                                    borderRadius: '0.5rem',
                                    mb: 2
                                }}
                                onClick={() => onEmailSubmit()}
                            >
                                <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Reset
                                    Password</Typography>

                            </Button>
                        </Box>

                    </ThemeProvider>

                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    )
        ;
}

export default PasswordReset;
