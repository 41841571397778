import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid, Paper, Stack, ThemeProvider, Typography} from '@mui/material';
import {useAtomValue} from 'jotai';
import StudentDashboardMiddle from './StudentDashboardMiddle';
import {profileVisibleAtom} from '../../../store/userStore';
import {styles, tomatoTheme} from "../../../components/theme";
import JoblingLayout from "../../JoblingLayout";
import ProfileVisibility from "./ProfileVisibility";
import StudentProfileInfo from "../../../components/student/StudentProfileInfo";

function StudentPreferences() {
    const profileVisible = useAtomValue(profileVisibleAtom);


    const renderStyles = () => {
        if (profileVisible) {
            return {...styles.paperCenter, border: '1px solid #D4DCD0', background: '#02B0330A'}
        }
        return {...styles.paperCenter, border: '1px solid #FBAF00', background: '#FFFDF8'}
    }
    return (
        <JoblingLayout>
            <ThemeProvider theme={tomatoTheme}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={10}>
                        <Typography typography="h2" sx={{mt: 3, mb: 1}}>Preferences</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={1}>
                            <Paper elevation={0} sx={styles.paperCenter}>
                                <StudentProfileInfo/>
                            </Paper>

                            <Paper elevation={0} sx={renderStyles()}>
                                <ProfileVisibility/>
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <StudentDashboardMiddle/>
                    </Grid>

                </Grid>
            </ThemeProvider>
        </JoblingLayout>
    );
}

export default StudentPreferences;
