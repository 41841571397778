import {Box, Button, Typography} from '@mui/material';
import React from 'react';
import DoneIcon from '../assets/DoneIcon';

const styles = {
    button: {
        width: '8.95rem',
        height: '1.875rem',
        borderColor: '#D3D3D3',
        borderRadius: '0.5rem',
        justifyContent: 'left',
        // margin: "2px"
        // marginRight: "0.25rem",
        // marginBottom: "0.25rem",
        paddingLeft: '0.5rem',
        textAlign: 'center',
    },
    icon: {
        color: '#D3D3D3',
    },
    label: {
        color: '#303030',
        fontSize: '0.8125rem',
        // lineHeight: '0.7rem',
        textTransform: 'capitalize',
        paddingLeft: '0.5rem',
        paddingTop: '0.15rem',
    },
};

function SelectionButton({
                             label,
                             selected = false,
                             onClick,
                             isLocked = false
                         }: { label: string; selected?: boolean; onClick: () => void; isLocked?: boolean;}) {
    return (
        <Button
            sx={{
                ...styles.button,
                borderColor: selected ? '#A2DA93' : '#D3D3D3',
                backgroundColor: selected ? '#F8FFFA' : 'transparent'
            }}
            variant="outlined"
            onClick={() => onClick()}
            disabled={isLocked}
        >
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <DoneIcon fill={selected ? '#A2DA93' : '#D3D3D3'}/>
                <Typography sx={styles.label}>{label}</Typography>
            </Box>

        </Button>
    );
}


export default SelectionButton;
