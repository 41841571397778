import React from 'react';

import {getAuth} from 'firebase/auth';
import {IException} from '../models/IException';

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

const useApi = () => {


    const doFetch = async (url: string, method = 'GET', body = {}, returnVoid = false) => {
        const token = await getAuth().currentUser?.getIdToken();
        const response = await fetch(url, {
            method,
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
            body: ['POST', 'PUT'].includes(method) ? JSON.stringify(body) : null,
        } as RequestInit);
        if (response.ok) {
            if (!returnVoid) {
                return response.json();
            }
        } else {
            const exception: IException = await response.json();
            // await handleEexception(response)
            const error = {message: exception.errCode, name: exception.exception} as Error;
            throw error;
        }
    };

    const doMultipart = async (url: string, body: FormData, returnValue = false) => {
        const token = await getAuth().currentUser?.getIdToken();
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body,
        } as RequestInit);
        if (!response.ok) {
            throw new Error(`Query error status ${response.status}`);
        } else if (returnValue) {
            return response.json();
        }
    };

    return {doFetch, doMultipart};
};

export default useApi;
