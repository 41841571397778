import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Box,
    Button,
    CircularProgress,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack
} from '@mui/material';
import useOrdersApi from "../../../api/useOrderApi";
import Typography from "@mui/material/Typography";
import {IOrderRequestPaymentMethod} from "../../../models/IOrderRequestPaymentMethod";
import Spinner from "../../../util/Spinner";


interface PaymentMethod {
    name: string;
    logoUrl: string;
    supportedCurrencies: string[];
    uiPosition: number;
    code: string;
}

interface CountryPaymentMethods {
    [country: string]: {
        supportedCurrencies: string[];
        paymentMethods: PaymentMethod[];
    };
}


interface PaymentMethods {
    paymentInitiation: {
        setup: CountryPaymentMethods;
    },
    cardPayments: {
        processor: string;
        logoUrl: string;
    }
}

const CARD_PAYMENTS = "cardPayments";

function PaymentMethodSelection({setPaymentMethod, onCancel, isSubmitting}: {
    setPaymentMethod: (method: IOrderRequestPaymentMethod) => void;
    isSubmitting: boolean;
    onCancel: () => void;
}) {
    const {getPaymentMethods} = useOrdersApi()
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethods | null>(null);
    const [selectedCountry, setSelectedCountry] = useState('EE');
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<IOrderRequestPaymentMethod | null>(null );

    useEffect(() => {
        fetchPaymentMethods();
    }, []);

    const fetchPaymentMethods = async () => {
        try {
            let paymentMethods = await getPaymentMethods();
            setPaymentMethods(paymentMethods.paymentMethods);
        } catch (error) {
            // Handle error
            console.error('Error fetching payment methods:', error);
        }
    };
    const onSubmit = async () => {
        setPaymentMethod(selectedPaymentMethod!);
    };

    const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedCountry(event.target.value as string);
    };

    const handleSelectingBankAsPayment = (paymentMethodCode: string) => {
        setSelectedPaymentMethod({
            method: "paymentInitiation",
            code: paymentMethodCode,
        })
    };

    const handleSelectingCreditCardAsPayment = () => {
        setSelectedPaymentMethod({
            method: CARD_PAYMENTS,
        })
    };


    return (
        <>
            <DialogContent style={{width: "700px"}}>
                {paymentMethods ?
                    <>

                        <Typography variant='h1' sx={{my: 2.5, whiteSpace: 'nowrap', color: 'primary.main',}}>
                            Select payment method
                        </Typography>

                        <Stack direction="row" spacing={2} sx={{mb: 2.5}} justifyContent={"space-between"}>

                        <Typography variant='h3' sx={{my: 2.5, whiteSpace: 'nowrap',}}>
                            Bank transfer
                        </Typography>
                        <FormControl style={{minWidth: 200}}>
                            <InputLabel id="country-select-label">Select Country</InputLabel>
                            {/* @ts-ignore */}
                            <Select labelId="country-select-label" value={selectedCountry} fullWidth onChange={handleCountryChange}>
                                {Object.keys(paymentMethods.paymentInitiation.setup).map((country) => (
                                    <MenuItem key={country} value={country}>{country}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Stack>

                        <Grid container spacing={2}>
                            {paymentMethods && paymentMethods.paymentInitiation.setup[selectedCountry]?.paymentMethods.map((method: any, index: number) => (
                                <Grid item xs={4} key={index}>
                                    <Box key={"inner" + index} style={{
                                        backgroundColor: selectedPaymentMethod?.code === method.code ? '#ffdcbb' : '#ededed82',
                                        border: selectedPaymentMethod?.code === method.code ? '1px solid #ff9837' : '1px solid #d5d5d5',
                                        height: '100px',
                                        borderRadius: '8px',
                                        padding: '20px',
                                        cursor: 'pointer',
                                    }}
                                         onClick={() => handleSelectingBankAsPayment(method.code)}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                backgroundImage: `url(${method.logoUrl})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'contain',
                                                backgroundPosition: 'center',
                                                height: '100%',
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        <Typography variant='h3' sx={{mb: 2.5, mt: 8, whiteSpace: 'nowrap',}}>
                            Credit card
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box style={{
                                    backgroundColor: selectedPaymentMethod?.method === CARD_PAYMENTS ? '#ffdcbb' : '#ededed82',
                                    border: selectedPaymentMethod?.method === CARD_PAYMENTS ? '1px solid #ff9837' : '1px solid #d5d5d5',
                                    height: '100px',
                                    borderRadius: '8px',
                                    padding: '18px',
                                    cursor: 'pointer',
                                }}
                                     onClick={() => handleSelectingCreditCardAsPayment()}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            // backgroundSize: '80%',
                                            backgroundImage: `url(${paymentMethods.cardPayments.logoUrl})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center',
                                            height: '100%',
                                            // marginLeft: '20px',
                                            // marginRight: '20px',
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>

                    </> :
                    <Spinner sx={{margin: "auto"}}/>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancel</Button>
                <Button
                    color='primary'
                    variant='contained'
                    type="submit"
                    disabled={isSubmitting || !selectedPaymentMethod}
                    onClick={onSubmit}
                    sx={{ml: 2}}
                >
                    {isSubmitting && <CircularProgress disableShrink size={16} sx={{mr: 1}}/>}
                    Start payment
                </Button>
            </DialogActions>
        </>
    )
        ;
}

export default PaymentMethodSelection;
