import React, {useState} from 'react';
import {Box, TextField, Typography} from '@mui/material';
import {useUpdateAtom} from "jotai/utils";
import {loginStepAtom} from "../../store/loginStore";

function VerificationCodeForm({confirmationResult, phone}: { confirmationResult: any; phone: string; }) {
    const [verificationCode, setVerificationCode] = useState('');
    const digits = ['', '', '', '', '', ''];
    const [focus, setFocus] = useState(0);
    const setLoginStep = useUpdateAtom(loginStepAtom);

    const onVerificationCodeChange = (index: number, code: string) => {
        const newVerificationCode = Array.from(verificationCode);
        newVerificationCode[index] = code;
        setFocus(index + 1);

        const newCode = newVerificationCode.join('');
        setVerificationCode(newCode);
        if (newCode && newCode.length === 6) {
            submitVerificationCode(newCode);
        }
    };

    const handleOnKeyUp = (index: number, key: string) => {
        if ('Backspace' === key) {
            setFocus(index - 1);
        }
    };

    const submitVerificationCode = (code: string) => {
        // TODO: show alerts
        confirmationResult?.confirm(code).then(() => {
            setLoginStep(0);
        }).catch((error: any) => {
            console.log(error)
            alert(`Error code: ${error.code}`);
        });
    };

    return (
        <>
            <Typography typography="h2">Enter your code</Typography>
            <Typography sx={{marginBottom: '2rem'}}>
                JOBLING has sent a verification code to {phone}
            </Typography>

            <Box sx={{display: 'flex'}}>
                {digits.map((item, index) => (
                    <TextField
                        key={index}
                        inputMode={"numeric"}
                        type={"number"}
                        sx={{
                            marginRight: '0.5rem',
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                                display: "none",
                            },
                            "& input[type=number]": {
                                MozAppearance: "textfield",
                            },
                        }}
                        inputProps={{style: {textAlign: 'center', width: '2rem'}}}
                        inputRef={(input) => input && focus === index && input.focus()}
                        // type="number"
                        variant="standard"
                        value={verificationCode[index]}
                        onKeyUp={(e) => handleOnKeyUp(index, e.key)}
                        onChange={(e) => onVerificationCodeChange(index, e.target.value)}
                    />
                ))}
            </Box>
        </>

    );
}

export default VerificationCodeForm;
