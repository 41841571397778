import React, {useEffect, useState} from 'react';
import {Box, Paper, Stack, Typography,} from '@mui/material';
import {useParams} from 'react-router-dom';
import {styles} from '../../../../components/theme';
import {FilterStatus, IUserProfile} from '../../../../models/IUserProfile';
import ActionButton, {ActionButtonType} from '../../../../components/ActionButton';
import useDomainValues from '../../../../hooks/useDomainValues';
import {DomainCode} from '../../../../models/IDomainValue';
import useFilterApi from '../../../../api/useFilterApi';
import {IStudentMismatchData} from '../../../../models/IStudentMismatchData';
import ChartDisplay from '../../../../components/ChartDisplay';
import RejectedLabel from '../../../../components/RejectedLabel';
import AcceptedLabel from '../../../../components/AcceptedLabel';
import StudentInformation from "../../../../components/shared/StudentInformation";
import StudentEducation from "../../../../components/shared/StudentEducation";
import SurveyResultsPopup from "../../../../components/SurveyResultsPopup";

function CompanyProfileSelectMiddle({
                                        activeProfile,
                                        changeStatus,
                                    }: { activeProfile: IUserProfile | null, changeStatus: any }) {
    const {findDomainValueStringByCode, findDomainValueByCode} = useDomainValues();
    const {getMismatchMetrixes} = useFilterApi();

    const {id} = useParams();
    const [mismatches, setMismatches] = useState<IStudentMismatchData[]>([]);

    useEffect(() => {
        if (activeProfile) {
            loadMismatches();
        }
    }, [activeProfile]);

    const loadMismatches = async () => {
        if (id && activeProfile) {
            const loadedMismatches = await getMismatchMetrixes(parseInt(id), activeProfile.id) as IStudentMismatchData[];
            if (loadedMismatches) {
                setMismatches(loadedMismatches);
            }
        }
    };


    return (
        <Stack sx={styles.middleStack} spacing={1}>
            {activeProfile
                ? (
                    <>
                        <Paper elevation={0} sx={styles.paper}>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Box sx={{display: 'flex', alignItems: 'baseline'}}>
                                    <Typography sx={{fontSize: '0.875rem', marginRight: '0.25rem', fontWeight: '400'}}>
                                        Profile ID
                                    </Typography>
                                    <Typography sx={{
                                        fontSize: '1.125rem',
                                        color: '#809252',
                                        fontWeight: '700'
                                    }}>{activeProfile.accountNumber}</Typography>
                                </Box>
                                {
                                    activeProfile?.filterStatus === FilterStatus.CONFIRMED
                                        ? (
                                            <Box sx={{marginTop: '-0.2rem', marginLeft: '0.5rem'}}>
                                                <AcceptedLabel/>
                                            </Box>
                                        ) : ''
                                }
                                {
                                    activeProfile?.filterStatus === FilterStatus.REJECTED
                                        ? (
                                            <Box sx={{marginTop: '-0.2rem', marginLeft: '0.5rem'}}>
                                                <RejectedLabel/>
                                            </Box>
                                        ) : ''
                                }

                                <Box sx={{flexGrow: 1}}/>
                                {changeStatus && <>
                                    {
                                        activeProfile?.filterStatus === FilterStatus.CONFIRMED ? ''

                                            : activeProfile?.filterStatus === FilterStatus.REJECTED
                                                ? (
                                                    <ActionButton
                                                        label="Move to accepted"
                                                        type={ActionButtonType.CONFIRM}
                                                        onClick={() => changeStatus(activeProfile, FilterStatus.CONFIRMED)}
                                                    />
                                                )
                                                : (
                                                    <ActionButton
                                                        label="Accept"
                                                        type={ActionButtonType.CONFIRM}
                                                        onClick={() => changeStatus(activeProfile, FilterStatus.CONFIRMED)}
                                                    />
                                                )
                                    }
                                    {
                                        activeProfile?.filterStatus === FilterStatus.REJECTED ? '' : (
                                            <ActionButton
                                                label="Reject"
                                                type={ActionButtonType.REJECT}
                                                onClick={() => changeStatus(activeProfile, FilterStatus.REJECTED)}
                                            />
                                        )
                                    }
                                </>}
                            </Box>
                        </Paper>

                        <Paper elevation={0} sx={styles.paper}>
                            <SurveyResultsPopup userId={activeProfile.id} accountNumber={activeProfile.accountNumber}/>
                        </Paper>


                        {
                            (activeProfile?.filterStatus === FilterStatus.PENDING && mismatches && mismatches.length > 0)
                                ? (
                                    <Box>
                                        <Typography typography="h4" sx={{marginLeft: "1.25rem"}}>Person filter
                                            mismatches</Typography>

                                        <Stack direction="row" spacing={1}>
                                            {mismatches.map((mismatch, i) => {
                                                const dv = findDomainValueByCode(DomainCode.METRIX, mismatch.metrix);
                                                return dv ? (
                                                    <Stack key={`s1_${i}`}>
                                                        <Paper elevation={0} sx={styles.paper} key={`p1_${i}`}>
                                                            <ChartDisplay
                                                                chartData={mismatch}
                                                                metrixDomain={dv}
                                                            />
                                                        </Paper>
                                                    </Stack>
                                                ) : '';
                                            })}
                                        </Stack>
                                    </Box>
                                ) : ''
                        }

                        <StudentEducation universities={activeProfile?.universities}/>

                        <Paper elevation={0} sx={styles.paper}>
                            <Typography typography="h4">Preferred location of work</Typography>
                            {
                                activeProfile?.location?.map((l, i) => (
                                    <Typography
                                        typography="body1"
                                        key={i}
                                    >
                                        {l === 'OTHER' ? 'Other - ' + activeProfile?.locationOther : findDomainValueStringByCode(DomainCode.LOCATION, l)}
                                    </Typography>
                                ))
                            }
                        </Paper>

                        <Paper elevation={0} sx={styles.paper}>
                            <Typography typography="h4">Preferred field of work</Typography>
                            {
                                activeProfile?.fieldOfWorking?.map((w, i) => (
                                    <Typography
                                        typography="body1"
                                        key={i}
                                    >
                                        {w === 'OTHER' ? 'Other - ' + activeProfile?.fieldOfWorkingOther : findDomainValueStringByCode(DomainCode.FIELD_OF_WORK, w)}
                                    </Typography>
                                ))
                            }
                        </Paper>

                        <StudentInformation description={activeProfile?.description!}/>

                        <Stack direction="row" spacing={1} key="row">
                            <Stack sx={{width: '50%'}} key="s1">
                                <Paper elevation={0} sx={{...styles.paper, height: '100%'}}>
                                    <Typography typography="h4">Employment type</Typography>
                                    {
                                        activeProfile?.waysOfWorking?.map((w, i) => (
                                            <Typography
                                                key={i}
                                                typography="body1"
                                            >
                                                {findDomainValueStringByCode(DomainCode.WORK_TYPE, w)}
                                            </Typography>
                                        ))
                                    }
                                </Paper>
                            </Stack>
                            <Stack sx={{width: '50%'}} key="s2">

                                <Paper elevation={0} sx={{...styles.paper, height: '100%'}}>
                                    <Typography typography="h4">Languages</Typography>
                                    {
                                        activeProfile?.languages?.map((w, i) => (
                                            <Typography
                                                typography="body1"
                                                key={i}
                                            >
                                                {findDomainValueStringByCode(DomainCode.LANGUAGE, w)}
                                            </Typography>
                                        ))
                                    }

                                </Paper>
                            </Stack>
                        </Stack>
                    </>
                )
                : (
                    <Paper elevation={0} sx={styles.paper}>
                        <Typography typography="h4">The filter returned no results!</Typography>
                        <Typography typography="body1">Try to widen the filter to find matches!</Typography>
                    </Paper>
                )}

        </Stack>
    );
}

export default CompanyProfileSelectMiddle;
