import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, Divider, Grid, Link, TextField, ThemeProvider, Typography} from '@mui/material';
import LoginImage from "../../assets/LoginImage";
import JoblingLogo from "../../assets/JoblingLogo";
import GoogleIcon from "../../assets/GoogleIcon";
import {beforeAuthStateChanged, getAuth, GoogleAuthProvider, OAuthCredential, signInWithPopup} from "firebase/auth";
import useUserApi from "../../api/useUserApi";
import {IUser, UserType} from "../../models/IUser";
import {tomatoTheme} from "../../components/theme";
import {toast} from "react-toastify";
import {useNavigate} from 'react-router';
import {isValidEmail} from "../../util/formatUtil";


function CreateAccount() {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [acceptTerms, setAcceptTerms] = useState(false);

    const [emailError, setEmailError] = useState<string>();

    const {getUserByEmail, createUser} = useUserApi()

    useEffect(() => {
        const unsubscribe = beforeAuthStateChanged(getAuth(), async (user) => {
            if (!user) {
                return;
            }

            try {
                await getUserByEmail(user.email!)
            } catch (e: any) {
                if (e.message === 'OBJECT_NOT_FOUND') {
                    const newUser = {
                        fullName: user.displayName ? user.displayName : '',
                        email: user.email,
                        citizenship: 'ESTONIAN',
                        accountType: UserType.STUDENT,
                        loginType: 'GOOGLE'
                    };
                    await createUser(newUser as IUser);
                }

            }
        });

        return unsubscribe;
    }, []);


    const onEmailSubmit = async () => {
        setEmailError(undefined);
        if (!isValidEmail(email)) {
            setEmailError('Valid e-mail address is required');
            return
        }
        if (!acceptTerms) {
            toast.info("Please accept the terms and conditions!")
            return;
        }

        const newUser = {
            fullName: '',
            email: email,
            citizenship: 'ESTONIAN',
            accountType: UserType.STUDENT,
            loginType: 'EMAIL'
        };
        const createdUser = await createUser(newUser as IUser)
        if (createdUser) {
            toast.info("Please check your e-mail " + email + " for further instructions!")
            navigate('/login/confirm/' + email);

        }
    };

    const googleHandler = async (e: any) => {

        e.preventDefault()

        // login || signup
        const provider = new GoogleAuthProvider();
        provider.setCustomParameters({
            prompt: 'select_account'
        })

        signInWithPopup(getAuth(), provider)
            .then(async (result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result) as OAuthCredential;
                const token = credential.accessToken;
                const user = result.user;
            }).catch((error) => {
            // Handle Errors here.
            // const errorCode = error.code;
            // const errorMessage = error.message;
            // The email of the user's account used.
            // const email = error.customData.email;
            // The AuthCredential type that was used.
            // const credential = GoogleAuthProvider.credentialFromError(error);
            console.log(error)
        });
    };

    const termsStyle = {
        color: '#809252',
    }

    const renderPrivacyPolicyLink = () => {
        return (
            <Box sx={{display: 'flex', alignItems: 'center', mb: 2}}>
                <Checkbox checked={acceptTerms} onChange={(e, checked) => setAcceptTerms(checked)} style={termsStyle} />
                <Typography sx={{mr: 0.5}}>
                    I agree with <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}}
                    href="/terms-and-conditions" target="_blank" rel="noopener">Terms and conditions</Link> and <Link
                    sx={{color: '#809252', textDecorationColor: '#809252', fontSize: '1rem'}} href="/privacy-policy"
                    target="_blank" rel="noopener">Privacy policy</Link>
                </Typography>
            </Box>
        )
    }

    function handleKeyDown(key: string) {
        if (key === 'Enter') {
            onEmailSubmit();
        }
    }

    return (
        <Grid container sx={{minHeight: '100vh'}}>
            <Grid item xs={12} md={6}>
                <Box sx={{position: 'absolute', mt: 4, ml: 4}}>
                    <JoblingLogo/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <ThemeProvider theme={tomatoTheme}>

                        <Typography typography="h2" sx={{mb: 8}}>For students</Typography>

                        <Box sx={{maxWidth: '25rem', pl: 1, pr: 1, width: '100%'}}>

                            <Button id={"google-login"} sx={{
                                border: '1px solid #FFA149',
                                backgroundColor: 'white',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '3rem',
                                borderRadius: '0.5rem',
                                mb: 3
                            }} onClick={googleHandler}>
                                <GoogleIcon/>
                                <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600, ml: 1}}>Create
                                    account
                                    with Google</Typography>
                            </Button>

                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider><Typography>or use</Typography></Divider>
                            </Box>

                            <Box sx={{
                                border: '1px solid #FFA149',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                <TextField
                                    error={!!emailError}
                                    helperText={emailError}
                                    variant="outlined"
                                    label={"E-mail"}
                                    value={email}
                                    fullWidth
                                    onKeyDown={(e) => handleKeyDown(e.key)}
                                    onChange={(e) => setEmail(e.target.value)}
                                    sx={{
                                        borderRadius: '0.5rem',
                                        marginBottom: "1rem",
                                    }}/>


                                <Button
                                    id="login-button"
                                    sx={{
                                        border: '1px solid #FFA149',
                                        backgroundColor: 'white',
                                        width: '100%',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '3rem',
                                        borderRadius: '0.5rem',
                                        mb: 2
                                    }}
                                    onClick={() => onEmailSubmit()}
                                >
                                    <Typography sx={{color: '#FFA149', fontSize: '1rem', fontWeight: 600}}>Create
                                        account</Typography>
                                </Button>
                            </Box>
                            <Box sx={{mb: 3, textAlign: 'center', width: '100%'}}>
                                <Divider></Divider>
                            </Box>
                            <Box sx={{
                                border: '1px solid gray',
                                borderRadius: '0.5rem',
                                padding: "1rem",
                                backgroundColor: 'white',
                                marginBottom: "2rem",
                            }}>
                                Already have an account? <Link href="/login">Log in</Link>
                            </Box>
                            {renderPrivacyPolicyLink()}

                        </Box>
                    </ThemeProvider>

                </Box>

            </Grid>
            <Grid item xs={0} md={6}>
                <Box sx={{
                    display: {xs: 'none', md: 'flex'},
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <LoginImage/>
                </Box>

            </Grid>
        </Grid>

    );
}

export default CreateAccount;
