import React, {useState} from 'react';
import {Box, Button, Grid, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../components/theme';
import {OfferStatus} from '../../../models/IUserProfile';
import {IStudentJobPosition} from '../../../models/IStudentJobPosition';
import AcceptedIndicator from '../../../assets/AcceptedIndicator';
import RejectedIndicator from '../../../assets/RejectedIndicator';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import useStudentPositionApi from "../../../api/useStudentPositionApi";
import {toast} from "react-toastify";
import AcceptOfferDialog from "./AcceptOfferDialog";
import RejectOfferDialog from "./RejectOfferDialog";
import {formatDate} from "../../../util/formatUtil";

function OffersMiddle({
                          activeOffer,
                          changeStatus,
                      }: { activeOffer: IStudentJobPosition | null, changeStatus: any }) {

    const {getOfferDownload} = useStudentPositionApi();

    function formatOfferDate(dateString: string) {
        if (!dateString) return null;

        const date = new Date(dateString);
        return formatDate(date)
    }

    function OfferSelection() {

        const [openAccept, setOpenAccept] = useState(false);
        const [openReject, setOpenReject] = useState(false);

        const boxStyle = {
            marginTop: '-0.2rem',
            marginLeft: '0.5rem',
        };

        const isAlreadyAccepted = activeOffer?.offerStatus === OfferStatus.ACCEPTED;
        const isAlreadyRejected = activeOffer?.offerStatus === OfferStatus.REJECTED;

        return (
            <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'space-between'}}>

                <Grid container alignItems="center">
                    <Grid item xs={6}>
                        <Box sx={{display: 'flex', width: "100%"}}>

                            <Typography variant="body1" fontWeight={700}>
                                {activeOffer?.positionName}
                            </Typography>
                            {
                                isAlreadyAccepted && (
                                    <Box sx={boxStyle}><AcceptedIndicator/></Box>
                                )
                            }
                            {
                                isAlreadyRejected && (
                                    <Box sx={boxStyle}><RejectedIndicator/></Box>
                                )
                            }
                        </Box>

                    </Grid>
                    <Grid item xs={6} textAlign="right">
                        {activeOffer?.offerSentDate ? (
                            <Typography variant="body1" fontWeight={700}>
                                {formatOfferDate(activeOffer?.offerSentDate)}
                            </Typography>
                        ) : null}
                    </Grid>
                </Grid>


                <Box sx={{display: {xs: 'none', sm: 'flex'}}}>
                    {
                        !isAlreadyAccepted && !isAlreadyRejected && (
                            <Button variant="contained" sx={{backgroundColor: '#809252', mr: 1}}
                                    onClick={() => setOpenAccept(true)}>Accept</Button>
                        )
                    }
                    {
                        !isAlreadyRejected && !isAlreadyAccepted && (
                            <Button variant="contained" sx={{backgroundColor: '#D32F2F', ml: 1}}
                                    onClick={() => setOpenReject(true)}>Reject</Button>
                        )
                    }
                </Box>

                <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
                    {
                        !isAlreadyAccepted && !isAlreadyRejected && (
                            <Button variant="contained"
                                    sx={{backgroundColor: '#809252', width: '2.5rem', minWidth: '2.5rem !important'}}
                                    onClick={() => setOpenAccept(true)}>
                                <CheckIcon/>
                            </Button>
                        )
                    }
                    {
                        !isAlreadyRejected && !isAlreadyAccepted && (
                            <Button variant="contained"
                                    sx={{backgroundColor: '#D32F2F', ml: 1, width: '2.5rem', minWidth: '2.5rem !important'}}
                                    onClick={() => setOpenReject(true)}>
                                <ClearIcon/>
                            </Button>
                        )
                    }
                </Box>
                <AcceptOfferDialog open={openAccept} setOpen={setOpenAccept}
                                   accept={() => changeStatus(activeOffer, OfferStatus.ACCEPTED)}/>
                <RejectOfferDialog open={openReject} setOpen={setOpenReject}
                                   reject={() => changeStatus(activeOffer, OfferStatus.REJECTED)}/>
            </Box>
        );
    }


    function downLoadOffer(id: number) {
        getOfferDownload(id).then((res: any) => {
            const link = document.createElement('a');
            link.href = `data:${res.contentType};base64,${res.content}`
            link.download = res.name;
            link.target = '_blank';
            link.click();
            link.remove();
        }).catch((e: any) => {
            console.log(e)
            toast.error('Error while downloading file')
        })
    }

    return (
        <Stack sx={{}} spacing={1}>
            <Paper elevation={0} sx={{...styles.paperCenter, p: {xs: 2, sm: 3}}}>
                <OfferSelection/>
            </Paper>

            <Paper elevation={0} sx={{...styles.paperCenter, p: {xs: 2, sm: 3}}}>
                <Typography typography="body1" sx={{mb: 2}}>{activeOffer?.offerSubject}</Typography>
                <Typography typography="body2">{activeOffer?.offerContent}</Typography>
                {activeOffer?.offerFileId &&
                    <Typography onClick={() => downLoadOffer(activeOffer?.id!)}
                                sx={{textDecoration: "underline", marginTop: "1rem", cursor: "pointer"}}
                                typography="body2">Download attached
                        file</Typography>
                }

            </Paper>
        </Stack>

    );
}

export default OffersMiddle;
