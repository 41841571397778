import {Box, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import UserUniversitySelect from "../../../../components/UserUniversitySelect";
import {IUserProfile} from "../../../../models/IUserProfile";
import {DomainCode, IDomainValue} from "../../../../models/IDomainValue";
import useDomainValues from "../../../../hooks/useDomainValues";
import {IUniversity} from "../../../../models/IUniversity";
import DragDropFileUpload from "./DragDropFileUpload";


const HigherEducation = ({
                             userProfile,
                             setUserProfile
                         }: { userProfile: IUserProfile | null, setUserProfile: any }) => {

    const [schools, setSchools] = useState<IDomainValue[]>([]);
    const {findDomainValuesByCode} = useDomainValues();


    const loadSchools = async () => {
        setSchools((prev) => findDomainValuesByCode(DomainCode.UNIVERSITY));
    };

    const changeUniversityData = (value: IUniversity) => {
        if (userProfile) {
            const newProfile = {...userProfile};
            newProfile.universities = userProfile.universities.map((u) => {
                if (u.id === value.id) {
                    return value;
                }
                return u;
            });
            setUserProfile(newProfile);
        }
    };

    const createNewUniversity = (): IUniversity => ({
        id: Math.floor(Math.random() * 1000000) + 1,
        gradeScale: 'PERCENTAGE',
        major: '',
        avgGrade: 0,
        year: null,
    } as IUniversity);

    useEffect(() => {
        loadSchools();
        if (userProfile) {
            if (!userProfile.universities || userProfile.universities.length === 0) {
                userProfile.universities = [];
                const initialStudy = createNewUniversity();
                userProfile.universities.push(initialStudy);
            }
        }
    }, [userProfile]);

    return (
        <Box>
            <Typography typography="body1">Education</Typography>
            <Typography typography="body2" sx={{mt: 1}}>I am studying at</Typography>

            <UserUniversitySelect
                university={userProfile?.universities[0]!!}
                schools={schools}
                changeData={changeUniversityData}
            />
            <DragDropFileUpload/>
        </Box>
    )
};

export default HigherEducation;
