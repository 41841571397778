import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import useTestApi from "../../../api/useTestApi";
import {Box, Button, ThemeProvider} from "@mui/material";
import {IQuestion} from "../../../models/IQuestion";
import {useAtomValue, useSetAtom} from "jotai";
import {userAtom} from "../../../store/userStore";
import {IAnswer} from "../../../models/IAnswer";
import {useNavigate} from "react-router-dom";
import TestIntro from "./TestIntro";
import {overviewAtom} from "../../../store/metrixStore";
import {IMetrixOverviews} from "../../../models/IMetrixOverviews";
import useUserProfileApi from "../../../api/useUserProfileApi";
import Competencies from "./Competencies";
import {findNotCompletedTests} from "../../../util/metrixUtil";
import PersonalityTest from "./PersonalityTest";
import JoblingLogo from "../../../assets/JoblingLogo";
import {tomatoTheme} from "../../../components/theme";
import {getAuth} from "firebase/auth";

function Test() {

    const user = useAtomValue(userAtom)
    const [allQuestions, setAllQuestions] = useState<IQuestion[]>([])
    const [activeStep, setActiveStep] = useState(-1);
    const [selected, setSelected] = useState(new Map<number, number | undefined>());
    const {getTestQuestions, getTestAnswers, upsertAnswer} = useTestApi();
    const setMetrixOverviews = useSetAtom(overviewAtom);
    const {getMetrixOverview} = useUserProfileApi();
    const [choiceDisabled, setChoiceDisabled] = useState(false);

    const [loaded, setLoaded] = useState(false)

    const navigate = useNavigate()

    const loadTestQuestions = async () => {
        const q: IQuestion[] = await getTestQuestions()
        setAllQuestions(q)
        return q
    }

    const loadAnswers = async () => {
        const answerMap = new Map()
        const answers: IAnswer[] = await getTestAnswers()

        answers.forEach((a) => {
            answerMap.set(a.questionId, a.value)
        })
        setSelected(answerMap)
        return answerMap
    }

    const loadMetrixData = async () => {
        let metrixOverview: IMetrixOverviews = await getMetrixOverview(user?.id!!);
        setMetrixOverviews(metrixOverview)
    }

    useEffect(() => {
        if (user) {
            loadTestQuestions()
            loadAnswers()
            loadMetrixData()
            setLoaded(true)
        }

    }, [])

    const handleNext = (selectedValue: number | undefined) => {
        setChoiceDisabled(true)

        const question = allQuestions[activeStep]

        const answer = {
            userId: user?.id,
            questionId: question.id,
            value: selectedValue
        } as IAnswer
        upsertAnswer(answer).then(() => {
            if (activeStep + 1 === allQuestions.length) {
                formSuccessfullySubmitted()
            } else {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                selected.set(question.id!, selectedValue)
                setChoiceDisabled(false)
            }
        })
    };

    const formSuccessfullySubmitted = async () => {
        let metrixOverview: IMetrixOverviews = await getMetrixOverview(user?.id!!);
        setMetrixOverviews(metrixOverview)

        const notCompleted = findNotCompletedTests(metrixOverview)

        if (notCompleted.length > 0) {
            setActiveStep(0)
            loadTestQuestions()
            loadAnswers()
            loadMetrixData()
        } else {
            navigate('/preferences')
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const continueLater = () => {
        setActiveStep(-1)
    }

    const logout = () => {
        getAuth().signOut().then(() => {
            navigate('/login');
        });
    }

    const continueTest = async () => {
        let a = await loadAnswers()
        let newActiveStep = 0
        if (a.size > 0) {
            const firstQuestionWithoutAnswer = allQuestions.find((q) => a.get(q.id!) === undefined)

            if (firstQuestionWithoutAnswer) {
                newActiveStep = firstQuestionWithoutAnswer.orderNr - 1 < a.size ? firstQuestionWithoutAnswer.orderNr - 1 : a.size
            }
        }
        setActiveStep(newActiveStep)
    }

    if (!loaded) {
        return <></>;
    }

    return (
        <ThemeProvider theme={tomatoTheme}>
            <Box sx={{backgroundColor: 'white', height: '100vh'}}>
                <Box sx={{
                    height: '4rem',
                    display: 'flex',
                    alignItems: 'center',
                    pl: 4,
                    borderBottom: '1px solid #EFEDEC;',
                    width: '100%'
                }}>
                    <JoblingLogo/>
                    <Box sx={{flexGrow: 1}}/>
                    <Box sx={{mr: "2rem"}}>
                        {activeStep === -1 ?
                            <Button sx={{color: 'palette.primary', fontSize: '1rem', fontWeight: '600'}}
                                    onClick={() => logout()}>Log out</Button> :
                            <Button sx={{
                                display: {xs: 'none', sm: 'block'},
                                color: 'palette.primary',
                                fontSize: '1rem',
                                fontWeight: '600'
                            }}
                                    onClick={() => continueLater()}>Save and continue later</Button>
                        }
                    </Box>
                </Box>
                <Box sx={{
                    width: '100%',
                    backgroundColor: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {activeStep === -1 && (
                        <TestIntro setActiveStep={setActiveStep} answers={selected.size}
                                   buttonAction={continueTest}/>
                    )}

                    {activeStep > -1 && (allQuestions[activeStep].testType === 'PERSONALITY' || allQuestions[activeStep].testType === 'VALUE') && (
                        <PersonalityTest activeStep={activeStep}
                                         questions={allQuestions.filter(q => q.testType === 'PERSONALITY' || q.testType === 'VALUE')}
                                         selected={selected}
                                         handleNext={handleNext} handleBack={handleBack}
                                         choiceDisabled={choiceDisabled}/>
                    )}


                    {activeStep > -1 && allQuestions[activeStep].testType === 'COMPETENCE' && (
                        <Competencies questions={allQuestions.filter(q => q.testType === 'COMPETENCE')}
                                      successfulSubmit={formSuccessfullySubmitted} handleBack={handleBack}/>

                    )}

                    {activeStep !== -1 ?
                        <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                            <Button sx={{color: 'palette.primary', fontSize: '1rem', fontWeight: '600'}}
                                    onClick={() => continueLater()}>Save and continue later</Button>
                        </Box> : <></>
                    }

                </Box>

            </Box>
        </ThemeProvider>
    );
}

export default Test;
