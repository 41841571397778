import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import Layout from "../../Layout";
import CompanyUsersMiddle from "./CompanyUsersMiddle";
import CompanySettingsLeft from "./CompanySettingsLeft";
import CompanyAccountMiddle from "./CompanyAccountMiddle";
import {useAtomValue} from "jotai";
import {userAtom} from "../../../store/userStore";

const initialPages = [
    {key: 'ACCOUNT', name: 'Account'}];

function CompanySettings() {
    const {t} = useTranslation();
    const user = useAtomValue(userAtom);

    const [pages, setPages] = useState(initialPages);
    const [activePage, setActivePage] = useState<any>(initialPages[0]);

    useEffect(() => {
        if(user && user.isCompanyAdmin) {
            setPages([...pages, {key: 'USERS', name: 'Users'}])
        }
    }, [user]);

    return (
        <Layout>

            <Stack spacing={1}>
                <Typography typography="h3">Settings</Typography>
                <Stack direction="row" spacing={1}>
                    <CompanySettingsLeft
                        pages={pages}
                        activePage={activePage}
                        setActivePage={setActivePage}
                    />
                    {activePage.key === 'ACCOUNT' && (
                        <CompanyAccountMiddle activePage={activePage}/>
                    )}
                    {activePage.key === 'USERS' && (
                        <CompanyUsersMiddle activePage={activePage}/>
                    )}
                </Stack>
            </Stack>


        </Layout>
    );
}

export default CompanySettings;
