import React from 'react';
import useApi, {BASE_URL} from './useApi';
import {OfferStatus} from '../models/IUserProfile';

const STUDENT_POSITIONS_URL = `${BASE_URL}student-job-positions`;

const useStudentPositionApi = () => {
    const {doFetch} = useApi();

    const updateStudentContacted = (id: number, studentContacted: boolean) => {
        const url = `${STUDENT_POSITIONS_URL}/${id}/student-contacted`;
        return doFetch(url, 'PUT', {studentContacted}, true);
    };

    const updateOfferStatus = (id: number, offerStatus: OfferStatus) => {
        const url = `${STUDENT_POSITIONS_URL}/${id}/offer-status`;
        return doFetch(url, 'PUT', {offerStatus}, true);
    };

    const getJobPositionsForStudent = () => {
        const url = `${STUDENT_POSITIONS_URL}/student`;
        return doFetch(url);
    };

    const getJobPositionsForCompany = () => {
        const url = `${STUDENT_POSITIONS_URL}/company`;
        return doFetch(url);
    };

    const getOfferDownload = (id: number) => {
        const url =  `${STUDENT_POSITIONS_URL}/${id}/download-offer`;
        return doFetch(url);
    };

    return {
        getJobPositionsForStudent,
        getJobPositionsForCompany,
        updateStudentContacted,
        updateOfferStatus,
        getOfferDownload
    };
};

export default useStudentPositionApi;
