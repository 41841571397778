import {IUniversity} from '../models/IUniversity';
import React from "react";

export const UNIVERSITY_MAP = {
    TARTU_YLIKOOL: [
        "Faculty of Arts and Humanities",
        "Faculty of Social Sciences",
        "Faculty of Medicine",
        "Faculty of Science and Technology"
    ],
    TALTECH: [
        "School of Information Technologies",
        "School of Business and Governance",
        "School of Engineering",
        "School of Science",
    ]
    /*
Tartu Ülikooli teadusvaldkonnad

Taltech teaduskonnad
School of Information Technologies
School of Business and Governance
School of Engineering
School of Science
Tallinna Ülikool
Baltic Film, Media and Arts School
School of Digital Technologies
School of Educational Sciences
School of Governance, Law and Society
School of Humanities
School of Natural Sciences and Health
 */
} as any;


export const createNewUniversity = (): IUniversity => ({
    id: Math.floor(Math.random() * 1000000) + 1,
    gradeScale: 'PERCENTAGE',
    major: '',
    avgGrade: 0,
    year: null,
} as IUniversity);
