import React from 'react';
import {AppBar, Box, Button, Grid} from '@mui/material';

const styles = {
    appBarBottom: {
        backgroundColor: 'background.paper',
        alignItems: 'flexEnd',
        top: 'auto',
        bottom: 0,
        width: '100%',
        height: '5rem'
    },
    box: {
        display: 'flex',
        alignItems: 'center',
        //width: '40rem',
        height: '3.125rem',
    },
};

function StudentFooterBar({save, submit}: { save: any, submit?: any }) {

    return (
        <AppBar elevation={0} position="fixed" sx={styles.appBarBottom}>


            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                backgroundColor: 'white',
                height: '100%',
                alignItems: 'center'
            }}>
                <Box sx={{maxWidth: '90rem', width: '100%'}}>
                    <Grid container spacing={0} justifyContent="center">
                        <Grid item xs={10}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: {xs: 'center', sm: 'flex-end'},
                                alignItems: 'center'
                            }}>
                                <Button sx={{backgroundColor: '#809252'}} variant="contained"
                                        onClick={submit}>Save</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>


        </AppBar>
    );
}

export default StudentFooterBar;
