import React from 'react';
import {toast} from 'react-toastify';
import useApi, {BASE_URL} from './useApi';
import {IUserProfile} from '../models/IUserProfile';

const USER_PROFILES_URL = `${BASE_URL}user-profiles`;

const useUserApi = () => {
    const {doFetch} = useApi();

    const getMyProfile = () => {
        const url = `${USER_PROFILES_URL}/me`;
        return doFetch(url);
    };

    const getUserProfile = (id: number) => {
        const url = `${USER_PROFILES_URL}/${id}`;
        return doFetch(url);
    };
    const updateProfile = (id: number, userProfile: IUserProfile) => {
        const url = `${USER_PROFILES_URL}/${id}`;
        doFetch(url, 'PUT', {...userProfile})
            .then((submittedProfile) => {
                toast.info('Profile saved. Note that you need to fill all required fields to make profile visible!');
            })
            .catch((e) => {
                console.log(e)
                if (e.name.includes('uq_user_phone')) {
                    toast.error('An account already exists with this phone number!');
                } else if (e.name.includes('uq_user_email')) {
                    toast.error('An account already exists with this email!');
                } else {
                    toast.error('Failed to submit profile');
                }
            });
    };

    const submitProfile = (userProfile: IUserProfile) => {
        const url = `${USER_PROFILES_URL}/submit`;
        return doFetch(url, 'POST', {...userProfile})
    };

    const unsubmitProfile = () => {
        const url = `${USER_PROFILES_URL}/unsubmit`;
        doFetch(url, 'POST', {}, true)
            .then((r) => toast.success('Profile opened for editing!'))
            .catch((e) => {
                console.log(e);
                toast.error('Failed to open profile for editing');
            });
    };

    const updateProfileVisibility = (id: number, profileVisible: boolean) => {
        const url = `${USER_PROFILES_URL}/profile-visible`;
        doFetch(url, 'PUT', {profileVisible}, true)
            .then((r) => toast.success('Profile visibility updated!'))
            .catch((e) => {
                console.log(e);
                toast.error('Failed to update profile visibility');
            });
    };
    const getMetrixOverview = (id: number) => {
        const url = `${USER_PROFILES_URL}/${id}/metrix-overview`;
        return doFetch(url); // TODO: Uncomment to load actual data from backend
        // return Promise.resolve({"valuesOverview":{"values":[{"type":"VALUE_SELF_DIRECTION","value":93},{"type":"VALUE_HEDONISM","value":44},{"type":"VALUE_BENEVOLENCE","value":55},{"type":"VALUE_UNIVERSALISM","value":33},{"type":"VALUE_STIMULATION","value":52},{"type":"VALUE_SECURITY","value":53}],"highestValues":[{"type":"VALUE_SELF_DIRECTION","value":93},{"type":"VALUE_BENEVOLENCE","value":55},{"type":"VALUE_SECURITY","value":53}],"lowestValues":[{"type":"VALUE_UNIVERSALISM","value":33},{"type":"VALUE_HEDONISM","value":44},{"type":"VALUE_STIMULATION","value":52}]},"personalityOverview":{"values":[],"highestValues":[],"lowestValues":[]}})
    };

    return {
        getMyProfile,
        getUserProfile,
        updateProfile,
        updateProfileVisibility,
        submitProfile,
        unsubmitProfile,
        getMetrixOverview
    };
};

export default useUserApi;
