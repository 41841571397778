import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {useAtomValue} from "jotai";
import {overviewAtom} from "../../../store/metrixStore";
import {findNotCompletedTests} from "../../../util/metrixUtil";
import SurveyResults from "./SurveyResults";
import JoblingLayout from "../../JoblingLayout";

function Survey() {
    const [notCompletedTests, setNotCompletedTests] = useState<string[] | null>(null);
    const metrixOverviews = useAtomValue(overviewAtom);

    useEffect(() => {
        const notCompleted = findNotCompletedTests(metrixOverviews)
        setNotCompletedTests(notCompleted)
    }, [metrixOverviews]);

    const hasCompletedAllTests = notCompletedTests?.length === 0

    if (hasCompletedAllTests) {
        return (
            <JoblingLayout>
                <SurveyResults metrixOverviews={metrixOverviews}/>
            </JoblingLayout>
        );
    }
    return <></>
}

export default Survey;
