import React, {useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Button, CircularProgress, DialogActions, DialogContent, DialogContentText} from '@mui/material';
import Dialog from "@mui/material/Dialog";


interface AcceptOfferDialogProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    accept: () => void;
}
function AcceptOfferDialog({open, setOpen, accept}: AcceptOfferDialogProps) {

    const [loading, setLoading] = useState(false);

    const handleAccept = async () => {
        setLoading(true); // Enable loading state when "Yes" button is clicked
        try {
            await accept(); // Call the accept function passed from Offers component
            // Once the operation is complete, you can disable the loading state
            setLoading(false);
        } catch (error) {
            // Handle any errors here
            console.error("Error:", error);
            setLoading(false); // Disable loading state on error
        }
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to accept this position? Your contact details will be shared with the Company
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleAccept} autoFocus disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Yes'}
                </Button>
                <Button onClick={() => setOpen(false)}>No</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AcceptOfferDialog;
