import React from 'react';
import useApi, {BASE_URL} from './useApi';

const useFileApi = () => {
    const {doFetch, doMultipart} = useApi();
    const FILE_URL = `${BASE_URL}files`;

    const getAllFiles = () => doFetch(FILE_URL);

    const getFile = (id: number) => {
        const url = `${FILE_URL}/${id}`;
        return doFetch(url);
    };

    const downloadFile = (id: number) => {
        const url = `${FILE_URL}/${id}/download`;
        return doFetch(url);
    };

    const getTestFile = (userId: number) => {
        const url = `${FILE_URL}/test?userId=${userId}`;
        return doFetch(url);
    };

    const getGradeSheet = () => {
        const url = `${FILE_URL}/grade-sheet`;
        return doFetch(url);
    };

    const deleteGradeSheet = () => {
        const url = `${FILE_URL}/grade-sheet`;
        return doFetch(url, 'DELETE', {}, true);
    };

    const uploadFile = (formData: FormData) => {
        const url = `${FILE_URL}/upload`;
        return doMultipart(url, formData, true);
    };

    const uploadTestFile = (formData: FormData) => {
        const url = `${FILE_URL}/test-upload`;
        return doMultipart(url, formData);
    };

    const uploadGradeSheet = (formData: FormData) => {
        const url = `${FILE_URL}/upload-grade-sheet`;
        return doMultipart(url, formData);
    };

    return {
        uploadFile, getAllFiles, getFile, getGradeSheet, getTestFile, downloadFile, uploadTestFile, uploadGradeSheet, deleteGradeSheet
    };
};

export default useFileApi;
