import React, {useEffect, useState} from 'react';
import {Grid} from '@mui/material';
import SelectionButton from './SelectionButton';
import {DomainCode, IDomainValue} from '../models/IDomainValue';
import useDomainValues from '../hooks/useDomainValues';

function DomainValueSelect({
                               domainCode,
                               selectedValues = [],
                               onSelect,
                               customCallback,
                               excludedValues = [],
                               isLocked = false
                           }: { domainCode: DomainCode, selectedValues: string[], onSelect: any, customCallback?: any, excludedValues?: string[]; isLocked?: boolean;}) {
    const [domainValues, setDomainValues] = useState<IDomainValue[]>([]);
    const [selected, setSelected] = useState<any>({});
    const {findDomainValuesByCode} = useDomainValues();

    const evalInitialValues = () => {
        // TODO: implement shorter way
        const initialSelected: any = {};
        selectedValues.forEach((i: string) => {
            initialSelected[i] = true;
        });
        setSelected(initialSelected);
    };

    useEffect(() => {
        const dv = findDomainValuesByCode(domainCode);
        if (dv) {
            setDomainValues(dv);
        }
    }, []);

    useEffect(() => {
        evalInitialValues();
    }, [selectedValues]);



    const changeSelection = (code: string) => {
        let newSelected = {...selected, [code]: !selected[code]};
        if (customCallback) {
            newSelected = customCallback(code, newSelected, domainValues);
        }

        setSelected(newSelected);

        onSelect(domainValues.filter((dv) => newSelected[dv.code]).map((dv) => dv.code));
    };

    return (
        <Grid container spacing={1} sx={{mt: 2}}>
            {
                domainValues.map((dv, index) => {
                    if(excludedValues.includes(dv.code)) {
                        return null;
                    }
                    const width = index === domainValues.length - 1 ? '0rem' : '0.5rem';
                    return (
                        <Grid item key={dv.code}>
                            <SelectionButton
                                key={dv.code}
                                selected={selected[dv.code]}
                                label={dv.value}
                                onClick={() => changeSelection(dv.code)}
                                isLocked={isLocked}
                            />

                        </Grid>
                    );
                })
            }
        </Grid>
    );
}

export default DomainValueSelect;
