import React from 'react';
import {Box, Paper, Stack, Typography,} from '@mui/material';
import {styles} from '../../../../components/theme';
import {IFilteredStudent} from '../../../../models/IFilteredStudent';
import AcceptedIndicator from '../../../../assets/AcceptedIndicator';
import {formatProfileLabel} from "../../../../util/formatUtil";

function CompanyOfferLeft({students}: { students: IFilteredStudent[] }) {
    return (
        <Stack sx={styles.leftStack} spacing={1}>
            {students && students.length > 0

                ? (
                    <Paper elevation={0} sx={styles.paper}>

                        {students?.map((p, i) => {
                            const margin = i === students.length - 1 ? '0rem' : '1rem';
                            return (
                                <Box sx={{display: 'flex', alignItems: 'center', marginBottom: margin}} key={i}>
                                    <Typography key={`t${i}`}>
                                        {formatProfileLabel(p.accountNumber)}
                                    </Typography>
                                    <Box sx={{flexGrow: 1}}/>
                                    <AcceptedIndicator/>
                                </Box>
                            );
                        })}
                    </Paper>
                ) : ''}
        </Stack>

    );
}

export default CompanyOfferLeft;
