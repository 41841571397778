import React, {useEffect, useState} from 'react';
import {Button, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import {styles} from '../../../components/theme';
import {useNavigate} from "react-router";
import {useAtomValue, useSetAtom} from "jotai";
import {orderModalShouldNotOpenAtom, orderOverviewAtom} from "../../../store/ordersStore";
import {IFilteredStudent} from "../../../models/IFilteredStudent";
import {FilterStatus} from "../../../models/IUserProfile";
import PaymentConfirmationDialog from '../ProductPackage/PaymentConfirmationDialog';

const steps = [
    {
        label: 'Filter and select matches',
        link: '/filter',
    },
    {
        label: 'Select',
        link: '/select',
    },
    {
        label: 'Contact request',
        link: '/offer',
    },
];

function PositionStepper({children, activeStep, students = []}: { children: JSX.Element, activeStep: number, students?: IFilteredStudent[]}) {
    const {id} = useParams();
    const navigate = useNavigate();
    const orderOverview = useAtomValue(orderOverviewAtom);
    const setOrderModalShouldNotOpenAtom = useSetAtom(orderModalShouldNotOpenAtom);

    const [enoughContactRequestsAvailable, setEnoughContactRequestsAvailable] = useState<boolean>(false);
    const [showPaymentNotification, setShowPaymentNotification] = useState<boolean>(false);

    useEffect(() => {
        let confirmedProfileCount = students.filter((students) => students.filterStatus === FilterStatus.CONFIRMED).length;
        setEnoughContactRequestsAvailable(orderOverview.availableContactRequests < confirmedProfileCount)
    }, [students, orderOverview]);



    const next = (index: number) => {
        if (needsToOrderContactRequests(index)) {
            setShowPaymentNotification(true);
        } else {
            const url = `/positions/${id}` + steps[activeStep + 1].link;
            navigate(url);
        }
    };

    const back = () => {
        const url = `/positions/${id}` + steps[activeStep - 1].link;
        setOrderModalShouldNotOpenAtom(true)
        setTimeout(() => {
            setOrderModalShouldNotOpenAtom(false)
        }, 700)
        navigate(url);
    };

    const onLabelClick = (step: any, index: number) => {
        if (index < activeStep) {
            navigate(`/positions/${id}` + step.link)
        }
    }

    function needsToOrderContactRequests(index: number) {
        return index === 1 && enoughContactRequestsAvailable;
    }

    return (
        <Stack sx={styles.rightStack} spacing={1}>
            <Paper elevation={0}
                   sx={{
                       ...styles.paper,
                       width: styles.rightStack.width
                   }}>
                <Stepper activeStep={activeStep} orientation="vertical" sx={{
                    '& .MuiStepLabel-label.Mui-active': {
                        color: '#000000',
                        fontWeight: '700',
                    },
                }}>
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel onClick={() => onLabelClick(step, index)}
                                       sx={index < activeStep ? {cursor: 'pointer'} : {}}>
                                {step.label}
                            </StepLabel>
                            <StepContent>
                                {children}
                                {index !== 0 && (
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            height: '1.875rem',
                                            color: '#FFA049',
                                            fontSize: '0.875rem',
                                            width: '4rem',
                                            marginTop: '1rem',
                                            marginRight: '0.25rem'
                                        }}
                                        onClick={() => back()}
                                    >
                                        Back
                                    </Button>
                                )}
                                {index !== steps.length - 1 && (
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: '1.875rem',
                                            color: '#ffffff',
                                            fontSize: '0.875rem',
                                            width: '4rem',
                                            marginTop: '1rem',
                                        }}
                                        onClick={() => next(index)}
                                    >
                                        {needsToOrderContactRequests(index) ? <>Order</> : <>Next</>}
                                    </Button>
                                )}

                                {index === 1 && <Typography variant="body2" mt={1}>Available
                                    requests: {orderOverview.availableContactRequests}</Typography>}
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
            </Paper>
            <PaymentConfirmationDialog open={showPaymentNotification} setOpen={setShowPaymentNotification}/>
        </Stack>

    );
}

export default PositionStepper;
