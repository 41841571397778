import {Box, Button, Dialog, Paper, Stack, Typography,} from '@mui/material';
import React, {useEffect} from 'react';
import {useAtom} from 'jotai';
import {styles} from '../theme';
import {orderWasSuccessfulAtom} from "../../store/ordersStore";

function PaymentOutcomeModal() {
    const [orderWasSuccessful, setOrderWasSuccessful] = useAtom(orderWasSuccessfulAtom);


    return (
        <Dialog open={orderWasSuccessful !== null} onClose={() => setOrderWasSuccessful(null)}>
            <Stack sx={{width: '100%'}}>
                <Paper elevation={0} sx={styles.paper}>

                    {orderWasSuccessful !== null && orderWasSuccessful &&
                        <Box sx={{mx: 2, my: 3}}>
                            <Typography typography="h1" color={'secondary'} mb={3}>
                                Thank for your payment!
                            </Typography>
                            We have successfully received your payment. The invoice will be emailed to you soon.
                            For any futher questions, please contact us at info@jobling.ee
                        </Box> }
                    {orderWasSuccessful !== null && !orderWasSuccessful &&
                        <Box sx={{mx: 2, my: 3}}>
                            <Typography typography="h1" color={'error'} mb={3}>
                                Payment Unsuccessful!
                            </Typography>
                            Your recent payment attempt was unsuccessful. Please check your payment details or contact us at info@jobling.ee
                        </Box>
                    }

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box style={{flexGrow: 1}}/>
                        <Button
                            variant="text"
                            sx={{fontSize: '0.875rem', color: '#809252'}}
                            onClick={() => setOrderWasSuccessful(null)}
                        >
                            Close
                        </Button>
                    </Box>
                </Paper>
            </Stack>
        </Dialog>
    );
}

export default PaymentOutcomeModal;
