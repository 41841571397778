import React from 'react';
import useApi, {BASE_URL} from './useApi';

const useDomainApi = () => {
    const {doFetch} = useApi();
    const DOMAIN_URL = `${BASE_URL}domain-values`;

    const getDomainValues = () => doFetch(DOMAIN_URL);

    const getUniversityCounts = () => {
        const url = DOMAIN_URL + "/university-counts"
        return doFetch(url);
    };

    return {getDomainValues, getUniversityCounts};
};

export default useDomainApi;
