import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Button, Divider, Typography} from "@mui/material";
import {IQuestion} from "../../../models/IQuestion";
import TestProgressBar from "./TestProgressBar";
import TestNavigationButtons from "./TestNavigationButtons";


interface Props {
    activeStep: number;
    questions: IQuestion[];
    selected: Map<number, number | undefined>
    handleNext: (step: number | undefined) => void;
    handleBack: () => void;
    choiceDisabled: boolean;
}

const buttonStyles = {
    backgroundColor: '#e4e6e8',
    p: 0,
    width: {xs: '3.5rem', sm: '5rem'},
    height: {xs: '3.5rem', sm: '5rem'},
    minWidth: '3.5rem !important',
    borderRadius: '100%',
    border: 'none',
    margin: '0.3rem',
    cursor: 'pointer',

}

function PersonalityTest({activeStep, questions, selected, handleNext, handleBack, choiceDisabled}: Props) {

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            //justifyContent: 'space-between',
            height: '100%',
            //height: 'calc(100vh - 5rem)',
            p: 2
        }}>
            <TestProgressBar activeStep={activeStep} type={'PERSONALITY'}/>

            <Box sx={{
                maxWidth: '34.25rem',
                textAlign: 'center',
                height: {xs: '6rem', md: '9rem'},
                mb: {xs: '4rem', sm: '4rem'}
            }}>
                <Typography typography="h1"
                            sx={{fontSize: {xs: '1.25rem', sm: '2rem'}}}>"{questions[activeStep].text}"</Typography>
            </Box>

            <Box sx={{maxWidth: '34.25rem', textAlign: 'center'}}>


                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <Button
                        className={"test-answer"}
                        sx={{
                            ...buttonStyles,
                            backgroundColor: selected.get(questions[activeStep].id!) === 0 ? '#FFA149' : '#e4e6e8'
                        }} onClick={() => handleNext(0)} disabled={choiceDisabled}/>
                    <Button className={"test-answer"} sx={{
                        ...buttonStyles,
                        width: {xs: '2.6rem', sm: '4rem'},
                        height: {xs: '2.6rem', sm: '4rem'},
                        minWidth: '2.6rem !important',
                        backgroundColor: selected.get(questions[activeStep].id!) === 20 ? '#FFA149' : '#e4e6e8'
                    }}
                            onClick={() => handleNext(20)} disabled={choiceDisabled}/>
                    <Button className={"test-answer"} sx={{
                        ...buttonStyles,
                        width: {xs: '2rem', sm: '3rem'},
                        height: {xs: '2rem', sm: '3rem'},
                        minWidth: '2rem !important',
                        backgroundColor: selected.get(questions[activeStep].id!) === 40 ? '#FFA149' : '#e4e6e8'
                    }}
                            onClick={() => handleNext(40)} disabled={choiceDisabled}/>
                    <Divider orientation={"vertical"}
                             sx={{
                                 color: '#e4e6e8',
                                 width: '1px',
                                 height: '5rem',
                                 ml: {xs: 1, sm: 2},
                                 mr: {xs: 1, sm: 2}
                             }}/>
                    <Button className={"test-answer"} sx={{
                        ...buttonStyles,
                        width: {xs: '2rem', sm: '3rem'},
                        height: {xs: '2rem', sm: '3rem'},
                        minWidth: '2rem !important',
                        backgroundColor: selected.get(questions[activeStep].id!) === 60 ? '#FFA149' : '#e4e6e8'
                    }}
                            onClick={() => handleNext(60)} disabled={choiceDisabled}/>
                    <Button className={"test-answer"} sx={{
                        ...buttonStyles,
                        width: {xs: '2.6rem', sm: '4rem'},
                        height: {xs: '2.6rem', sm: '4rem'},
                        minWidth: '2.6rem !important',
                        backgroundColor: selected.get(questions[activeStep].id!) === 80 ? '#FFA149' : '#e4e6e8'
                    }}
                            onClick={() => handleNext(80)} disabled={choiceDisabled}/>
                    <Button className={"test-answer"} sx={{
                        ...buttonStyles,
                        backgroundColor: selected.get(questions[activeStep].id!) === 100 ? '#FFA149' : '#e4e6e8'
                    }} onClick={() => handleNext(100)} disabled={choiceDisabled}/>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: {xs: '4rem', sm: '4rem'}
                }}>
                    <Typography typography="body1"
                                sx={{maxWidth: '6rem', fontWeight: '700', fontSize: {xs: '0.75rem', md: '1rem'}}}>Not
                        like me at all</Typography>
                    <Typography typography="body1"
                                sx={{maxWidth: '6rem', fontWeight: '700', fontSize: {xs: '0.75rem', md: '1rem'}}}>Very
                        much like me</Typography>
                </Box>

                <TestNavigationButtons activeStep={activeStep} questions={questions} selected={selected}
                                       handleNext={handleNext} handleBack={handleBack} type="PERSONALITY"/>

            </Box>


        </Box>
    );
}

export default PersonalityTest;
