import {Box, Typography} from '@mui/material';
import React from 'react';

const styles = {
    label: {
        color: '#FF0000',
        fontSize: '0.75rem',
    },
    box: {
        display: 'flex',
        backgroundColor: '#FFE9E9',
        borderRadius: '0.25rem',
        padding: '0rem 0.5rem',
        height: '1.375rem',
        width: '3.9375rem',
        alignItems: 'center',
    },
};

function RejectedLabel() {
    return (
        <Box sx={styles.box}>
            <Typography sx={styles.label}>Rejected</Typography>
        </Box>
    );
}

export default RejectedLabel;
