import React from 'react';
import {Container} from '@mui/material';

const html = '<html>\n' +
    '\n' +
    '<head>\n' +
    '<meta http-equiv=Content-Type content="text/html; charset=windows-1252">\n' +
    '<meta name=Generator content="Microsoft Word 15 (filtered)">\n' +
    '<style>\n' +
    '<!--\n' +
    ' /* Font Definitions */\n' +
    ' @font-face\n' +
    '\t{font-family:"Cambria Math";\n' +
    '\tpanose-1:2 4 5 3 5 4 6 3 2 4;}\n' +
    '@font-face\n' +
    '\t{font-family:Calibri;\n' +
    '\tpanose-1:2 15 5 2 2 2 4 3 2 4;}\n' +
    '@font-face\n' +
    '\t{font-family:"Calibri Light";\n' +
    '\tpanose-1:2 15 3 2 2 2 4 3 2 4;}\n' +
    '@font-face\n' +
    '\t{font-family:"Segoe UI";\n' +
    '\tpanose-1:2 11 5 2 4 2 4 2 2 3;}\n' +
    ' /* Style Definitions */\n' +
    ' p.MsoNormal, li.MsoNormal, div.MsoNormal\n' +
    '\t{margin-top:0cm;\n' +
    '\tmargin-right:0cm;\n' +
    '\tmargin-bottom:8.0pt;\n' +
    '\tmargin-left:0cm;\n' +
    '\tline-height:107%;\n' +
    '\tfont-size:11.0pt;\n' +
    '\tfont-family:"Calibri",sans-serif;}\n' +
    'a:link, span.MsoHyperlink\n' +
    '\t{color:#0563C1;\n' +
    '\ttext-decoration:underline;}\n' +
    'p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph\n' +
    '\t{margin-top:0cm;\n' +
    '\tmargin-right:0cm;\n' +
    '\tmargin-bottom:8.0pt;\n' +
    '\tmargin-left:36.0pt;\n' +
    '\tline-height:107%;\n' +
    '\tfont-size:11.0pt;\n' +
    '\tfont-family:"Calibri",sans-serif;}\n' +
    'p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst\n' +
    '\t{margin-top:0cm;\n' +
    '\tmargin-right:0cm;\n' +
    '\tmargin-bottom:0cm;\n' +
    '\tmargin-left:36.0pt;\n' +
    '\tline-height:107%;\n' +
    '\tfont-size:11.0pt;\n' +
    '\tfont-family:"Calibri",sans-serif;}\n' +
    'p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle\n' +
    '\t{margin-top:0cm;\n' +
    '\tmargin-right:0cm;\n' +
    '\tmargin-bottom:0cm;\n' +
    '\tmargin-left:36.0pt;\n' +
    '\tline-height:107%;\n' +
    '\tfont-size:11.0pt;\n' +
    '\tfont-family:"Calibri",sans-serif;}\n' +
    'p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast\n' +
    '\t{margin-top:0cm;\n' +
    '\tmargin-right:0cm;\n' +
    '\tmargin-bottom:8.0pt;\n' +
    '\tmargin-left:36.0pt;\n' +
    '\tline-height:107%;\n' +
    '\tfont-size:11.0pt;\n' +
    '\tfont-family:"Calibri",sans-serif;}\n' +
    '.MsoChpDefault\n' +
    '\t{font-family:"Calibri",sans-serif;}\n' +
    '.MsoPapDefault\n' +
    '\t{margin-bottom:8.0pt;\n' +
    '\tline-height:107%;}\n' +
    ' /* Page Definitions */\n' +
    ' @page WordSection1\n' +
    '\t{size:612.0pt 792.0pt;\n' +
    '\tmargin:72.0pt 72.0pt 72.0pt 72.0pt;}\n' +
    'div.WordSection1\n' +
    '\t{page:WordSection1;}\n' +
    ' /* List Definitions */\n' +
    ' ol\n' +
    '\t{margin-bottom:0cm;}\n' +
    'ul\n' +
    '\t{margin-bottom:0cm;}\n' +
    '-->\n' +
    '</style>\n' +
    '\n' +
    '</head>\n' +
    '\n' +
    '<body lang=ET link="#0563C1" vlink="#954F72" style=\'word-wrap:break-word\'>\n' +
    '\n' +
    '<div class=WordSection1>\n' +
    '\n' +
    '<p class=MsoNormal align=center style=\'margin-top:15.0pt;margin-right:0cm;\n' +
    'margin-bottom:0cm;margin-left:0cm;text-align:center;line-height:normal;\n' +
    'background:white\'><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:black\'>Privacy Policy</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;line-height:normal;background:white\'><b><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>&nbsp;</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal align=right style=\'margin-bottom:15.0pt;text-align:right;\n' +
    'line-height:normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Last update: 26.09.2022</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:35.7pt;text-indent:-17.85pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>1.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>General provisions</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:6.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>1.1. The present document constitutes the privacy policy of\n' +
    'Jobling OÜ (hereinafter - <b>“the Service Provider”</b>), which describes how\n' +
    'the Service Provider processes personal data of the users of the platform\n' +
    '(hereinafter also – <b>“the data subjects”</b>).</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:6.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>1.2. Within the meaning of the present privacy policy personal\n' +
    'data means any information related to the data subject which can be used either\n' +
    'directly or indirectly for identification of the person, such as, for example,\n' +
    'name, e-mail address or address of residence.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:6.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>1.3. The Service Provider processes personal data first and\n' +
    'foremost on the basis of the obligations stipulated in the General Data\n' +
    'Protection Regulation (EC) 2016/679 (hereinafter – <b>“GDPR”</b>).</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:6.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>1.4. The present privacy policy does not cover or protect\n' +
    'against processing of anonymous data or data of legal entities.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:14.2pt;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>2.<b>&nbsp;Data\n' +
    'controller</b></span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>2.1.&nbsp; The data controller regarding personal data of the\n' +
    'data subject is Jobling OÜ, registry code 16605946, address A. Lauteri Street\n' +
    '3, 10114, Tallinn.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>2.2.&nbsp;\n' +
    'The contact details of the data controller are: info@jobling.ee</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:35.7pt;text-indent:-17.85pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>3.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Data processor</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>3.1.&nbsp; In order to allow the platform to successfully serve\n' +
    'the users, the Service Provider in certain cases engages data processors in its\n' +
    'activity. The data processors are our consultants and companies that provide to\n' +
    'the Service Provider various services which support operation of the Service Provider\n' +
    '(for example, companies that provide IT services), as well as other cooperation\n' +
    'partners that process personal data for the purpose of achievement of the goals\n' +
    'established by us, on the basis of instructions received from the Service\n' +
    'Provider.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:35.7pt;text-indent:-17.85pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>4.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Recipients</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>4.1.&nbsp; In certain cases, first and foremost, if it is\n' +
    'required under applicable legislation, the Service Provider must forward\n' +
    'personal data of the data subject to administrative agencies, including on the\n' +
    'basis of legislation concerning taxation and social insurance. When the Service\n' +
    'Provider forwards personal data of the data subject to the recipients, it\n' +
    'observes general principles of data processing stipulated in GDPR. Also, when\n' +
    'the Service Provider forwards personal data to recipients, it performs all obligations\n' +
    'stipulated in GDPR and other legislation concerning data protection.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:21.3pt;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>5<b>.\n' +
    'Types of personal data</b></span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.&nbsp; The Service Provider processes the following personal\n' +
    'data:</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.1. results of the personality test;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.2.    information as to which type of work the data subject\n' +
    'is looking for (internship, full-time or part-time work, project-based work,\n' +
    'etc.);</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.3.    which areas students are interested in to find a job;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.4.    language skills of the student;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.5.    establishment where the student is presently studying;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.6.    average grade of the student;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.7.    information regarding previous work experience;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.8.    information regarding skills.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>5.1.9. other data that has been voluntarily provided by the data\n' +
    'subjects to the Service Provider (for example, personal data that was disclosed\n' +
    'by the contact person of the employer when preparing an invitation for a job\n' +
    'interview for the student).</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:35.7pt;text-indent:-17.85pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Purposes of processing</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1. The Service Provider processes personal data for the\n' +
    'following purpose:</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.1. creation of an account for the user. The legal basis for\n' +
    'processing of personal data is subsection (b) of Article 6(1) of GDPR;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.2. communication with the users of the platform, including\n' +
    'settlements and resolution of claims. The legal basis for processing of personal\n' +
    'data is subsection (f) of Article 6(1) of GDPR (legitimate interest of the data\n' +
    'controller in responding to inquiries of the data subject);</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.3. performance of legal obligations of the Service Provider\n' +
    'arising from the law. The legal basis for processing of personal data is\n' +
    'subsection (c) of Article 6(1) of GDPR;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.4. conduct of surveys for the purpose of receipt of feedback\n' +
    'from the users of the platform and improvement of the service. The legal basis\n' +
    'for processing of personal data is subsection (f) of Article 6(1) of GDPR\n' +
    '(legitimate interest of the data controller in improving its own service);</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.5. receipt of feedback from the users of the platform and\n' +
    'its analysis. The legal basis for processing of personal data is subsection (f)\n' +
    'of Article 6(1) of GDPR (legitimate interest of the data controller in\n' +
    'improving its own products);</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.6. conduct of personality tests in case of users who are\n' +
    'students. The legal basis for processing of personal data is subsection (f) of\n' +
    'Article 6(1) of GDPR (legitimate interest of the employer as the third person\n' +
    'in finding an employee with a suitable personality);</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>6.1.7. generation of a profile of a student based on the\n' +
    'knowledge, skills and previous experience of the student. The legal basis for\n' +
    'processing of personal data is subsection (b) of Article 6(1) of GDPR. </span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:36.0pt;text-indent:-18.0pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Rights of the data subject</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1. The data subject has the following rights, subject to\n' +
    'limitations arising from applicable legislation on data protection:</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.1. the right of access to personal data concerning the data\n' +
    'subject;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.2. the right to rectification of personal data;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.3. the right to erasure of personal data;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.4. the right to transmit personal data;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.5. the right not to be subject to a decision based solely on\n' +
    'automated processing of personal data;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.1.6. the right to withdraw consent to processing of personal\n' +
    'data given to the Service Provider (if the legal basis for processing of\n' +
    'personal data is consent of the data subject).</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.2. If the Service Provider processes personal data for the\n' +
    'purpose of direct marketing, the data subject has the right to submit at any\n' +
    'time objections to processing of personal data concerning the data subject in\n' +
    'connection with such marketing, including free profiling conducted at any time\n' +
    'and free of charge, to the extent related to the relevant direct marketing.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.3. If the data subject submits objections to processing of\n' +
    'personal data for the purpose of direct marketing, the Service Provider shall\n' +
    'no longer process the personal data for such purpose. In such case the Service\n' +
    'Provider stops processing your personal data for the purpose of direct\n' +
    'marketing, however, the Service Provider may not necessarily stop processing\n' +
    'for other purposes if the Service Provider has a legal basis suitable for that\n' +
    'purpose.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.4. All data subjects have the right to contact a data\n' +
    'protection supervision authority if the data subject finds that the Service\n' +
    'Provider breaches applicable data protection legislation in the course of\n' +
    'processing of personal data.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>7.5. If processing of personal data is based on consent of the\n' +
    'data subject, the data subject has the right to withdraw their consent at any\n' +
    'time. Withdrawal of consent does not impact lawfulness of processing performed\n' +
    'before withdrawal of the consent. The data subject is notified of this\n' +
    'condition before giving consent. The Service Provider stops processing of\n' +
    'personal data if consent is the only basis for processing. If there is any\n' +
    'other legal basis or bases for processing of personal data (for example, a\n' +
    'contract concluded with the data subject, a legitimate interest, etc.),\n' +
    'processing can be continued on the respective legal basis.</span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:36.0pt;text-indent:-18.0pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>8.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Security of personal data</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>8.1. The Service Provider keeps all personal data disclosed to\n' +
    'the Service Provider strictly confidential and protects personal data from\n' +
    'unlawful acquisition by third persons.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>8.2. For protection of personal data, the Service Provider\n' +
    'implements respective technical and organisational measures, which take into\n' +
    'account the method, scope, context and purposes of processing of personal data,\n' +
    'as well as security of rights and freedoms of a natural person.</span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-top:12.0pt;margin-right:0cm;margin-bottom:\n' +
    '12.0pt;margin-left:36.0pt;text-indent:-18.0pt;line-height:normal;background:\n' +
    'white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>9.<span style=\'font:7.0pt "Times New Roman"\'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;\n' +
    '</span></span><b><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Other provisions</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:15.0pt;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>9.1. The Service Provider regularly reviews the present privacy\n' +
    'policy and amends it if necessary. The latest version of the privacy policy is\n' +
    'always available on the website of the Service Provider. The Service Provider\n' +
    'notifies the data subjects of updates of the Privacy Policy and asks each data\n' +
    'subject whether they agree with the amendments.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>9.2. If the data subject has questions as to how the Service\n' +
    'Provider processes their personal data, the data subject can contact the\n' +
    'Service Provider using the contact details stated in Section 2.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>&nbsp;</span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-bottom:0cm;text-align:justify;\n' +
    'text-indent:-18.0pt;line-height:normal;background:white\'><b><span lang=EN-GB\n' +
    'style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>10.<span\n' +
    'style=\'font:7.0pt "Times New Roman"\'>&nbsp; </span></span></b><b><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>Use of\n' +
    'cookies</span></b></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-top:0cm;margin-right:0cm;margin-bottom:0cm;\n' +
    'margin-left:18.0pt;text-align:justify;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>&nbsp;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>Jobling OÜ uses cookies on its website pages. Cookies are small\n' +
    'text files that are loaded on to your device when you visit our website.</span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>&nbsp;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>By continuing to use our website <a href="http://www.jobling.ee">www.jobling.ee</a>\n' +
    'and platform <a href="https://app.jobling.ee/login">https://app.jobling.ee/login</a>,\n' +
    'you grant consent for use of cookies based on the rules of these terms and\n' +
    'conditions. If you do not consent to the use of cookies, you have the option of\n' +
    'configuring your browser so that the computer does not save the cookies. In\n' +
    'such a case, we cannot ensure that all functions on our page will work for you\n' +
    'in the way that they should. For more information about managing and blocking\n' +
    'cookies, visit </span><span lang=EN-US style=\'color:black\'><a\n' +
    'href="http://www.allaboutcookies.org/"><span lang=EN-GB style=\'font-family:\n' +
    '"Calibri Light",sans-serif\'>http://www.allaboutcookies.org/</span></a></span><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'> </span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-top:0cm;margin-right:0cm;margin-bottom:\n' +
    '0cm;margin-left:21.0pt;text-align:justify;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>&nbsp;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>We use cookies to improve the quality of service. We use cookies\n' +
    'for collecting statistical data, remembering visitor preferences, and\n' +
    'displaying ads. </span></p>\n' +
    '\n' +
    '<p class=MsoListParagraph style=\'margin-top:0cm;margin-right:0cm;margin-bottom:\n' +
    '0cm;margin-left:21.0pt;text-align:justify;line-height:normal;background:white\'><span\n' +
    'lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;color:#333333\'>&nbsp;</span></p>\n' +
    '\n' +
    '<p class=MsoNormal style=\'margin-bottom:0cm;text-align:justify;line-height:\n' +
    'normal;background:white\'><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif;\n' +
    'color:#333333\'>When you visit Jobling, our data analytics and advertising\n' +
    'partners can also save cookies on your device.</span></p>\n' +
    '\n' +
    '<p class=MsoNormal><span lang=EN-GB style=\'font-family:"Calibri Light",sans-serif\'>&nbsp;</span></p>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '</body>\n' +
    '\n' +
    '</html>\n';

function PrivacyPolicy() {
    return (
        <Container sx={{backgroundColor:"#FFFFFF"}}>
            <div dangerouslySetInnerHTML={{__html: html}} />
        </Container>
    );
}

export default PrivacyPolicy;
