import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Button, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import Dialog from "@mui/material/Dialog";


function JobManagedByJoblingNotification({open, setOpen}: {
    open: boolean;
    setOpen: (open: boolean) => void;
}) {


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle>
                No further action required
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    This job position is managed by Jobling!
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}

export default JobManagedByJoblingNotification;
