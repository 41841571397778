import React, {useState} from 'react';
import {Stack, ThemeProvider, Typography} from "@mui/material";
import {tomatoTheme} from "./theme";
import {isValidEmail} from "../util/formatUtil";
import useFormsApi from "../api/useFormsApi";
import {toast} from "react-toastify";

function Game2() {

    const [email, setEmail] = useState('');
    const [errorFields, setErrorFields] = useState<string[]>([]);

    const {postFormData} = useFormsApi();


    const isFormValid = () => {

        const errors = [];

        if (!isValidEmail(email)) {
            errors.push('email');
        }

        setErrorFields(errors);
        return errors.length <= 0;

    };


    const send = async () => {
        if (isFormValid()) {
            const formData = new FormData();
            formData.append("entry.389836881", email)

            postFormData(formData).then((response => {
                if (response.status != 0 && !response.ok) {
                    console.log("error response state")
                } else {
                    setEmail('')
                    toast.success("E-mail submitted!")
                }
            })).catch((e) => {
                console.log("error", e)
            }).finally(() => {

                }
            )
        }

    };

    return (
        <ThemeProvider theme={tomatoTheme}>
            <Stack sx={{
                minWidth: "100%",
                width: "100%",
                height: "100vh",
                maxHeight: "-webkit-fill-available",
                backgroundColor: "#FFFFFF",
                alignItems: "center",
                paddingBottom: "2rem"
            }}>
                <Stack sx={{
                    width: "100%",
                    alignItems: "center",
                    position: "absolute",
                    height: "45px",
                    backgroundColor: "#FFFFFF",
                    zIndex: 1
                }}/>
                <iframe
                    src="https://turbowarp.org/812173146/embed?autoplay&addons=remove-curved-stage-border&username=apunkto"
                    style={{overflow: "hidden", height: "100%", minWidth: "100%"}}
                    frameBorder="0" scrolling="no" allowFullScreen/>
                <Stack padding={"0rem"}>

                    <Typography variant={"h1"} textAlign={"center"} sx={{marginTop: "1rem", fontSize: "2.5rem"}}>Win a
                        round trip for 2 to Linnanmäki amusement
                        park!</Typography>
                    <Typography textAlign={"center"} variant={"h1"} sx={{marginTop: "1rem", fontSize: "2rem"}}>Complete your profile
                        at <Typography variant={"h1"} component={"span"} sx={{ fontSize: "2rem"}} color={"green"}>jobling.ee</Typography> to
                        participate</Typography>
                </Stack>
            </Stack>
        </ThemeProvider>
    );
}

export default Game2;
