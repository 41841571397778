import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid, Paper, Stack, ThemeProvider, Typography} from '@mui/material';
import {useAtomValue} from 'jotai';
import ChangePassword from '../../../components/shared/ChangePassword';
import {profileVisibleAtom} from '../../../store/userStore';
import {styles, tomatoTheme} from "../../../components/theme";
import JoblingLayout from "../../JoblingLayout";
import StudentProfileInfo from "../../../components/student/StudentProfileInfo";

function StudentPreferences() {

    return (
        <JoblingLayout>
            <ThemeProvider theme={tomatoTheme}>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={10}>
                        <Typography typography="h2" sx={{mt: 3, mb: 1}}>Account</Typography>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Stack spacing={1}>
                            <Paper elevation={0} sx={styles.paperCenter}>
                                <StudentProfileInfo/>
                            </Paper>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} md={7}>

                        <Stack spacing={1}>
                            <Paper elevation={0} sx={{...styles.paperCenter}}>
                                <ChangePassword/>
                            </Paper>
                        </Stack>

                    </Grid>

                </Grid>
            </ThemeProvider>
        </JoblingLayout>
    );
}

export default StudentPreferences;
