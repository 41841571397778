import {Button} from '@mui/material';
import React from 'react';

export enum ActionButtonType {
    CONFIRM, REJECT, SUBMIT, SAVE
}

const styles = {
    button: {
        height: '1.875rem',
        minWidth: '6.25rem',
        color: '#ffffff',
        fontSize: '0.875rem',
        marginLeft: '0.25rem',
        border: '0px',
    },
};

const CONFIG = {
    [ActionButtonType.CONFIRM]: {
        variant: 'contained',
        style: {
            backgroundColor: '#02B033',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#02B033',
                border: '1px solid',
            },

        },
    },
    [ActionButtonType.REJECT]: {
        variant: 'contained',
        style: {
            backgroundColor: '#EF2D56',
            '&:hover': {
                backgroundColor: '#fff',
                color: '#EF2D56',
                border: '1px solid',
            },
        },
    },
    [ActionButtonType.SUBMIT]: {
        variant: 'contained',
        style: {
            fontWeight: 'bold',
            backgroundColor: '#809252',
            width: '6.25rem',
            '&:hover': {
                backgroundColor: '#809252',
                color: '#FFFFFF',
            },
        },
    },
    [ActionButtonType.SAVE]: {
        variant: 'outlined',
        style: {
            fontWeight: 'bold',
            backgroundColor: '#FFFFFF',
            color: '#809252',
            border: '1px solid',
            borderColor: '#809252',
        },
    },
};

function ActionButton({label, type, onClick}: { label: string; type: ActionButtonType; onClick: () => void; }) {
    const {variant} = CONFIG[type];
    return (
        <Button variant={ActionButtonType.SAVE ? 'outlined' : 'contained'} onClick={onClick}
                sx={{...styles.button, ...CONFIG[type].style}}>{label}</Button>
    );
}

export default ActionButton;
