import React from 'react';

export enum DomainCode {
    WORK_TYPE = 'WORK_TYPE',
    FIELD_OF_WORK = 'FIELD_OF_WORK',
    LOCATION = 'LOCATION',
    LANGUAGE = 'LANGUAGE',
    UNIVERSITY = 'UNIVERSITY',
    MAJOR = 'MAJOR',
    GRADE_SCALE = 'GRADE_SCALE',
    METRIX = 'METRIX',
    CITIZENSHIP = 'CITIZENSHIP',
}

export interface IDomainValue {
    code: string;
    value: string;
    domainCode: string;
    orderNr: number;
    count: number;
    range: number[];
    selected: boolean;
    major: string;
}
