import React, {useEffect, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Box, Grid, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import AdminDashboardLeft from './AdminDashboardLeft';
import AdminDashboardMiddle from './AdminDashboardMiddle';

import AdminUsersMiddle from "./AdminUsersMiddle";
import AdminLayout from "../../AdminLayout";
import AdminCompanies from "./AdminCompanies";

const initialPages = [
    {key: 'COMPANIES', name: 'Companies'},
    {key: 'USERS', name: 'Users'},
    {key: 'STUDENT', name: 'Statistics'},

];

function AdminDashboard() {
    const {t} = useTranslation();

    const [pages, setPages] = useState(initialPages);
    const [activePage, setActivePage] = useState<any>(initialPages[0]);

    useEffect(() => {

    }, []);

    return (
        <AdminLayout>
            <Stack spacing={1}>
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Typography typography="h3">Admin Dashboard</Typography>
                </Box>

                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} sm={4} md={3} lg={2}>
                        <AdminDashboardLeft
                            pages={pages}
                            activePage={activePage}
                            setActivePage={setActivePage}
                        />
                    </Grid>
                    <Grid item xs={12} sm={8} md={5}>
                        {activePage.key === 'COMPANIES' && (
                            <AdminCompanies activePage={activePage}/>
                        )}
                        {activePage.key === 'USERS' && (
                            <AdminUsersMiddle activePage={activePage}/>
                        )}
                        {activePage.key === 'STUDENT' && (
                            <AdminDashboardMiddle activePage={activePage}/>
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </AdminLayout>
    );
}

export default AdminDashboard;
