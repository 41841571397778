import {Autocomplete, Box, Button, Dialog, Paper, Stack, TextField, Typography,} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {useAtomValue} from 'jotai';
import {useNavigate} from 'react-router-dom';
import ActionButton, {ActionButtonType} from '../ActionButton';
import {styles} from '../theme';
import {IJobPosition} from '../../models/IJobPosition';
import {userAtom} from '../../store/userStore';
import useJobPositions from '../../hooks/useJobPositions';
import {positionsAtom} from '../../store/positionsStore';
import {toast} from "react-toastify";
import {DomainCode, IDomainValue} from "../../models/IDomainValue";
import {COMPETENCE, PERSONALITY, VALUE} from "../../util/metrixUtil";
import useDomainValues from "../../hooks/useDomainValues";
import {IMetrixRange, ISearchFilter} from "../../models/ISearchFilter";
import MetrixSelectionList from "./MetrixSelectionList";
import CompanyFilterLeft from "../../pages/Company/Positions/Filter/CompanyFilterLeft";

const initialSearchFilterState = {
    metrixRanges: [],
    jobPositionId: null,
    mismatches: 0,
    universities: [],
};

function AddPositionModal({
                              open,
                              handleClose,
                              template = null
                          }: { open: boolean; handleClose: () => void; template?: IJobPosition | null }) {
    const [name, setName] = useState('');
    const [templatePosition, setTemplatePosition] = useState<IJobPosition | null>(null);
    const {createJobPosition, savePositionFilter} = useJobPositions();
    const user = useAtomValue(userAtom);
    const positions = useAtomValue(positionsAtom);
    const navigate = useNavigate();

    const [searchFilter, setSearchFilter] = useState<ISearchFilter>(initialSearchFilterState);

    const updateFilter = async (newFilter: ISearchFilter, updateCharts: boolean, saveFilter: boolean = true, updateCount: boolean = true) => {
        setSearchFilter((prev) => newFilter);
    };

    useEffect(() => {
        onTemplateChange(template);
    }, [template]);

    const createNewPosition = async (name: string) => {
        if (!name || name.trim().length < 3) {
            toast.error("Please use name that is at least 3 characters long!");
            return;
        }
        const position = {
            id: 0,
            name,
            userId: user?.id,
            filter: templatePosition ? templatePosition.filter : '{}',
        } as IJobPosition;

        const addedPosition = await createJobPosition(position);

        await savePositionFilter(parseInt(addedPosition.id), searchFilter);


        navigate(`/positions/${addedPosition.id}/filter`);
        handleClose();
    };

    const onTemplateChange = (value: IJobPosition | null) => {

        if (value) {
            const loadedFilter = JSON.parse(value?.filter || "") as ISearchFilter;
            setSearchFilter(loadedFilter);
        } else {
            setSearchFilter(initialSearchFilterState);
        }

        setTemplatePosition(value);
    };



    return (
        <Dialog open={open} onClose={handleClose}>
            <Stack sx={{width: '100%'}}>
                <Paper elevation={0} sx={styles.paper}>
                    <Typography typography="h3">Add new position</Typography>
                    <TextField
                        sx={{width: '100%'}}
                        variant="standard"
                        placeholder="Job position"
                        autoFocus
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        options={positions}
                        value={templatePosition}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e, value) => onTemplateChange(value)}
                        renderInput={(params) => <TextField variant="standard" {...params}
                                                            placeholder="Choose from template"/>}
                    />

                    <>
                        <CompanyFilterLeft searchFilter={searchFilter} updateFilter={updateFilter}
                                           chartUpdateKey={0}/>
                    </>

                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box style={{flexGrow: 1}}/>
                        <Button
                            variant="text"
                            sx={{fontSize: '0.875rem', color: '#809252'}}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                        <ActionButton
                            label="Create"
                            type={ActionButtonType.SUBMIT}
                            onClick={() => createNewPosition(name)}
                        />
                    </Box>
                </Paper>
            </Stack>
        </Dialog>
    );
}

export default AddPositionModal;
