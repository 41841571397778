import React, {useEffect, useState} from 'react';
import {Autocomplete, Box, Grid, TextField,} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {IDomainValue} from '../models/IDomainValue';
import {IUniversity} from '../models/IUniversity';
import {UNIVERSITY_MAP} from "../util/educationUtil";


function UserUniversitySelect({
                                  university,
                                  schools,

                                  changeData,
                              }: {
    university: IUniversity,
    schools: IDomainValue[],
    changeData: any
}) {
    const [scoreValue, setScoreValue] = useState<number | null>(university.avgGrade !== null && university.avgGrade !== 0 ? university.avgGrade / 20 : 0);
    const [yearValue, setYearValue] = useState<number | null>(university.year === 0 ? null : university.year);
    const [universityValue, setUniversityValue] = useState<IDomainValue | null>(null);
    const [customValue, setCustomValue] = useState<string | undefined>(university.customValue);
    const [majorValue, setMajorValue] = useState<string | null>(university.major || 'All majors');
    const [majors, setMajors] = useState<string[]>([]);

    const generateYears = () => {
        const end = new Date().getFullYear() + 3;
        const start = end - 13;

        return Array.from(Array(14).keys()).map(x => x + start).reverse();
    }

    const years = generateYears();

    useEffect(() => {
        const schoolDv = schools.find((s) => s.code === university.university);
        if (schoolDv) {
            setUniversityValue(schoolDv);
            if (schoolDv.code === 'OTHER') {
                setCustomValue(university.customValue);
            }
            setMajors(UNIVERSITY_MAP[schoolDv.code] || []);
        }
        /*
        const majorDv = majors.find((s) => s.code === university.major);
        if (majorDv) {
            setMajorValues(majorDv);
        }

         */
    }, [schools]);

    const changeUniversity = (university: IUniversity, value: IDomainValue | null) => {
        if (value) {
            if (UNIVERSITY_MAP[value.code] === undefined || value.code !== university.university) {
                setMajorValue(prev => null);
                university.major = 'All majors';
            }

            setMajors(UNIVERSITY_MAP[value.code] || []);

            const newCustomValue = value.code === 'OTHER' ? '' : undefined;
            const newUniversity = {...university, university: value.code, customValue: newCustomValue};
            setUniversityValue((prev) => value);
            setCustomValue(newCustomValue);
            changeData(newUniversity);
        }
    };

    const changeCustomValue = (value: string | null) => {
        if (value) {
            const newUniversity = {...university, customValue: value};
            setCustomValue(value);
            changeData(newUniversity);
        }
    };

    /*
    const changeMajor = (university: IUniversity, value: IDomainValue | null) => {
        if (value) {
            const newUniversity = {...university, major: value.code};
            setMajorValue(value);
            changeData(newUniversity);
        }
    };

     */

    const changeFaculty = (value: string | null) => {
        if (value) {
            const newUniversity = {...university, major: value};
            setMajorValue(value);
            changeData(newUniversity);
        }
    };

    const changeGrade = (value: any) => {
        let num: number | null = parseFloat(value);
        if (!num) {
            num = 0;
        }
        setScoreValue(num);
        const newUniversity = {...university, avgGrade: num * 20};
        changeData(newUniversity);
    };

    const changeYear = (value: any) => {
        let num: number | null = parseInt(value);
        if (!num) {
            num = 0;
        }

        setYearValue(num);
        const newUniversity = {...university, year: num};
        changeData(newUniversity);
    };

    return (
        <Box sx={{display: 'flex', alignItems: 'center', mt: 2, mb: 2}}>


            <Grid container spacing={2}>

                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={universityValue}
                        clearIcon={null}
                        isOptionEqualToValue={(option, value) => option.code === value.code}
                        options={schools}
                        popupIcon={<ArrowDropDownIcon sx={{width: '1rem'}}/>}
                        getOptionLabel={(option) => option.value}
                        //fullWidth
                        renderInput={(params) => (
                            <TextField
                                variant="standard"
                                label={"Higher Education Institution"}
                                {...params}
                            />
                        )}
                        onChange={(event, value) => changeUniversity(university, value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {
                        majors.length > 0 && (
                            <Autocomplete
                                value={majorValue}
                                //id={university.id?.toString()}
                                fullWidth
                                //sx={{padding: 0, width: '13rem'}}
                                clearIcon={null}
                                //isOptionEqualToValue={(option, value) => option.code === value.code}
                                options={majors}
                                popupIcon={<ArrowDropDownIcon sx={{width: '1rem'}}/>}
                                //getOptionLabel={(option) => option.value}
                                renderInput={(params) => (
                                    <TextField
                                        sx={{}}
                                        variant="standard"
                                        label={"Faculty"}
                                        {...params}
                                        inputProps={{...params.inputProps, sx: {}}}
                                    />
                                )}
                                onChange={(event, value) => changeFaculty(value)}
                            />
                        )}
                    {
                        university.university === 'OTHER' && (
                            <TextField
                                //sx={{pt: 0.4}}
                                fullWidth
                                variant="standard"
                                label="Other university"
                                autoFocus
                                value={customValue}
                                onChange={(e) => changeCustomValue(e.target.value)}
                            />
                        )
                    }
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        placeholder="GPA"
                        variant="standard"
                        type={"number"}
                        label={"Grade point average (0-5 up to 2 decimal places):"}
                        fullWidth
                        value={scoreValue}
                        inputProps={{
                            min: 0,
                            step: 0.01, /*style: {textAlign: 'center', padding: 0}*/
                        }} // the change is here
                        onChange={(e) => changeGrade(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Autocomplete
                        value={yearValue}
                        clearIcon={null}
                        options={years}
                        popupIcon={<ArrowDropDownIcon sx={{width: '1rem'}}/>}
                        getOptionLabel={(option) => option.toString()}
                        renderInput={(params) => (
                            <TextField
                                sx={{textAlign: 'center'}}
                                label={"Expected graduation year"}
                                variant="standard"
                                {...params}
                            />
                        )}
                        onChange={(event, value) => changeYear(value)}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default UserUniversitySelect;
