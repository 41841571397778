import {useLocation, useNavigate} from 'react-router';
import {Button} from '@mui/material';
import React from 'react';

const STUDENT_LINKS = [
    {label: 'Offers', path: '/'},
    {label: 'Preferences', path: '/preferences'},
    {label: 'Survey results', path: '/survey'},
    // {label: 'Profile', path: '/profile'},
];

const linkStyle = {
    textTransform: 'capitalize',
    color: '#231F20',
    fontWeight: 400,
};

const activeLinkStyle = {
    color: '#809252',
    fontWeight: 700,
    textDecoration: 'underline',
};

function StudentHeaderLinks() {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            {STUDENT_LINKS.map((link, index) => {
                const addStyle = location.pathname === link.path ? activeLinkStyle : {};
                return (
                    <React.Fragment key={"studentlink_" + index}>
                        {
                            /*
                            {index === STUDENT_LINKS.length - 1 && (
                            <Box sx={{flexGrow: 1}}/>
                        )}
                             */
                        }

                        <Button key={index} sx={{...linkStyle, ...addStyle}}
                                    onClick={() => navigate(link.path)}>{link.label}</Button>
                    </React.Fragment>
                );
            })}
        </>
    );
}

export default StudentHeaderLinks;
