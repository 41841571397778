import React, {useEffect, useState} from 'react';
import {Box, Modal, Stack, Typography,} from '@mui/material';
import useUserProfileApi from "../api/useUserProfileApi";
import {IMetrixOverviews} from "../models/IMetrixOverviews";
import SurveyResults from "../pages/Student/Survey/SurveyResults";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'start',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    overflow: "scroll",
    height: "100%",
    minWidth: "75rem"
};

function SurveyResultsPopup({userId, accountNumber}: { userId: number, accountNumber: number }) {
    const {getMetrixOverview} = useUserProfileApi();
    const [metrixOverview, setMetrixOverview] = useState<IMetrixOverviews>();
    const [open, setopen] = useState<boolean>(false);


    const loadMetrixData = async () => {
        let mw: IMetrixOverviews = await getMetrixOverview(userId);
        setMetrixOverview(mw)
    }

    useEffect(() => {
        if (userId) {
            loadMetrixData();
        }
    }, [userId]);

    return (
        <>
            <Box sx={{width: '100%'}} textAlign="left">
                {metrixOverview?.personalityOverview && metrixOverview?.personalityOverview.values.length > 0 &&
                metrixOverview.valuesOverview && metrixOverview.valuesOverview.values.length > 0 &&
                metrixOverview.competenciesOverview && metrixOverview.competenciesOverview.values.length > 0 ? (
                    <>
                        <Typography
                            color="primary"
                            sx={{marginLeft: '5px', cursor: 'pointer'}}
                            onClick={() => setopen(true)}
                        >
                            View test results
                        </Typography>

                    </>
                ) : (
                    <Typography typography="h2" sx={{mt: 0, mb: 6}}>No test info</Typography>
                )}
            </Box>

            <Modal open={open} onClose={() => setopen(false)}>
                <Stack sx={style}>

                    <CloseIcon onClick={() => setopen(false)} sx={{
                        cursor: 'pointer', top: "10px", left: "calc(100% - 35px)", position: "sticky", zIndex: 2

                    }}/>
                    <Box sx={{
                        top: "-25px",
                        display: 'flex',
                        position: "relative",
                        justifyContent: 'center',
                        backgroundColor: "#F7F7F7",
                    }}>
                        <Box sx={{height: '100%', maxWidth: '90rem', width: '100%'}}>
                            <SurveyResults metrixOverviews={metrixOverview ? metrixOverview : null} accountNumber={accountNumber}/>
                        </Box>
                    </Box>
                </Stack>
            </Modal>

        </>
    );
}

export default SurveyResultsPopup;
