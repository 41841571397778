import React, {useEffect, useState} from 'react';
import {Autocomplete, Box, CircularProgress, Paper, Stack, TextField,} from '@mui/material';
import {useAtom} from 'jotai';
import {toast} from 'react-toastify';
import {styles} from '../../../components/theme';
import {IUser} from '../../../models/IUser';
import {userAtom} from '../../../store/userStore';
import ActionButton, {ActionButtonType} from '../../../components/ActionButton';
import useUserApi from '../../../api/useUserApi';
import ChangePassword from "../../../components/shared/ChangePassword";

const callingCodes = [
    '+372',
];

interface IProfileForm {
    callingCode: string;
    phone: string;
    fullName: string;
    email: string;
    companyName: string;
}

function CompanyAccountMiddle({
                           activePage,
                       }: {
    activePage?: any,
}) {
    const [user, setUser] = useAtom(userAtom);
    const {updateUser} = useUserApi();
    const [form, setForm] = useState<IProfileForm | null>(null);

    const initForm = () => {
        if (user) {
            const profileForm = {
                fullName: user.fullName,
                email: user.email,
                companyName: user.companyName,
                phone: user.phone ? user.phone.substring(4) : '',
                callingCode: user.phone ? user.phone.substring(0, 4) : '',
            };

            setForm(profileForm as IProfileForm);
        }
    };

    useEffect(() => {
        initForm();
    }, [user, activePage]);

    const onFieldChange = (field: string, value: string | boolean) => {
        setForm({...form, [field]: value} as IProfileForm);
    };

    const saveProfile = async () => {
        if (user) {
            const newUser = {
                ...user,
                fullName: form?.fullName,
                email: form?.email,
                phone: form?.phone ? `${form?.callingCode}${form?.phone}` : "",
                modifiedDate: null,
            } as IUser;
            updateUser(user?.id, newUser).then((updatedUser) => {
                toast.success('Account info updated!');
                setUser(updatedUser);
            });
        }
    };

    if (!form) {
        return (
            <CircularProgress/>
        );
    }

    return (
        <Stack sx={styles.middleStack} spacing={1}>
            <Paper elevation={0} sx={styles.paper}>

                <Stack sx={{alignItems: 'center'}}>
                    <TextField
                        sx={{width: '15.625rem'}}
                        variant="standard"
                        label="Full name"
                        value={form?.fullName}
                        onChange={(e) => onFieldChange('fullName', e.target.value)}
                    />
                    <TextField
                        sx={{width: '15.625rem'}}
                        variant="standard"
                        label="Email"
                        value={form?.email}
                        onChange={(e) => onFieldChange('email', e.target.value)}
                    />
                    <Box sx={{display: 'flex', width: '15.625rem'}}>
                        <Autocomplete
                            sx={{width: '3.125rem', marginTop: '1rem'}}
                            disableClearable
                            disablePortal
                            options={callingCodes}
                            value={form?.callingCode}
                            onChange={(e, value: string) => onFieldChange('callingCode', value)}
                            renderInput={(params) => <TextField variant="standard"
                                                                sx={{width: '3.5rem'}} {...params} />}
                        />
                        <TextField
                            sx={{width: '12rem', marginLeft: '0.5rem', marginTop: '1rem'}}
                            variant="standard"
                            placeholder="Mobile"
                            value={form?.phone}
                            onChange={(e) => onFieldChange('phone', e.target.value)}
                        />
                    </Box>

                    <TextField
                        disabled
                        sx={{width: '15.625rem'}}
                        variant="standard"
                        label="Company name"
                        value={form?.companyName}
                    />
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{flexGrow: 1}}/>
                        <ActionButton label="Save" type={ActionButtonType.SUBMIT} onClick={() => saveProfile()}/>
                    </Box>
                </Stack>
            </Paper>

            <Paper elevation={0} sx={{...styles.paperCenter}}>
                <ChangePassword/>
            </Paper>
        </Stack>
    );
}

export default CompanyAccountMiddle;
