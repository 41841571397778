import {Box, Paper, Typography} from '@mui/material';
import React from 'react';
import {styles} from "../theme";
import {formatAvgGradeForStudent} from "../../util/formatUtil";
import {IUniversity} from "../../models/IUniversity";
import {DomainCode} from "../../models/IDomainValue";
import useDomainValues from "../../hooks/useDomainValues";


const StudentEducation = ({universities}: {universities: IUniversity[]}) => {

    const {findDomainValueStringByCode} = useDomainValues();

    const renderUniversity = (u: IUniversity) => {
        if (u.university === 'OTHER') {
            return u.customValue;
        }
        let value = findDomainValueStringByCode(DomainCode.UNIVERSITY, u.university);
        if(u.major && u.major.trim().length > 0) {
            value += " - " + u.major;
        }
        return value;
    }

    return (
        <Paper elevation={0} sx={styles.paperCenter}>
            <Typography typography="h4">Higher Education Institution</Typography>
            {
                universities?.map((w, i) => (
                    <Box key={i}>
                        <Typography
                            sx={styles.body1}
                            key={`t1${i}`}
                        >
                            {renderUniversity(w)}
                        </Typography>
                        <Typography sx={styles.body1} key={`t4${i}`}>GPA: <span style={{fontWeight: '500'}}> {'  ' + formatAvgGradeForStudent(w)}</span></Typography>
                        <Typography sx={styles.body1} key={`t8${i}`}>Expected graduation year: <span style={{fontWeight: '500'}}>{w.year}</span></Typography>
                    </Box>
                ))
            }
        </Paper>
    );
}

export default StudentEducation;
