import {orderOverviewAtom, orderWasSuccessfulAtom} from "../store/ordersStore";
import useOrderApi from "../api/useOrderApi";
import {useAtom, useSetAtom} from "jotai";
import {IOrderResponse} from "../models/IOrderCallbackResponse";
import {IOrderStatus} from "../models/IOrderStatus";

const useOrders = () => {
    const [orderOverview, setOrderOverview] = useAtom(orderOverviewAtom);
    const setOrderWasSuccessful = useSetAtom(orderWasSuccessfulAtom);

    const {getOrdersOverview, paymentCallback} = useOrderApi();


    const loadOrderOverview = async () => {
        const ordersOverview = await getOrdersOverview();
        setOrderOverview(ordersOverview);
    };
    const isJobPositionManagedByJobling = (jobPositionId: number) => {
        return orderOverview.jobPositionsManagedByJoblings.find((p) => p.positionId === jobPositionId) !== undefined
    };

    const validateOrderToken = async (token: string) => {
        try {
            let response: IOrderResponse = await paymentCallback(token);
            if (response.status === IOrderStatus.PAID) {
                setOrderWasSuccessful(true);
            } else {
                setOrderWasSuccessful(false);
            }
            loadOrderOverview();
        } catch (e) {
            setOrderWasSuccessful(false);
        }
    };

    return {
        loadOrderOverview,
        isJobPositionManagedByJobling,
        validateOrderToken
    };
};

export default useOrders;
